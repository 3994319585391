import React, { useState, useEffect } from 'react';
import {
  Form,
  FormGroup,
  Button,
  Media,
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from 'reactstrap';
import {  Form as FinalForm } from 'react-final-form';
import { useUnitById } from '../hooks/unit/useUnitById';
import { useUpdateUnit } from '../hooks/unit/useUpdateUnit';
import { Tabs } from '../components/admin/tabs/Tabs';
import { TabPaneUnit } from '../components/admin/tabs/TabPaneUnit';
import { useHistory } from 'react-router-dom';
import arrowLeft from '../shared/images/arrow-left.svg';
import { useLessonsByUnitId } from '../hooks/lesson/useLessonsByUnitId';
import { SortableTable } from '../components/admin/SortableTable';
import { useUpdateLessons } from '../hooks/lesson/useUpdateLessons';
import { HelpContentModal } from '../components/admin/modal/HelpContentModal';
import { useDispatch, useSelector } from 'react-redux';
import translate, { getSupportedLanguage } from '../utils/translate';
import { withNamespaces } from 'react-i18next';
import { ImagePreviewModal } from '../components/admin/modal/ImagePreviewModal';
import { FileUploader } from '../components/admin/FileUploader';
import { setSpinnerVisible  } from '../redux/actions/spinner';
import { useUploadFile } from '../hooks/upload/useUploadFile';
import { substituteFileId } from '../shared/helpers/upload';
import './UnitEdit.scss';
import { API_URL } from '../shared/constants/api';

export const UnitEdit = withNamespaces()((props) => {
  const history = useHistory();
  const unitId = props.match.params?.id;
  const user = useSelector((state) => state.authenticatedUser);
  const lng = getSupportedLanguage(props.lng);
  const dispatch = useDispatch();

  const { data: unit } = useUnitById(unitId);
  const { data: lessons } = useLessonsByUnitId(unitId);
  const { mutate: updateUnit } = useUpdateUnit(unitId);
  const { mutate: updateLessons } = useUpdateLessons();
  const { mutate: uploadFile } = useUploadFile();

  const [helpContentIndexToEdit, setHelpContentIndexToEdit] = useState(-1);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    if (!user.isAdmin) {
      history.replace('/');
    }
  }, [history, user]);

  const onSubmit = (values) => {
    updateUnit(values);
  };

  const validate = (values) => {
    const errors = {
      i18n: {},
      helpContent: []
    };
    const initLocaleErrors = (locale) => {
      if (!errors.i18n[locale]) {
        errors.i18n[locale] = {};
      }
    };
    const initHelpContentErrors = (index) => {
      if (!errors.helpContent[index]) {
        errors.helpContent[index] = {};
      }
    };
    const initHelpContentLocaleErrors = (index, locale) => {
      initHelpContentErrors(index);
      if (!errors.helpContent[index].i18n) {
        errors.helpContent[index].i18n = {};
      }
      if (!errors.helpContent[index].i18n[locale]) {
        errors.helpContent[index].i18n[locale] = {};
      }
    };

    if (values.i18n) {
      Object.keys(values.i18n).forEach((locale) => {
        if (!values.i18n[locale].title) {
          initLocaleErrors(locale);
          errors.i18n[locale].title = 'Required';
        }

        if (!values.i18n[locale].goal) {
          initLocaleErrors(locale);
          errors.i18n[locale].goal = 'Required';
        }
      });
    }

    if (values.helpContent) {
      values.helpContent.forEach((helpContent, index) => {
        if (helpContent.i18n) {
          Object.keys(helpContent.i18n).forEach((locale) => {
            if (!helpContent.i18n[locale].title) {
              initHelpContentLocaleErrors(index, locale);
              errors.helpContent[index].i18n[locale].title = 'Required';
            }

            if (!helpContent.i18n[locale].text) {
              initHelpContentLocaleErrors(index, locale);
              errors.helpContent[index].i18n[locale].text = 'Required';
            }
          });
        }
      });
    }

    return errors;
  };

  const isUnitLocalesInvalid = (locale, values) => {
    let result = false;
    let { i18n } = values;
    if (!i18n || !i18n[locale]) {
      result = true;
    } else {
      if (!i18n[locale]) result = true;
      if (!i18n[locale].title?.length) result = true;
      if (!i18n[locale].goal?.length) result = true;
    }
    return result;
  };

  const onGoBackClick = () => {
    history.goBack();
  };

  const onLessonClick = (lesson) => {
    history.push(`/editor/${unit._id}/lesson/${lesson._id}`);
  };

  const onFileUploadSuccess = (result, propertyName) => {
    const updatedUnit = { ...unit };
    let newFilePath = null;
    if (!updatedUnit[propertyName]) {
      newFilePath = `/files/image/${result._id}`;
    } else {
      newFilePath = substituteFileId(
        updatedUnit[propertyName],
        result._id
      );
    }
    updatedUnit[propertyName] = newFilePath;
    updateUnit(updatedUnit);
    dispatch(setSpinnerVisible(false));
  };

  const onChangeFileClick = (e, propertyName) => {
    const files = e.target.files;
    if (files.length > 0) {
      dispatch(setSpinnerVisible(true));
      onUploadFile(files[0], (result) =>
        onFileUploadSuccess(result, propertyName)
      , propertyName);
    }
  };

  const onUploadFile = (file, cb, propertyName) => {
    if (unit) {
      const metadata = {
        unitId: unit._id,
        scope: `unit_${propertyName}`,
      };
      uploadFile({
        file: file,
        metadata,
        cb 
      });
    }
  };

  return (
    <Row className="unit-edit">
      <Col lg={{
        offset: 2,
        size: 8 
      }}>
        <div style={{ display: 'flex' }}>
          <Button className="back-btn" onClick={onGoBackClick}>
            <Media src={arrowLeft} className="back-icon" />
          </Button>
          <h3 style={{ marginBottom: 0 }}>Unit {unit?.number} Edit</h3>
        </div>
        <br />
        <FinalForm
          onSubmit={onSubmit}
          validate={validate}
          initialValues={unit}
          render={({
            form, handleSubmit, submitting, pristine, invalid 
          }) => (
            <Form onSubmit={handleSubmit} className="">
              <Card>
                <CardBody>
                  <CardTitle tag="h3" style={{ marginBottom: 5 }}>
                    Localizations
                  </CardTitle>
                  <FormGroup>
                    <Tabs
                      form={form}
                      locales={Object.keys(unit?.i18n || {})}
                      tabPaneContentComponent={TabPaneUnit}
                      isTabContentInvalidFunc={isUnitLocalesInvalid}
                      fieldNamePrefix="i18n."
                      handleSubmit={handleSubmit}
                    />
                  </FormGroup>
                  <FormGroup className="file-uploader-container">
                    <label className="uploader-label">Image</label>
                    {unit?.image ? (
                      <Button
                        style={{ marginRight: 15 }}
                        onClick={() => setImagePreview(unit?.image)}
                      >
                        Preview
                      </Button>
                    ) : (
                      <span style={{ marginRight: 15 }}>No image</span>
                    )}
                    <FileUploader
                      label="Change"
                      onChange={(e) => onChangeFileClick(e, 'image')}
                      accept="image/*"
                    />
                  </FormGroup>
                  <FormGroup className="file-uploader-container">
                    <label className="uploader-label">Icon</label>
                    {unit?.icon ? (
                      <Button
                        style={{ marginRight: 15 }}
                        onClick={() => setImagePreview(unit?.icon)}
                      >
                        Preview
                      </Button>
                    ) : (
                      <span style={{ marginRight: 15 }}>No image</span>
                    )}
                    <FileUploader
                      label="Change"
                      onChange={(e) => onChangeFileClick(e, 'icon')}
                      accept="image/*"
                    />
                  </FormGroup>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle tag="h3" style={{ marginBottom: 5 }}>
                    Lessons
                  </CardTitle>
                  <SortableTable
                    items={lessons}
                    onItemClick={onLessonClick}
                    onItemsUpdate={updateLessons}
                  />
                </CardBody>
              </Card>

              {unit?.helpContent && (
                <Card>
                  <CardBody>
                    <CardTitle tag="h3" style={{ marginBottom: 5 }}>
                      Help Content
                    </CardTitle>
                    <ListGroup>
                      {unit?.helpContent.map((helpContent, index) => (
                        <ListGroupItem
                          key={index}
                          tag="a"
                          style={{ cursor: 'pointer' }}
                          onClick={() => setHelpContentIndexToEdit(index)}
                        >
                          {translate(helpContent, 'title', lng)}
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </CardBody>
                </Card>
              )}

              <HelpContentModal
                isOpen={helpContentIndexToEdit !== -1}
                onClosed={() => setHelpContentIndexToEdit(-1)}
                unit={unit}
                index={helpContentIndexToEdit}
                form={form}
                handleSubmit={handleSubmit}
              />
            </Form>
          )}
        />
        <ImagePreviewModal
          isOpen={!!imagePreview}
          image={imagePreview}
          onClosed={() => setImagePreview(null)}
        />
      </Col>
    </Row>
  );
});
