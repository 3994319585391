import * as types from '../types';

const moduleReducers = { globalDialog: setGlobalDialog };

const initialState={
  type:null,
  props:{}
};

function setGlobalDialog(state = initialState, action) {
  switch (action.type) {
  case types.SET_GLOBAL_DIALOG:
    return action.payload;
  case types.REMOVE_GLOBAL_DIALOG:
    return initialState;
  default:
    return state;
  }
}

export default moduleReducers;