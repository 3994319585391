/** API ACTION TYPES */
export const FETCH_UNITS = 'FETCH_UNITS';
export const SET_UNITS = 'SET_UNITS';
export const FETCH_LESSONS = 'FETCH_LESSONS';
export const SET_LESSONS = 'SET_LESSONS';
export const START_LESSON = 'START_LESSON';
export const SELECT_UNIT = 'SELECT_UNIT';
export const SET_CURRENT_UNIT = 'SET_CURRENT_UNIT';
export const SET_CURRENT_LESSON = 'SET_CURRENT_LESSON';
export const SET_CURRENT_UNIT_LESSONS = 'SET_CURRENT_UNIT_LESSONS';
export const TASK_CHANGE = 'TASK_CHANGE';
export const STORE_RESULT = 'STORE_RESULT';
export const FETCH_USER_RESULTS = 'FETCH_USER_RESULTS';
export const SET_USER_RESULTS = 'SET_USER_RESULTS';
export const SET_UNITS_COMPLETED = 'SET_UNITS_COMPLETED';
export const SET_UNITS_RESULT = 'SET_UNITS_RESULT';
export const SET_LESSONS_COMPLETED = 'SET_LESSONS_COMPLETED';
export const SET_STREAK = 'SET_STREAK';
export const SET_CURRENT_STREAK = 'SET_CURRENT_STREAK';
export const SET_LONGEST_STREAK = 'SET_LONGEST_STREAK';
export const SET_TOTAL_TASKS = 'SET_TOTAL_TASKS';
export const SET_SUCEEDED_TASKS = 'SET_SUCEEDED_TASKS';
export const SET_ANONYMOUS_LESSON_COMPLED = 'SET_ANONYMOUS_LESSON_COMPLED';
export const SET_ANONYMOUS_UNIT_COMPLED = 'SET_ANONYMOUS_UNIT_COMPLED';
export const SET_SUCCEDED_TASKS_PER_LESSON = 'SET_SUCCEDED_TASKS_PER_LESSON';
export const GET_UNIT_RECOMMENDATION = 'GET_UNIT_RECOMMENDATION';
export const SET_UNIT_RECOMMENDATION = 'SET_UNIT_RECOMMENDATION';

/** ALERT ACTION TYPES */
export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const NEW_ALERT_SUCCESS = 'NEW_ALERT_SUCCESS';
export const NEW_ALERT_INFO = 'NEW_ALERT_INFO';
export const NEW_ALERT_WARNING = 'NEW_ALERT_WARNING';
export const NEW_ALERT_ERROR = 'NEW_ALERT_ERROR';
export const SET_SPINNER_VISIBLE = 'SET_SPINNER_VISIBLE';

/** LOGIN ACTION TYPES */
export const INIT_USER = 'INIT_USER';
export const SET_LOGING_IN = 'SET_LOGING_IN';
export const LOGIN = 'LOGIN';
export const LOGIN_ANONYMOUS = 'LOGIN_ANONYMOUS';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_ANONYMOUS_USER = 'SET_ANONYMOUS_USER';
export const SAVE_USER_PROFILE = 'SAVE_USER_PROFILE';
export const LOGOUT = 'LOGOUT';
export const MODAL_SHOWN = 'MODAL_SHOWN';
export const SET_MODALS_SHOWN = 'SET_MODALS_SHOWN';
export const IS_EDITOR = 'IS_EDITOR';
export const SET_EDITOR = 'SET_EDITOR';
export const SAVE_FEEDBACK = 'SAVE_FEEDBACK';
export const SET_DID_FEEDBACK = 'SET_DID_FEEDBACK';
export const SET_GLOBAL_DIALOG = 'SET_GLOBAL_DIALOG';
export const REMOVE_GLOBAL_DIALOG = 'REMOVE_GLOBAL_DIALOG';
