import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Badge } from '../badge/Badge';
import { STATE } from '../../../shared/constants';
import { StateIcon } from './state_icon/StateIcon';
import { StarsStats } from '../stars_stats/StarsStats';
import './CardListItem.scss';

export const CardListItem = (props) => {
  const {
    onCardClick,
    title,
    badgeText,
    subtitle,
    state,
    percentage,
    icon,
    lang,
    ratio,
  } = props;

  return (
    <Row
      className={`card-list-item ${state === STATE.LOCKED && 'locked'}`}
      onClick={onCardClick}
    >
      <Col className="card-img-container">
        <div className="icon-container">
          <div
            className="icon"
            style={{
              backgroundImage: `url(${icon})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
            }}
          ></div>
        </div>
      </Col>
      <Col className="card-title-container">
        <Row className="card-title-row">
          {title ? (
            <p className="card-title">{title}</p>
          ) : (
            <StarsStats ratio={ratio || 0} />
          )}

          <Badge text={badgeText} />
        </Row>
        <Row>
          <p lang={lang} className="card-subtitle">
            {subtitle}
          </p>
        </Row>
      </Col>
      <Col className="state-container">
        <StateIcon state={state} percentage={percentage} />
      </Col>
    </Row>
  );
};

CardListItem.propTypes = {
  onCardClick: PropTypes.func,
  title: PropTypes.string,
  badgeText: PropTypes.string,
  subtitle: PropTypes.string,
  state: PropTypes.string,
  percentage: PropTypes.number,
  icon: PropTypes.string,
  lang: PropTypes.string,
  ratio: PropTypes.number,
};
