import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { LESSON } from '../../shared/constants';
import { BREAKPOINT_MOBILE_LAYOUT } from '../../shared/constants/theme';
import { DefaultBtn } from '../fragments/default_btn/DefaultBtn';
import { TipsBtn } from '../fragments/tips_btn/TipsBtn';
import LessonExerciseTaskAudio from './LessonExerciseTaskAudio';
import LessonExerciseTaskOptions from './LessonExerciseTaskOptions';
import './LessonExerciseTask.scss';

class LessonExerciseTask extends React.Component {
  static propTypes = {
    task: PropTypes.object,
    success: PropTypes.bool,
    successLevel: PropTypes.number,
    attempts: PropTypes.number,
    round: PropTypes.number,
    correctOption: PropTypes.string,
    allowSkipButton: PropTypes.bool,
    isEditor: PropTypes.bool,
    skip: PropTypes.func,
    selectOption: PropTypes.func,
    onAudioResult: PropTypes.func,
    next: PropTypes.func,
    roundHeaderText: PropTypes.string,
    onTipsClick: PropTypes.func,
    screenWidth: PropTypes.number,
  };

  static defaultProps = { isEditor: false };

  constructor(props) {
    super(props);

    this.state = { audioProcessing: false };
  }

  onAudioResultReceived = (result) => {
    this.setState({ audioProcessing: false });
    this.props.onAudioResult(result);
  };

  onStartRecord = () => {
    this.setState({ audioProcessing: true });
  };

  render() {
    let {
      task,
      success,
      successLevel,
      attempts,
      round,
      correctOption,
      selectOption,
      next,
      skip,
      allowSkipButton,
      roundHeaderText,
      onTipsClick,
      screenWidth,
    } = this.props;

    const { audioProcessing } = this.state;

    let showNextButton = success || attempts === 3;
    let showSkipButton =
      this.props.isEditor ||
      (allowSkipButton && !showNextButton && attempts > 0 && !success);
    return (
      <>
        <Row
          className={
            'question-container ' +
            (attempts > 0 && !audioProcessing
              ? successLevel < LESSON.AUDIO.ALMOST
                ? ' failed'
                : ' almost'
              : '') +
            (success ? ' succeed' : '') +
            ` round-${round}`
          }
        >
          <Col>
            {screenWidth > BREAKPOINT_MOBILE_LAYOUT ? (
              <Row className="question-row">
                <Col className="flex-grow-0 hidden">
                  <TipsBtn onClick={onTipsClick} />
                </Col>
                <Col className="question-text-container">
                  <p className="question-text">{roundHeaderText}</p>
                </Col>
                <Col className="flex-grow-0">
                  <TipsBtn onClick={onTipsClick} />
                </Col>
              </Row>
            ) : (
              <>
                <Row className="xs-tips-row">
                  <TipsBtn onClick={onTipsClick} />
                </Row>
                <Row className="question-row">
                  <Col className="question-text-container">
                    <p className="question-text">{roundHeaderText}</p>
                  </Col>
                </Row>
              </>
            )}

            {round === 1 && (
              <LessonExerciseTaskOptions
                task={task}
                round={round}
                correctOption={correctOption}
                next={next}
                selectOption={selectOption}
                success={success}
                attempts={attempts}
              ></LessonExerciseTaskOptions>
            )}

            {round > 1 && (
              <LessonExerciseTaskAudio
                task={task}
                success={success}
                successLevel={successLevel}
                attempts={attempts}
                round={round}
                next={next}
                onAudioResult={this.onAudioResultReceived}
                onStartRecord={this.onStartRecord}
              ></LessonExerciseTaskAudio>
            )}
          </Col>
        </Row>
        <Row className="footer">
          {!this.state.audioProcessing && (
            <Col>
              {showNextButton && (
                <DefaultBtn
                  label={this.props.t('lessonExerciseTaskButtonContinue')}
                  onClick={next}
                />
              )}
              {showSkipButton && !showNextButton && (
                <DefaultBtn
                  label={this.props.t('lessonExerciseTaskButtonSkip')}
                  onClick={skip}
                />
              )}
            </Col>
          )}
        </Row>
      </>
    );
  }
}
export default withNamespaces()(LessonExerciseTask);
