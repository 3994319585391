import ApiService from './api';

export default class UserService extends ApiService {
  constructor(token) {
    super(token);
  }
  async getAuthenticatedUser() {
    return this.get('/user/');
  }

  async updateUser(user) {
    return this.put('/user/', user);
  }

  async getUsersList(skip, limit, search) {
    return this.get('/user/list', {
      skip,
      limit,
      search,
    });
  }

  async getUserUnitRecommendation() {
    return this.get('/user/unit-recommendation/');
  }
}
