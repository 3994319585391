import { useState, useEffect } from 'react';

function getScreenWidth() {
  const { innerWidth } = window;
  return innerWidth;
}

export default function useScreenWidth() {
  const [width, setWidth] = useState(getScreenWidth());

  useEffect(() => {
    function handleResize() {
      setWidth(getScreenWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
}