import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import './ResultHeader.scss';

export const ResultHeader = (props) => {
  const { leftLabel, centerLabel } = props;

  return (
    <Row className='result-header-row'>
      <Col className='header-col'>
        <p className='header-text'>{leftLabel}</p>
      </Col>
      <Col className='header-col'>
        <p className='header-text center'>{centerLabel}</p>
      </Col>
      <Col className='header-col hidden'>
        <p className='header-text'>{leftLabel}</p>
      </Col>
    </Row>
  );
};

ResultHeader.propTypes = {
  leftLabel: PropTypes.string,
  centerLabel: PropTypes.string,
};
