import ApiService from './api';

export default class UnitService extends ApiService{
  constructor(token) {
    super(token);
  }

  async getUnits(){
    return this.get( '/unit');
  }

  async getUnitLessons(unitId){
    return this.get(`/unit/${unitId}/lessons`);
  }

  async getUnitsList() {
    return this.get('/unit/list');
  }

  async getUnitById(unitId) {
    return this.get(`/unit/${unitId}`);
  }

  async updateUnit(unit) {
    return this.put(`/unit/${unit._id}`, unit);
  }

  async updateUnits(units) {
    return this.put('/unit', units);
  }
}

