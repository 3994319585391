import { useMutation } from '@tanstack/react-query';
import UploadService from '../../service/upload';
import { useSelector } from 'react-redux';

export const useUploadFile = () => {
  const token = useSelector((state) => state.token);
  const uploadService = new UploadService(token);

  return useMutation(
    ({ file, metadata }) => {
      return uploadService.uploadFile(file, metadata);
    },
    {
      onSuccess: (result, { cb }) => {
        if (cb) {
          cb(result);
        }
      },
    },
  );
};