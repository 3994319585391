import React from 'react';
import PropTypes from 'prop-types';
import { Alert as ReactstrapAlert } from 'reactstrap';
import './Alert.scss';
class Alert extends React.Component {
    static propTypes = {
      alert: PropTypes.object,
      context: PropTypes.string,
      dismissable: PropTypes.bool,
      intl: PropTypes.object,
      onDismiss: PropTypes.func,
    };

    constructor(props) {
      super(props);
      this.state = { visible: true, };
    }
    onDismiss() {
      this.props.onDismiss(this.props.alert);
    }

    render() {
      let { alert } =this.props;
      if (!alert.dismissable) {
        return <ReactstrapAlert color={alert.level}>{alert.message}</ReactstrapAlert>;
      }
      return (
        <ReactstrapAlert color={alert.level} isOpen={true} toggle={this.onDismiss.bind(this)}>
          {alert.message}
        </ReactstrapAlert>
      );
    }
}

export default Alert;
