import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { API_URL } from '../../../shared/constants/api';
import './ImagePreviewModal.scss';

export const ImagePreviewModal = ({
  isOpen, image, onClosed 
}) => {
  return (
    <Modal isOpen={isOpen} onClosed={onClosed} toggle={onClosed}>
      <ModalBody className="body">
        <div className="imgwrap">
          <img alt="image-preview" className="img" src={API_URL + image}></img>
        </div>
      </ModalBody>
    </Modal>
  );
};
