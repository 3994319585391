import React from 'react';
import checkImage from '../../../shared/images/check.svg';
import { Media } from 'reactstrap';
import PropTypes from 'prop-types';
import './SuccessResultIcon.scss';

export const SuccessResultIcon = (props) => {
  const { containerClassName = '' } = props;
  return (
    <div className={`success-result-icon-container ${containerClassName}`}>
      <div className="success-result-icon-container-inner">
        <Media object src={checkImage} className="success-result-icon" />
      </div>
    </div>
  );
};

SuccessResultIcon.propTypes = {
  containerClassName: PropTypes.string,
};
