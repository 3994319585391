import React from 'react';
import { Field } from 'react-final-form';
import BootstrapInput from '../../form/BootstrapInput';

export const TabPaneUnit = ({
  fieldNamePrefix, locale, handleSubmit 
}) => {
  return (
    <>
      <Field
        name={`${fieldNamePrefix}${locale}.title`}
        component={BootstrapInput}
        type="text"
        label={'Title'}
        onBlur={handleSubmit}
      />
      <Field
        name={`${fieldNamePrefix}${locale}.goal`}
        component={BootstrapInput}
        type="input"
        label={'Goal'}
        onBlur={handleSubmit}
      />
    </>
  );
};
