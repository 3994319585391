import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import doneImage from '../../shared/images/check-bg-greeen.svg';
import successImage from '../../shared/images/check-bg-greeen2-small.svg';
import failImage from '../../shared/images/cancel-bg-red.svg';
import { Media, Button, Row, Col } from 'reactstrap';
import './LessonRoundResult.scss';
import { getSupportedLanguage } from '../../utils/translate';
import { LOCALES } from '../../shared/constants';
import { DefaultBtn } from '../fragments/default_btn/DefaultBtn';
import { ResultHeader } from '../fragments/result_header/ResultHeader';
import { getResultStats } from '../../shared/helpers/results';
import { BREAKPOINT_MOBILE_LAYOUT } from '../../shared/constants/theme';

function LessonRoundResult(props) {
  let {
    currentLessonResult,
    currentLesson,
    round,
    lng,
    currentUnit,
    t,
    screenWidth,
  } = props;

  if (
    !currentLessonResult ||
    !currentLessonResult.taskResults ||
    !currentLesson ||
    !currentUnit
  )
    return <React.Fragment></React.Fragment>;

  lng = getSupportedLanguage(lng);
  const stats = getResultStats(currentLessonResult, round);

  return (
    <Row>
      <Col
        lg={{
          offset: 2,
          size: 8,
        }}
      >
        <Row className="task-result">
          <Col>
            {screenWidth > BREAKPOINT_MOBILE_LAYOUT ? (
              <ResultHeader
                leftLabel={`${t('unitListItem')} ${currentUnit.number}`}
                centerLabel={t('resultExercises', {
                  current: stats.successCount,
                  total: stats.totalCount,
                })}
              />
            ) : (
              <Row className="xs-header-row">
                <Col>
                  <p>{`${t('unitListItem')} ${currentUnit.number}`}</p>
                </Col>
                <Col>
                  <p>
                    {t('resultExercises', {
                      current: stats.successCount,
                      total: stats.totalCount,
                    })}
                  </p>
                </Col>
              </Row>
            )}

            <Row className="title-container">
              <h1 className="title">{t('lessonTaskResultHeader')}</h1>
            </Row>

            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: '24%' }}></th>
                  <th style={{ width: '38%' }}>Score</th>
                  <th style={{ width: '38%' }}>
                    {props.t('lessonTaskResultTable')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentLessonResult.taskResults[round].map(
                  (taskResult, index) => (
                    <tr key={index}>
                      <td>
                        {currentLesson.tasks[round][index]?.word}
                      </td>
                      <td>
                        {taskResult.success ? (
                          <Media object src={successImage} className="icon" />
                        ) : (
                          <Media object src={failImage} className="icon" />
                        )}
                      </td>
                      <td>{taskResult.attempts}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>

            <Row className="btn-container">
              <DefaultBtn
                label={props.t('lessonExerciseTaskButtonContinue')}
                onClick={() => props.next()}
              />
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

LessonRoundResult.propTypes = {
  currentLessonResult: PropTypes.object,
  currentLesson: PropTypes.object,
  round: PropTypes.number,
  next: PropTypes.func,
  currentUnit: PropTypes.object,
  screenWidth: PropTypes.number,
};

export default withNamespaces()(LessonRoundResult);
