import React from 'react';
import { useSelector } from 'react-redux';
import {
  Modal, ModalBody, Spinner as ReactstrapSpinner 
} from 'reactstrap';

export const Spinner = () => {
  const spinnerVisible = useSelector((state) => state.spinnerVisible);
    
  return (
    <Modal style={{ zIndex: 999 }} isOpen={spinnerVisible}>
      <ModalBody>
        <div style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: 12 }}>Updating..</div>
          <ReactstrapSpinner color="success"/>
        </div>
      </ModalBody>
    </Modal>
  );
};
