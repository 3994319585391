import {
  put,call,takeEvery, delay 
} from 'redux-saga/effects';
import { v1 as uuid }  from 'uuid';
import { ALERT } from '../../shared/constants';
import * as types from '../types';


export function* newAlert(options) {
  let defaultOptions = {
    context: ALERT.DEFAULT_CONTEXT,
    dismissable: true,
    dimissAfter: 2000,
    level: ALERT.DEFAULT_LEVEL
  };

  Object.assign(defaultOptions, options);
  const alert = {
    uuid: uuid(),
    level: defaultOptions.level,
    message: options.message,
    context: defaultOptions.context,
    dismissable: defaultOptions.dismissable,
    dimissAfter: defaultOptions.dimissAfter,
  };
  yield put({
    type: types.ADD_ALERT,
    payload: alert
  });
  if (alert.dimissAfter > 0) {
    yield delay(alert.dimissAfter);
    yield put({
      type: types.REMOVE_ALERT,
      payload: alert
    });
  }
}

export function* newInfoAlert(action){
  yield call(newAlert,Object.assign(action.payload, {
    level:ALERT.LEVELS.INFO,
    dismissable:true 
  }));
}
export function* newSuccessAlert(action){
  yield call(newAlert,Object.assign(action.payload, {
    level:ALERT.LEVELS.SUCCESS,
    dimissAfter:2 
  }));
}
export function* newWarningAlert(action){
  yield call(newAlert,Object.assign(action.payload, { 
    level:ALERT.LEVELS.WARNING,
    dismissable:true
  }));
}
export function* newErrorAlert(action){
  let options = {
    dismissable: true,
    dimissAfter: 0,
    level: ALERT.LEVELS.ERROR
  };

  Object.assign(options, action.payload);
  yield call(newAlert,options);
}

export function* alertSagas() {
  yield takeEvery(types.NEW_ALERT_INFO, newInfoAlert);
  yield takeEvery(types.NEW_ALERT_SUCCESS, newSuccessAlert);
  yield takeEvery(types.NEW_ALERT_WARNING, newWarningAlert);
  yield takeEvery(types.NEW_ALERT_ERROR, newErrorAlert);
}

export default alertSagas;