export const getResultStats = (lessonResult, round) => {
  let successCount = 0;
  let totalCount = 0;
  const roundResults = lessonResult?.taskResults?.[round];

  for (let i = 0; i < roundResults?.length; i++) {
    if (roundResults[i].success) {
      successCount++;
    }
    totalCount++;
  }

  return {
    successCount,
    totalCount,
  };
};

export const parseUnitNumberFromPrononciationErrorId = (errorId) => {
  const match = errorId.match(/unit(\d+)_/);
  return match ? parseInt(match[1], 10) : -1;
};
