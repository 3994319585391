import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import checkImage from '../../shared/images/check-bg-greeen2-small.svg';
import { Media } from 'reactstrap';
import './Streak.scss';

function Streak(props) {
  let weekdays = ['Mo','Di','Mi','Do','Fr','Sa','So'];
  return (
    <div className="streak-container">
      <p className='text-normal'>Deine Woche im Überblick:</p>
      {/* {props.t('streakContainer')} */}
      <div className="streak">
        {props.streak.map((active,index)=>{
          return active ? (
            <div className="active" key={index}>
              <div className="text-normal">{weekdays[index]}</div>
              <div className="icon-container"><Media src={checkImage} className="icon" /></div>
            </div>
          ) : (
            <div className="inactive" key={index}>
              <div className="text-normal">{weekdays[index]}</div>
              <div className="icon-container"></div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

Streak.propTypes = { streak:PropTypes.array, };

export default withNamespaces()(Streak);

