import React from 'react';
import PropTypes from 'prop-types';
import './TaskFeedback.scss';

export const TaskFeedback = (props) => {
  const { state, label } = props;

  return (
    <div className="task-feedback-container">
      <div className="task-feedback-container-inner">
        <div className={`task-feedback-icon ${state}-icon`}></div>
        <div className={`task-feedback-label ${state}-label`}>{label}</div>
      </div>
    </div>
  );
};

TaskFeedback.propTypes = {
  state: PropTypes.string,
  label: PropTypes.string,
};
