import React, { useRef } from 'react';
import { Button } from 'reactstrap';

export const FileUploader = ({
  onChange, label, accept 
}) => {
  const inputRef = useRef(null);
  
  const onClick = () => {
    inputRef.current.click();
  };

  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <>
      <Button onClick={onClick}>{label}</Button>
      <input
        type="file"
        ref={inputRef}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept={accept}
      />
    </>
  );
};