import React from 'react';
import { Container } from 'reactstrap';
import './ContentContainer.scss';
export default function ContentContainer(props) {
  return (
    <Container className='main'>
      {props.children}
    </Container>
  );
}
