import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Row, Col, Media, Container, Button } from 'reactstrap';
import checkImage from '../../shared/images/check-green-big.svg';
import checkmarkIcon from '../../shared/images/new/checkmark-thin.svg';
import LessonExerciseTaskContent from './LessonExerciseTaskContent';
import translate, { getSupportedLanguage } from '../../utils/translate';
import { LOCALES } from '../../shared/constants/locales';
import { SuccessResultIcon } from '../fragments/result_icon/SuccessResultIcon';
import { ColoredWord } from '../fragments/colored_word/ColoredWord';
import { TaskCaption } from '../fragments/task_caption/TaskCaption';

class LessonExerciseTaskOptions extends React.Component {
  static propTypes = {
    round: PropTypes.number,
    task: PropTypes.object,
    correctOption: PropTypes.string,
    next: PropTypes.func,
    selectOption: PropTypes.func,
  };

  renderTaskWord = (task) => {
    const { attempts, success, correctOption } = this.props;

    let showHint = correctOption && (success || attempts > 1);
    let className = success ? ' succeed' : attempts > 0 ? ' failed' : '';
    let hintLabel = success
      ? `${this.props.t('lessonExerciseTaskHintCorrect')} `
      : `${this.props.t('lessonExerciseTaskHintTip')}: `;
    if (showHint) {
      return (
        <div className="task">
          <div>
            <span className="article">{(task.article || '') + ' '}</span>
            <ColoredWord
              word={task.word}
              annotatedWord={task.annotatedWord}
              success={success}
              attempts={attempts}
            />
          </div>
          <div className={'hint-subline' + className}>
            {hintLabel} {correctOption}
          </div>
        </div>
      );
    } else {
      return (
        <div className="task">
          <span className={className}>
            <span className="article">{(task.article || '') + ' '}</span>
            <ColoredWord
              word={task.word}
              annotatedWord={task.annotatedWord}
              success={success}
              attempts={attempts}
            />
            <div className={'hint-subline'}></div>
          </span>
        </div>
      );
    }
  };

  renderButtons = (task) => {
    const { success, selectOption, attempts } = this.props;

    if (!success && attempts >= 3) {
      return <></>;
    }

    if (success) {
      return (
        <Row className="option-container">
          <SuccessResultIcon />
        </Row>
      );
    }

    return (
      <div className="option-container">
        {task.options &&
          task.options.map((option, index) => {
            return (
              <div
                key={index}
                className="option"
                onClick={selectOption.bind(this, option)}
              >
                {option.replaceAll('*', '')}
              </div>
            );
          })}
      </div>
    );
  };

  render() {
    const { task, attempts, success, correctOption } = this.props;
    const lng = getSupportedLanguage(this.props.lng);

    if (!task) return <React.Fragment />;

    return (
      <Row className="round-container">
        <Col>
          <LessonExerciseTaskContent task={task}></LessonExerciseTaskContent>
          <TaskCaption
            taskArticle={task.article}
            taskWord={task.word}
            taskAnnotatedWord={task.annotatedWord}
            success={success}
            attempts={attempts}
            audioProcessing={false}
            correctOption={correctOption}
            feedbackMessage={translate(task, 'feedback', lng)}
          />
          {this.renderButtons(task)}
        </Col>
      </Row>
    );
  }
}

export default withNamespaces()(LessonExerciseTaskOptions);
