import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Row 
} from 'reactstrap';
import './DefaultBtn.scss';

export const DefaultBtn = (props) => {
  const {
    label = '', onClick = () => {}, className = '', disabled, postfixIcon, type 
  } = props;
  return (
    <Button 
      className={`default-btn ${className}`}
      disabled={disabled}
      onClick={onClick}
      active={true}
      color={'rgba(156, 223, 22, 0.32)'}
      outline={false}
      type={type}
    >
      <Row className='content-container'>
        <Col>{label}</Col>
        {postfixIcon && (
          <Col className='icon-container'>
            {postfixIcon}
          </Col>
        )}
      </Row>
    </Button>
  );
};

DefaultBtn.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  postfixIcon: PropTypes.element,
  type: PropTypes.string,
};