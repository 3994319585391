import React from 'react';
import { Field } from 'react-final-form';
import BootstrapInput from '../../form/BootstrapInput';

export const TabPaneHelpContent = ({
  locale,
  fieldNamePrefix,
  handleSubmit,
}) => {
  return (
    <>
      <Field
        name={`${fieldNamePrefix}${locale}.title`}
        component={BootstrapInput}
        type="text"
        label={'Title'}
        onBlur={handleSubmit}
      />
      <Field
        name={`${fieldNamePrefix}${locale}.text`}
        component={BootstrapInput}
        type="textarea"
        label={'Text'}
        onBlur={handleSubmit}
      />
    </>
  );
};
