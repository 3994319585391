import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';

import translationEN from '../locales/en/translation.json';
import translationDE from '../locales/de/translation.json';
import translationVI from '../locales/vi/translation.json';

const resources = {
  en: { translation: translationEN },
  de: { translation: translationDE },
  vi: { translation: translationVI },
};

i18n
  .use(detector)
  .use(reactI18nextModule)
  .init({
    resources,
    fallbackLng: 'de',
    keySeparator: false,
    interpolation: { escapeValue: false },
    detection: { order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'], }
  });

export default i18n;
