import ApiService from './api';

export default class UploadService extends ApiService {
  constructor(token) {
    super(token);
  }

  async uploadFile(file, metadata){
    let formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('metadata', JSON.stringify(metadata));
    
    return this.postWithFormData('/upload', formData);
  }
}

