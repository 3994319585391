import { SET_GLOBAL_DIALOG , REMOVE_GLOBAL_DIALOG } from '../types';
export function globalDialog(type, props={}) {
  return  {
    type:SET_GLOBAL_DIALOG,
    payload:{
      type,
      props 
    }
  };
}


export function removeGlobalDialog(){
  return  { type:REMOVE_GLOBAL_DIALOG };
}