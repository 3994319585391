import { combineReducers } from 'redux';

import userReducer from './user';
import authReducer from './auth';
import feedbackReducer from './feedback';
import unitReducer from './unit';
import lessonReducer from './lesson';
import alertReducer from './alert';
import resultReducer from './result';
import globalDialogReducer from './globalDialog';
import spinnerVisibleReducer from './spinner';

const rootReducer = combineReducers(
  {
    ...userReducer,
    ...feedbackReducer,
    ...authReducer,
    ...unitReducer,
    ...lessonReducer,
    ...alertReducer,
    ...resultReducer,
    ...globalDialogReducer,
    ...spinnerVisibleReducer,
  }
);

export default rootReducer;