import ApiService from './api';

export default class FeedbackService extends ApiService{
  constructor(token=null) {
    super(token);
  }
  async getAuthenticatedUser(){
    return this.get( '/fee/');
  }

  async saveFeedback(feedback){
    return this.post('/feedback/', feedback);
  }

}

