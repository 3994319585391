import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withNamespaces } from "react-i18next";
import { Col, Row } from "reactstrap";
import translate, { getSupportedLanguage } from "../utils/translate";
import { WebtrekkSmartPixelReact } from "@webtrekk-smart-pixel/react";
import Streak from "../components/stats/Streak";
import { DefaultBtn } from "../components/fragments/default_btn/DefaultBtn";
import { ProgressPanel } from "../components/fragments/progress_panel/ProgressPanel";
import { ProgressEntity } from "../components/fragments/progress_entity/ProgressEntity";
import { BGImage } from "../components/fragments/bg_image/BGImage";
import bgImage from "../shared/images/bg/progress.svg";
import "./ProgressUnits.scss";

class ProgressUnits extends Component {
  static propTypes = { prop: PropTypes };

  onGoBack() {
    this.props.history.push("/progress/");
  }

  componentDidMount() {
    WebtrekkSmartPixelReact.page("aussprachetraining_goethe_de.units", {});
    WebtrekkSmartPixelReact.track();
  }

  render() {
    let { units, unitsCompleted, unitsResults, streak } = { ...this.props };
    const lng = getSupportedLanguage(this.props.lng);
    let nextUnitButton = null;
    return (
      <Row className="progress-units-page">
        <Col
          lg={{
            offset: 2,
            size: 4,
          }}
        >
          <h1 className="page-title">{this.props.t("Progress")}</h1>
          <Streak streak={streak} />

          <ProgressPanel
            title={this.props.t("progressUnits")}
            onBackClick={() => this.onGoBack()}
          >
            <Col>
              {units.map((unit, index) => {
                let unitCompleted = unitsCompleted.indexOf(unit._id) > -1;
                let unitResult = unitsResults[unit._id];
                let first = index === 0;
                let last = units.length - 1 === index;
                let nextUnit = units[index + 1] || null;
                let badgeClass = last ? "last" : first ? "first" : "middle";
                let lastCompleted =
                  unitsCompleted.indexOf(unit._id) > -1 &&
                  nextUnit &&
                  unitsCompleted.indexOf(nextUnit._id) == -1;
                if (lastCompleted) {
                  nextUnitButton = nextUnit;
                }
                badgeClass = lastCompleted
                  ? badgeClass + " last-completed"
                  : badgeClass;

                return (
                  <ProgressEntity
                    key={index}
                    isCompleted={unitCompleted && unitResult}
                    isLast={last}
                    title={translate(unit, "title", lng)}
                    ratio={unitResult?.ratio || 0}
                    date={unitResult?.finishedAt}
                    time={unitResult?.minutesSpend}
                  />
                );
              })}
            </Col>
          </ProgressPanel>

          {nextUnitButton && (
            <Row className="no-margin">
              <DefaultBtn
                label={`${this.props.t("progressGoTo")} ${
                  nextUnitButton.number
                }`}
                onClick={() => {
                  this.props.history.push("/unit/" + nextUnitButton._id);
                }}
              />
            </Row>
          )}
        </Col>
        <BGImage
          imageUrl={bgImage}
          className="screen-height"
          desktopStyle={{ paddingBottom: 40 }}
          mobileStyle={{ top: this.props.anonymousUser ? 240 : 195 }}
        />
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  units: state.units,
  unitsCompleted: state.unitsCompleted,
  unitsResults: state.unitsResults,
  streak: state.streak,
  anonymousUser: state.anonymousUser,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(ProgressUnits));
