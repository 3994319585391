import {
  takeEvery, put, call, select
} from 'redux-saga/effects';
import * as types from '../types';
import FeedbackService from '../../service/feedback';



function* saveFeedback({ payload }) {
  let { userProfile, didFeedback } = yield select((state) => state);
  const feedbackService = yield call(() => new FeedbackService());
  const feedack = {
    type: payload.type,
    data: payload.data,
    userProfile,
    createdAt: new Date()
  };
  try {
    let result = yield call([feedbackService, feedbackService.saveFeedback], feedack);
    didFeedback[feedack.type] = true;
    yield put({
      type: types.SET_DID_FEEDBACK,
      payload: didFeedback 
    });
    localStorage.setItem('didFeedback', JSON.stringify(didFeedback));
  } catch (error) {
    console.error(error);
  }
}

export function* feedbackSagas() {
  yield takeEvery(types.SAVE_FEEDBACK, saveFeedback);

}

export default feedbackSagas;