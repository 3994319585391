import React from 'react';
import PropTypes from 'prop-types';
import './ColoredWord.scss';
import { COLORED_WORD_SEPARATOR } from '../../../shared/constants/lesson';

export const ColoredWord = (props) => {
  const { word, annotatedWord, success, attempts, forceDefaultStyle = false } = props;

  const extractPartsWithStartChar = (arr, startChar) => {
    const result = [];
    arr.forEach(function (element) {
      if (element.startsWith(startChar)) {
        result.push(element.substring(0, 2), element.substring(2));
      } else {
        result.push(element);
      }
    });
    return result;
  };

  const splitForColor = (word) => {
    const result = [];
    let colored = word.startsWith(COLORED_WORD_SEPARATOR);
    let parts = word.split(COLORED_WORD_SEPARATOR);
    parts.forEach((part, index) => {
      if (part !== '' || index > 0) {
        result.push({
          colored: colored,
          text: part,
        });
        colored = !colored;
      }
    });
    return result;
  };

  const splitForLongSounds = (arr) => {
    const result = [];
    for (const arrElement of arr) {
      let arrElementSplit = arrElement.text.split(/(?=_)/);
      arrElementSplit = extractPartsWithStartChar(arrElementSplit, '_');
      arrElementSplit.forEach((part) => {
        const long = part.startsWith('_');
        result.push({
          ...arrElement,
          long,
          text: part.replace('_', ''),
        });
      });
    }
    return result;
  };

  const splitForShortSounds = (arr) => {
    const result = [];
    for (const arrElement of arr) {
      let arrElementSplit = arrElement.text.split(/(?=\..)/);
      arrElementSplit = extractPartsWithStartChar(arrElementSplit, '.');
      arrElementSplit.forEach((part) => {
        const short = part.startsWith('.');
        result.push({
          ...arrElement,
          short,
          text: part.replace('.', ''),
        });
      });
    }
    return result;
  };

  let formattedAnnotatedWordArray = splitForColor(annotatedWord);
  formattedAnnotatedWordArray = splitForLongSounds(formattedAnnotatedWordArray);
  formattedAnnotatedWordArray = splitForShortSounds(formattedAnnotatedWordArray);

  const className = success ? 'succeed' : 'failed';

  if (attempts > 0 && !forceDefaultStyle) {
    return (
      <>
        {formattedAnnotatedWordArray.map((part, index) => {
          const coloredClassName = part.colored ? `colored-text-${className}` : ''

          return (
            <span
                key={index}
                className={`colored-text ${coloredClassName}`}
            >
              {part.text}

              {part.long && <div className="long-sound"></div>}
              {part.short && (
                  <div className="short-sound">
                    <div className="short-sound-inner"></div>
                  </div>
              )}
            </span>
          )
        })}
      </>
    );
  } else {
    return (
      <span className="colored-text">
        {word}
      </span>
    );
  }
};

ColoredWord.propTypes = {
  word: PropTypes.string,
  annotatedWord: PropTypes.string,
  success: PropTypes.bool,
  attempts: PropTypes.number,
  forceDefaultStyle: PropTypes.bool,
};
