import React, { useState, useEffect } from 'react';
import {
  Navbar,
  Col,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  Button
} from 'reactstrap';
import './Footer.scss';
import homeIcon from '../../shared/images/new/home.svg';
import homeColoredIcon from '../../shared/images/new/home-colored.svg';
import progressIcon from '../../shared/images/new/graph.svg';
import progressColoredIcon from '../../shared/images/new/graph-colored.svg';
import {
  Link, useLocation, useHistory 
} from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';


function Footer(props) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const anonymousUser = useSelector(state => state.anonymousUser);
  const [showModal, setShowModal] = useState(false);
  let homeIsActive = location.pathname === '/';
  let progressIsActive = location.pathname.indexOf('/progress') === 0;


  let onProgressClick = event => {
    event.preventDefault();
    if (anonymousUser) {
      setShowModal(true);
    } else {
      history.push('/progress');
    }
  };

  return (
    <Navbar className="fixed-bottom footer">
      <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
        <ModalHeader toggle={() => setShowModal(!showModal)} />
        <ModalBody>
          <h2>{props.t('footerModalBodyHeader')}</h2>
          <p>
            {' '}
            {props.t('footerModalBodyParagraph')}
          </p>
          <Button block color="primary" onClick={() => {props.logout();}}>
            {props.t('footerModalBodyButtonRegister')}
          </Button>
          <Button block color="primary" onClick={() => {props.logout();}}>
            {props.t('Log in')}
          </Button>
        </ModalBody>
      </Modal>
      <Col xs={6}>
        <Link to="/" className={homeIsActive ? 'active' : ''}>
          <Media
            object
            src={homeIsActive ? homeColoredIcon : homeIcon}
            className="icon"
          />
          <div className="subtitle">{props.t('footerLinkHome')}</div>
        </Link>
      </Col>
      <Col xs={6}>
        <a
          href="#"
          onClick={event => onProgressClick(event)}
          className={progressIsActive ? 'active' : ''}
        >
          <Media
            object
            src={progressIsActive ? progressColoredIcon : progressIcon}
            className="icon"
          />
          <div className="subtitle">{props.t('footerIconProgress')}</div>
        </a>
      </Col>
    </Navbar>
  );
}

export default withNamespaces()(Footer);
