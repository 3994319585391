import React, { useContext, useMemo } from 'react';
import { AudioContext } from '../../context/AudioContext';
import { CircleProgressBar } from './CircleProgressBar';
import './StartRecordButton.scss';

export const StartRecordButton = () => {
  const { startRecord, attempts } = useContext(AudioContext);

  const btnClassName = useMemo(() => {
    return attempts === 3 ? 'disabled' : '';
  }, [attempts]);

  return (
    <CircleProgressBar value={0}>
      <div
        className={`start-record-button ${btnClassName}`}
        onClick={attempts < 3 ? startRecord : () => {}}
      ></div>
    </CircleProgressBar>
  );
};
