import React, { memo } from 'react';
import { Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
const ProtectedRoute = ({
  component: Comp,
  ...rest
}) => {
  const authenticatedUser = useSelector(state => state.authenticatedUser);
  const anonymousUser = useSelector(state => state.anonymousUser);
  const loggingIn = useSelector(state => state.loggingIn);
  
  return <Route
    {...rest}
    render={props =>{
      
      return authenticatedUser||anonymousUser ? 
        <Comp {...props} /> : 
        loggingIn?<React.Fragment/>:<Redirect to="/login" />;
    }
    }
  />;
};
  


export default memo(ProtectedRoute);