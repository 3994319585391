import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Media, Row 
} from 'reactstrap';
import closeIcon from '../../../shared/images/new/close.svg';
import './ProgressPanel.scss';

export const ProgressPanel = (props) => {
  const {
    onBackClick, title, children 
  } = props;

  return (
    <Row className='progress-card'>
      <Col>
        <Row className='progress-card-header'>
          <Col className='flex-grow-0'>
            <Media src={closeIcon} className="icon" onClick={onBackClick} />
          </Col>
          <Col>
            <p className='title'>{title}</p>
          </Col>
          <Col className='flex-grow-0 placeholder'>
          </Col>
        </Row>
        <Row className='progress-content-container'>
          {children}
        </Row>
      </Col>
    </Row>
  );
};

ProgressPanel.propTypes = {
  onBackClick: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node
};