import { WebtrekkSmartPixelReact } from '@webtrekk-smart-pixel/react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import Streak from '../components/stats/Streak';
import UnitList from '../components/unit/UnitList';
import UserProfile from '../components/user/UserProfile';
import { BGImage } from '../components/fragments/bg_image/BGImage';
import bgImage from '../shared/images/bg/home.svg';
import { UnitRecommendation } from '../components/recommendation/UnitRecommendation';
import { UnitRecommendationContextProvider } from '../context/UnitRecommendationContext';
import './Home.scss';

class Home extends Component {
  static propTypes = {
    units: PropTypes.array,
    history: PropTypes.object,
  };

  onSelectUnit(unit) {
    this.props.history.push('/unit/' + unit._id);
  }

  userProfileComplete() {
    let userProfile = this.props.userProfile;
    if (!userProfile?.language?.length || !!userProfile?.level?.length) {
      return false;
    }
    if (!userProfile?.birthyear?.length || !!userProfile?.gender?.level) {
      return false;
    }
    return true;
  }
  renderUserProfileModal() {
    if (!this.userProfileComplete()) {
      return;
    } else {
      return <React.Fragment />;
    }
  }

  componentDidMount() {
    WebtrekkSmartPixelReact.page('aussprachetraining_goethe_de.home', {});
    WebtrekkSmartPixelReact.track();
  }

  render() {
    return (
      <Row className="home">
        <UserProfile />
        <Col
          lg={{
            offset: 2,
            size: 4,
          }}
        >
          <Row>
            <Col>
              <h1 className="page-title">{this.props.t('homeGreet')}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              {!this.props.anonymousUser && (
                <Streak streak={this.props.streak} />
              )}

              <div className="unit-list-container">
                <UnitRecommendationContextProvider>
                  <UnitRecommendation />
                </UnitRecommendationContextProvider>

                <UnitList
                  unitsCompleted={this.props.unitsCompleted}
                  lessons={this.props.lessons}
                  units={this.props.units}
                  lessonsCompleted={this.props.lessonsCompleted}
                  onSelectUnit={(unit) => this.onSelectUnit(unit)}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <BGImage
          mobileStyle={{ top: this.props.anonymousUser ? 100 : 195 }}
          desktopStyle={{ paddingBottom: 40 }}
          imageUrl={bgImage}
          className="screen-height"
        />
      </Row>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps(state) {
  return {
    units: state.units,
    lessons: state.lessons,
    streak: state.streak,
    userProfile: state.userProfile,
    unitsCompleted: state.unitsCompleted,
    anonymousUser: state.anonymousUser,
    lessonsCompleted: state.lessonsCompleted,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withNamespaces()(Home));
