import * as types from '../types';

const moduleReducers = { didFeedback: setDidFeedback, };

function setDidFeedback(state = {}, action) {
  switch (action.type) {
  case types.SET_DID_FEEDBACK:
    return action.payload;
  default:
    return state;
  }
}

export default moduleReducers;