import {
  takeEvery, put, call, select
} from 'redux-saga/effects';
import * as types from '../types';
import LessonService from '../../service/lesson';


function* fetchLessons(){    
  try{
    /** @type {LessonService}  */        
    const lessonService = yield call(()=>new LessonService());
    let lessons = yield call([lessonService, lessonService.getLessons]);           
    yield put({
      type:types.SET_LESSONS,
      payload:lessons 
    });
  }catch(error){
    yield put({
      type:types.NEW_ALERT_ERROR,
      payload:{ message:'Error fetching lessons:' + error.message }
    });
    console.error('fetchLessons error',error);
  }
}

function* startLesson(action){    
  try {
    // let lessons = yield select((state) => state.lessons);      
    let { lessons } = yield select((state) => state);      

    if(lessons.length === 0) {
      yield call(fetchLessons);
      lessons  = yield select((state) => state.lessons);      
    }
     
    let currentLesson = lessons.find(lesson=> lesson._id === action.payload);
    if(currentLesson === null) {
      yield put({
        type:types.NEW_ALERT_ERROR,
        payload:{ message:'Lesson cannot be loaded' }
      });
    } else {
      let currentLessonState = { ...currentLesson };
      currentLessonState.tasks = [];
      currentLesson.tasks.forEach(lesson =>{
        currentLessonState.tasks[lesson.round]=currentLessonState.tasks[lesson.round]||[];
        currentLessonState.tasks[lesson.round].push(lesson);
      });
      yield put({
        type:types.SET_CURRENT_LESSON,
        payload:currentLessonState
      });
      yield put({
        type: types.SELECT_UNIT,
        payload: currentLesson.unitId
      });
    }
  } catch(error) {
    yield put({
      type: types.NEW_ALERT_ERROR,
      payload:{ message:'Error starting lesson:' + error.message }
    });
    console.error('setCurrentLesson error',error);
  }
}


export function* lessonSagas() {
  yield takeEvery(types.FETCH_LESSONS, fetchLessons);
  yield takeEvery(types.START_LESSON, startLesson);
  // yield takeEvery(types.LESSON_HANDLE_ANSWER, handleAnswer);
}
  
export default lessonSagas;