import { WebtrekkSmartPixelReact } from '@webtrekk-smart-pixel/react';
import React, { useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import './Privacy.scss';
const Privacy = withNamespaces()((props)=>{

  useEffect(() => {
    WebtrekkSmartPixelReact.page('aussprachetraining_goethe_de.privacy', { });
    WebtrekkSmartPixelReact.track();
  });

  return (
    <div className="privacy">

      <div dangerouslySetInnerHTML={{ __html:props.t('privacyText_0') }}/>
      <div dangerouslySetInnerHTML={{ __html:props.t('privacyText_1') }}/>
      <div dangerouslySetInnerHTML={{ __html:props.t('privacyText_2') }}/>
      <div dangerouslySetInnerHTML={{ __html:props.t('privacyText_3') }}/>
      <div dangerouslySetInnerHTML={{ __html:props.t('privacyText_4') }}/>
      <div dangerouslySetInnerHTML={{ __html:props.t('privacyText_5') }}/>
      <div dangerouslySetInnerHTML={{ __html:props.t('privacyText_6') }}/>
      <div dangerouslySetInnerHTML={{ __html:props.t('privacyText_7') }}/>
      <div dangerouslySetInnerHTML={{ __html:props.t('privacyText_8') }}/>
      <div dangerouslySetInnerHTML={{ __html:props.t('privacyText_9') }}/>
    </div>
  );
});
export default Privacy;
