import { useMutation, useQueryClient } from '@tanstack/react-query';
import UserService from '../../service/user';
import { useSelector } from 'react-redux';

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const token = useSelector((state) => state.token);
  const userService = new UserService(token);
  const query = ['users-list'];

  return useMutation(
    (user) => {
      return userService.updateUser(user);
    },
    {
      onMutate: async (user) => {
        await queryClient.cancelQueries(query);
        const oldUsers = queryClient.getQueryData(query) || [];
        const updatedUsers = oldUsers.map((oldUser) => {
          if (oldUser._id === user._id) {
            return user;
          }
          return oldUser;
        });
        queryClient.setQueryData(query, updatedUsers);
        return { oldUsers };
      },
      onError: (_error, _vars, context) => {
        if (context !== undefined) {
          queryClient.setQueryData(query, context.oldUsers);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(query);
      },
    },
  );
};