import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import AudioButton from '../global/AudioButton';
import { API_URL } from '../../shared/constants/api';

function LessonExerciseTaskContent(props) {
  let { task } = props;
  const [playingAudio, setPlayingAudio] = useState(false);
  const [playingVideo, setPlayingVideo] = useState(false);
  const handleAudioPlay = () => {
    setPlayingAudio(true);
  };
  const handleAudioEnded = () => {
    setPlayingAudio(false);
  };
  const handleVideoPlay = () => {
    setPlayingVideo(true);
  };

  return (
    <React.Fragment>
      {task.audio && (
        <AudioButton
          playing={playingAudio}
          url={API_URL + task.audio}
          onPlay={handleAudioPlay}
          onEnded={handleAudioEnded}
          onPause={handleAudioEnded}
          repeat={true}
        />
      )}
      {task.video && (
        <ReactPlayer
          playing={playingVideo}
          url={API_URL + task.video}
          config={{ file: { forceVideo: true } }}
          controls={true}
          onPlay={handleVideoPlay}
          onEnded={handleAudioEnded}
          onPause={handleAudioEnded}
          width="100%"
          height="auto"
          height="200px"
          className="text-center"
        />
      )}
      {task.image && (
        <img
          alt={task.word}
          className="img-fluid"
          src={API_URL + task.image}
        ></img>
      )}
    </React.Fragment>
  );
}

LessonExerciseTaskContent.propTypes = {
  //   header: PropTypes.string,
  //   title: PropTypes.string,
  //   subtitle: PropTypes.string,
  //   state: PropTypes.string,
  task: PropTypes.object,
};

export default LessonExerciseTaskContent;
