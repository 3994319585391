import React from 'react';
import { withNamespaces } from 'react-i18next';
import './LoaderAnimation.scss';

export const LoaderAnimation = withNamespaces()((props) => {
  const { t } = props;

  return (
    <>
      <div className="loader-animation-container">
        <div className="loader-animation"></div>
      </div>
    </>
  );
});
