import React, { useState } from 'react';
import {
  Button, Modal, ModalBody, Card, CardBody, CardTitle 
} from 'reactstrap';
import { FormGroup } from 'reactstrap';
import { Field } from 'react-final-form';
import BootstrapInput from '../../form/BootstrapInput';
import { useUpdateUnit } from '../../../hooks/unit/useUpdateUnit';
import { Tabs } from '../tabs/Tabs';
import { TabPaneHelpContent } from '../tabs/TabPaneHelpContent';
import { useUploadFile } from '../../../hooks/upload/useUploadFile';
import { FileUploader } from '../FileUploader';
import { ImagePreviewModal } from './ImagePreviewModal';
import AudioButton from '../../global/AudioButton';
import { VideoPreviewModal } from './VideoPreviewModal';
import { API_URL } from '../../../shared/constants/api';
import { useDispatch } from 'react-redux';
import { setSpinnerVisible } from '../../../redux/actions/spinner';
import './HelpContentModal.scss';
import { substituteFileId } from '../../../shared/helpers/upload';

export const HelpContentModal = ({
  isOpen,
  onClosed,
  unit,
  index,
  form,
  handleSubmit,
}) => {
  const { mutate: uploadFile } = useUploadFile();
  const { mutate: updateUnit } = useUpdateUnit();
  const helpContent = unit?.helpContent[index];
  const dispatch = useDispatch();

  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [playingAudio, setPlayingAudio] = useState(false);

  const onFileUploadSuccess = (result, propertyName) => {
    const updatedUnit = { ...unit };
    let newFilePath = null;
    if (!updatedUnit.helpContent[index][propertyName]) {
      newFilePath = `/files/${propertyName}/${result._id}`;
    } else {
      newFilePath = substituteFileId(
        updatedUnit.helpContent[index][propertyName],
        result._id
      );
    }
    updatedUnit.helpContent[index][propertyName] = newFilePath;
    updateUnit(updatedUnit);
    dispatch(setSpinnerVisible(false));
  };

  const onChangeFileClick = (e, propertyName) => {
    const files = e.target.files;
    if (files.length > 0) {
      dispatch(setSpinnerVisible(true));
      onUploadFile(files[0], (result) =>
        onFileUploadSuccess(result, propertyName)
      );
    }
  };

  const onUploadFile = (file, cb) => {
    if (unit) {
      const metadata = {
        unitId: unit._id,
        scope: 'unitHelp',
      };
      uploadFile({
        file: file,
        metadata,
        cb 
      });
    }
  };

  const isLocalesInvalid = (locale, values) => {
    let result = false;
    let i18n =
      values.helpContent &&
      values.helpContent[index] &&
      values.helpContent[index].i18n;
    if (!i18n || !i18n[locale]) {
      result = true;
    } else {
      if (!i18n[locale]) result = true;
      if (!i18n[locale].title?.length) result = true;
      if (!i18n[locale].text?.length) result = true;
    }
    return result;
  };

  const handleAudioPlay = () => {
    setPlayingAudio(true);
  };

  const handleAudioEnded = () => {
    setPlayingAudio(false);
  };

  return (
    <Modal isOpen={isOpen} onClosed={onClosed} toggle={onClosed}>
      <ModalBody className="body">
        <div style={{ flex: 1 }}>
          <Card>
            <CardBody>
              <CardTitle tag="h3" style={{ marginBottom: 5 }}>
                Localizations
              </CardTitle>
              <FormGroup>
                <Tabs
                  form={form}
                  locales={Object.keys(helpContent?.i18n || {})}
                  tabPaneContentComponent={TabPaneHelpContent}
                  isTabContentInvalidFunc={isLocalesInvalid}
                  fieldNamePrefix={`helpContent.${index}.i18n.`}
                  handleSubmit={handleSubmit}
                />
              </FormGroup>
            </CardBody>
          </Card>

          <FormGroup className="file-uploader-container">
            <label className="uploader-label">Image</label>
            {helpContent?.image ? (
              <Button
                style={{ marginRight: 15 }}
                onClick={() => setImagePreview(helpContent?.image)}
              >
                Preview
              </Button>
            ) : (
              <span style={{ marginRight: 15 }}>No image</span>
            )}
            <FileUploader
              label="Change"
              onChange={(e) => onChangeFileClick(e, 'image')}
              accept="image/*"
            />
          </FormGroup>
          <FormGroup className="file-uploader-container">
            <label className="uploader-label">Audio</label>
            {helpContent?.audio ? (
              <AudioButton
                playing={playingAudio}
                url={API_URL + helpContent?.audio}
                onPlay={handleAudioPlay}
                onEnded={handleAudioEnded}
                onPause={handleAudioEnded}
                style={{
                  paddingBottom: 0,
                  marginRight: 15 
                }}
              />
            ) : (
              <span style={{ marginRight: 15 }}>No audio</span>
            )}
            <FileUploader
              label="Change"
              onChange={(e) => onChangeFileClick(e, 'audio')}
              accept="audio/*"
            />
          </FormGroup>
          <FormGroup className="file-uploader-container">
            <label className="uploader-label">Video</label>
            {helpContent?.video ? (
              <Button
                style={{ marginRight: 15 }}
                onClick={() => setVideoPreview(helpContent?.video)}
              >
                Preview
              </Button>
            ) : (
              <span style={{ marginRight: 15 }}>No video</span>
            )}
            <FileUploader
              label="Change"
              onChange={(e) => onChangeFileClick(e, 'video')}
              accept="video/*"
            />
          </FormGroup>
          <ImagePreviewModal
            isOpen={!!imagePreview}
            image={imagePreview}
            onClosed={() => setImagePreview(null)}
          />
          <VideoPreviewModal
            isOpen={!!videoPreview}
            video={videoPreview}
            onClosed={() => setVideoPreview(null)}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};
