import ApiService from './api';

export default class ResultService extends ApiService{
  constructor(token) {
    super(token);
  }
  async storeLessonResult(data){
    return this.post( '/result',data);
  }
  async getUserResults(){
    return this.get('/result');
  }
}

