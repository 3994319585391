import React from 'react';
import './Badge.scss';

export const Badge = ({ text }) => {
  return (
    <div className="badge">
      <p className="badge-text">{text}</p>
    </div>
  );
};
