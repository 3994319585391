import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Media,
  Button,
  Table,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Row,
  Col,
} from 'reactstrap';
import arrowLeftIcon from '../shared/images/arrow-left.svg';
import searchIcon from '../shared/images/search.svg';
import { useUsersList } from '../hooks/user/useUsersList';
import { useUpdateUser } from '../hooks/user/useUpdateUser';
import './UsersList.scss';

export const UsersList = () => {
  const history = useHistory();
  const itemsPerPage = 10;
  const authenticatedUser = useSelector((state) => state.authenticatedUser);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState('');
  const { data: usersList } = useUsersList(currentPage, itemsPerPage, search);
  const { mutate: updateUser } = useUpdateUser();

  useEffect(() => {
    if (!authenticatedUser.isAdmin) {
      history.replace('/');
    }
  }, [authenticatedUser, history]);

  const onGoBackClick = () => {
    history.goBack();
  };

  const onToggleIsAdmin = (user) => {
    updateUser({
      ...user,
      isAdmin: !user.isAdmin 
    });
  };

  const hasMore = () => {
    return usersList?.total > (currentPage + 1) * itemsPerPage;
  };

  const getLastPageIndex = () => {
    return Math.ceil(usersList?.total / itemsPerPage) - 1;
  };

  const onSearchChange = (e) => {
    setCurrentPage(0);
    setSearch(e.target.value);
  };

  return (
    <Row className="users-list">
      <Col lg={{
        offset: 2,
        size: 8 
      }}>
        <div style={{ display: 'flex' }}>
          <Button className="back-btn" onClick={onGoBackClick}>
            <Media src={arrowLeftIcon} className="back-icon" />
          </Button>
          <h3 style={{ marginBottom: 0 }}>Users</h3>
        </div>

        <br />
        <InputGroup style={{ width: 250 }}>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <Media src={searchIcon} className="search-icon" />
            </InputGroupText>
          </InputGroupAddon>
          <Input placeholder="search" value={search} onChange={onSearchChange} />
        </InputGroup>
        <br />

        <Table>
          <thead>
            <tr>
              <th style={{ width: '70%' }}>Email</th>
              <th style={{
                flex: 1,
                textAlign: 'center' 
              }}>Is Admin</th>
            </tr>
          </thead>
          <tbody>
            {usersList?.users?.map((user, index) => (
              <tr key={user._id}>
                <td style={{ width: '70%' }}>{user.email}</td>
                <td style={{
                  flex: 1,
                  textAlign: 'center' 
                }}>
                  <Input
                    type="checkbox"
                    className="table-checkbox"
                    disabled={user._id === authenticatedUser._id}
                    checked={user.isAdmin}
                    onChange={() => onToggleIsAdmin(user)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div style={{
          display: 'flex',
          justifyContent: 'flex-end' 
        }}>
          <Pagination>
            <PaginationItem disabled={currentPage === 0}>
              <PaginationLink first onClick={() => setCurrentPage(0)} />
            </PaginationItem>
            <PaginationItem disabled={currentPage === 0}>
              <PaginationLink
                previous
                onClick={() => setCurrentPage(currentPage - 1)}
              />
            </PaginationItem>
            <PaginationItem active>
              <PaginationLink>{currentPage + 1}</PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={!hasMore()}>
              <PaginationLink
                next
                onClick={() => setCurrentPage(currentPage + 1)}
              />
            </PaginationItem>
            <PaginationItem disabled={!hasMore()}>
              <PaginationLink
                last
                onClick={() => setCurrentPage(getLastPageIndex())}
              />
            </PaginationItem>
          </Pagination>
        </div>
      </Col>
    </Row>
  );
};
