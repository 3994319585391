import React from 'react';
import PropTypes from 'prop-types';
import './ProgressBar.scss';

class ProgressBar extends React.Component {
    static propTypes = {
      taskLength: PropTypes.number,
      progress: PropTypes.number,
    };

    render() {
      const { taskLength, progress } = this.props;
      const items = [];

      for (let index = 0; index < taskLength; index++) {
        const className = (index === progress) ? 'icon is-current': (index < progress) ? 'icon is-complete': 'icon';
        items.push(
          <li key={index} className={className}>
            <svg className={className}></svg>
          </li>
        );
      }

      return (
        
        <ol className="progress-bar-line">
          {items}
        </ol>
      );
    }
}

export default ProgressBar;
