import * as types from '../types';

const moduleReducers = { spinnerVisible: setSpinnerVisible };

function setSpinnerVisible(state = false, action) {
  switch (action.type) {
  case types.SET_SPINNER_VISIBLE:
    return action.payload;
  default:
    return state;
  }
}

export default moduleReducers;