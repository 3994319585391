import React, { useContext } from 'react';
import { AudioContext } from '../../context/AudioContext';
import './PlayRecordedAudioButton.scss';

export const PlayRecordedAudioButton = () => {
  const { playing, playAudio } = useContext(AudioContext);

  if (playing) {
    return (
      <div className="play-audio-animation-container">
        <div className="bar" id="bar0"></div>
        <div className="bar" id="bar1"></div>
        <div className="bar" id="bar2"></div>
        <div className="bar" id="bar3"></div>
        <div className="bar" id="bar4"></div>
        <div className="bar" id="bar5"></div>
      </div>
    );
  }

  return (
    <div className="play-recorded-audio-btn-container" onClick={playAudio}>
      <div className="play-recorded-audio-icon"></div>
    </div>
  );
};
