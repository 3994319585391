import { takeEvery, put, call, select } from 'redux-saga/effects';

import { v1 as uuid } from 'uuid';

import * as types from '../types';
import AuthService from '../../service/auth';
import UserService from '../../service/user';
import {
  PRONONCIATION_ERRORS_STORAGE_KEY,
  ANON_USER_PRONONCIATION_ERRORS_STORAGE_KEY,
} from '../../shared/constants/storage';

function* initUser(action) {
  yield call(restoreAnonymousUser);
  yield call(initToken);
  yield call(initAuthenticatedUser);
  yield put({ type: types.MODAL_SHOWN });
  yield call(restoreIsEditor);
  yield call(restoreDidFeedback);
  yield getUnitRecommendation({});
}

function* initAuthenticatedUser() {
  let { token } = yield select((state) => state);

  if (token) {
    const userService = yield call(() => new UserService(token));
    let authenticatedUser = yield call([
      userService,
      userService.getAuthenticatedUser,
    ]);

    if (authenticatedUser) {
      yield put({
        type: types.SET_AUTHENTICATED_USER,
        payload: authenticatedUser,
      });
      yield put({
        type: types.SET_USER_PROFILE,
        payload: authenticatedUser.profile || {},
      });
      yield put({ type: types.FETCH_USER_RESULTS });
    }
  }
  yield put({
    type: types.SET_LOGING_IN,
    payload: false,
  });
}

function* initToken() {
  const authService = yield call(() => new AuthService());
  let tokenResponse = null;
  try {
    tokenResponse = yield call([authService, authService.getToken]);
  } catch (error) {
    console.error('error :>> ', error);
  }
  if (tokenResponse) {
    yield put({
      type: types.SET_TOKEN,
      payload: tokenResponse.access_token,
    });
  }
}

function* restoreAnonymousUser() {
  let anonymousUserState = yield localStorage.getItem('anonymousUser');
  let userProfileState = yield localStorage.getItem('userProfile');
  let anonymousUser = null;
  let userProfile = null;
  try {
    anonymousUser = JSON.parse(anonymousUserState);
    userProfile = JSON.parse(userProfileState);
  } catch (e) {
    console.error(e);
  }
  if (anonymousUser) {
    yield put({
      type: types.SET_ANONYMOUS_USER,
      payload: anonymousUser,
    });
    yield put({
      type: types.SET_USER_PROFILE,
      payload: userProfile,
    });
    yield put({
      type: types.SET_ANONYMOUS_LESSON_COMPLED,
      payload: null,
    });
  }
}

function* loginAnonymous(action) {
  try {
    const anonUserId = uuid();
    localStorage.setItem('anonymousUser', JSON.stringify(anonUserId));

    yield put({
      type: types.SET_ANONYMOUS_USER,
      payload: anonUserId,
    });
    yield put({
      type: types.SET_USER_PROFILE,
      payload: {},
    });
    yield put({
      type: types.SET_ANONYMOUS_LESSON_COMPLED,
      payload: null,
    });
    yield put({ type: types.MODAL_SHOWN });
    yield restoreIsEditor();
  } catch (error) {
    yield put({
      type: types.NEW_ALERT_ERROR,
      payload: { message: 'Error in login:' + error.message },
    });
    console.error('login error', error);
  }
}

function* logout(action) {
  try {
    let { authenticatedUser, anonymousUser } = yield select((state) => state);
    if (anonymousUser && anonymousUser.length) {
      localStorage.removeItem('unitsCompleted');
      localStorage.removeItem('modalsShown');
      localStorage.removeItem('lessonsCompleted');
      localStorage.removeItem('lessonProgress');
      localStorage.removeItem('didFeedback');
      localStorage.removeItem('currentLessonResult');
      localStorage.removeItem(PRONONCIATION_ERRORS_STORAGE_KEY);
      localStorage.removeItem(ANON_USER_PRONONCIATION_ERRORS_STORAGE_KEY);
    }
    localStorage.removeItem('authenticatedUser');
    localStorage.removeItem('anonymousUser');
    localStorage.removeItem('userProfile');
    yield put({
      type: types.SET_AUTHENTICATED_USER,
      payload: null,
    });
    yield put({
      type: types.SET_ANONYMOUS_USER,
      payload: false,
    });
    if (authenticatedUser) {
      const authService = yield call(() => new AuthService());
      yield call([authService, authService.logout]);
    }
  } catch (error) {
    yield put({
      type: types.NEW_ALERT_ERROR,
      payload: { message: 'Error in logout:' + error.message },
    });
    console.error('login error', error);
  }
}

function* setModalShown({ payload }) {
  let modalsShownStorage = yield localStorage.getItem('modalsShown');
  let modalsShown = [];
  try {
    modalsShown = JSON.parse(modalsShownStorage) || [];
  } catch (e) {
    console.error(e);
  }
  if (payload) {
    if (modalsShown.indexOf(payload) === -1) {
      modalsShown.push(payload);
    }
    localStorage.setItem('modalsShown', JSON.stringify(modalsShown));
  }
  yield put({
    type: types.SET_MODALS_SHOWN,
    payload: modalsShown,
  });
}

function* restoreIsEditor() {
  let isEditorStorage = yield localStorage.getItem('isEditor');
  let isEditor = false;
  try {
    isEditor = JSON.parse(isEditorStorage) || false;
  } catch (e) {
    console.error(e);
  }
  yield put({
    type: types.SET_EDITOR,
    payload: isEditor,
  });
}

function* setEditor({ payload }) {
  yield localStorage.setItem('isEditor', JSON.stringify(payload));
  yield put({
    type: types.SET_EDITOR,
    payload: payload,
  });
}

function* restoreDidFeedback() {
  let didFeedbackStorage = yield localStorage.getItem('didFeedback');
  let didFeedback = {};
  try {
    didFeedback = JSON.parse(didFeedbackStorage) || {};
  } catch (e) {
    console.error(e);
  }
  yield put({
    type: types.SET_DID_FEEDBACK,
    payload: didFeedback,
  });
}

function* saveUserProfile({ payload }) {
  let { authenticatedUser, anonymousUser, token } = yield select(
    (state) => state
  );

  if (anonymousUser) {
    yield localStorage.setItem('userProfile', JSON.stringify(payload));
  } else {
    const userService = yield call(() => new UserService(token));
    const profile = Object.assign(authenticatedUser.profile || {}, payload);
    authenticatedUser.profile = profile;
    try {
      let result = yield call(
        [userService, userService.updateUser],
        authenticatedUser
      );
    } catch (error) {
      console.error(error);
    }
  }

  yield put({
    type: types.SET_USER_PROFILE,
    payload: payload,
  });
}

function* getUnitRecommendation({ payload }) {
  try {
    let { token } = yield select((state) => state);
    if (token) {
      const userService = yield call(() => new UserService(token));
      const recommendation = yield call([
        userService,
        userService.getUserUnitRecommendation,
      ]);
      yield put({
        type: types.SET_UNIT_RECOMMENDATION,
        payload: recommendation,
      });
    }
  } catch (error) {
    yield put({
      type: types.NEW_ALERT_ERROR,
      payload: {
        message: 'Error getting unit recommendation:' + error.message,
      },
    });
    console.error('getUnitRecommendation error', error);
  }
}

export function* userSagas() {
  yield takeEvery(types.INIT_USER, initUser);
  yield takeEvery(types.LOGIN_ANONYMOUS, loginAnonymous);
  yield takeEvery(types.LOGOUT, logout);
  yield takeEvery(types.MODAL_SHOWN, setModalShown);
  yield takeEvery(types.IS_EDITOR, setEditor);
  yield takeEvery(types.SAVE_USER_PROFILE, saveUserProfile);
  yield takeEvery(types.GET_UNIT_RECOMMENDATION, getUnitRecommendation);
}

export default userSagas;
