import React from 'react';
import { Row, Media } from 'reactstrap';
import helpImage from '../../../shared/images/new/help.svg';
import { withNamespaces } from 'react-i18next';
import './TipsBadge.scss';

export const TipsBadge = withNamespaces()((props) => {
  const { onClick, t } = props;

  return (
    <Row className="tips-badge" onClick={onClick}>
      <span className="tips-label">{t('unitTips')}</span>
      <Media src={helpImage} className="icon" />
    </Row>
  );
});
