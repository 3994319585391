import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { ProgressStateIcon } from './progress_state_icon/ProgressStateIcon';
import useScreenWidth from '../../../hooks/layout/useScreenWidth';
import { BREAKPOINT_MOBILE_LAYOUT } from '../../../shared/constants/theme';
import './ProgressCard.scss';

export const ProgressCard = (props) => {
  const {
    icon, title, badgeText, percentage, isSmallCard, onClick 
  } = props;
  const screenWidth = useScreenWidth();

  let iconSize = 0;
  let strokeWidth = 6;
  if (screenWidth > BREAKPOINT_MOBILE_LAYOUT) {
    if (isSmallCard) {
      iconSize = 40;
      strokeWidth = 10;
    } else {
      iconSize = 80;
      strokeWidth = 6;
    }
  } else {
    if (isSmallCard) {
      iconSize = 30;
    } else {
      iconSize = 40;
    }
  }

  return (
    <Row className={`progress-card ${onClick && 'clickable'}`} onClick={onClick}>
      <Col className={isSmallCard ? 'icon-container-small' : 'icon-container'}>
        {percentage === undefined
          ?
          <>
            {icon}
          </>
          :
          <ProgressStateIcon 
            icon={icon}
            percentage={percentage}
            size={iconSize}
            strokeWidth={strokeWidth}
          />
        }
      </Col>
      <Col>
        <Row>
          <p className={isSmallCard ? 'title-small' : 'title'}>{title}</p>
        </Row>
        <Row className='value-container'>
          {badgeText && <p className='value'>{badgeText}</p>}
        </Row>
      </Col>
    </Row>
  );
};

ProgressCard.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  badgeText: PropTypes.string,
  percentage: PropTypes.number,
  titleClassName: PropTypes.string,
  isSmallCard: PropTypes.bool,
  onClick: PropTypes.func,
};