import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';
import ReactPlayer from 'react-player';
import './AudioButton.scss';
import audioIcon from '../../shared/images/new/audio.svg';
import { warningAlert } from '../../redux/actions/alert';
import { useDispatch } from 'react-redux';

function AudioButton(props) {
  const [hasError, setHasError] = useState(false);
  const dispatch = useDispatch();
  let play = () => {
    if (!hasError) {
      if(props.playing){
        props.onPause && props.onPause();
      }else{
        props.onPlay();
      }
    } else {
      dispatch(warningAlert({ message: 'Audio not found' }));
      setHasError(false);
    }
  };
  let onPlay = (params) => {
    props.onPlay && props.onPlay();
  };
  let onEnded = (params) => {
    props.onEnded && props.onEnded();
  };
  let onPause = (params) => {
    props.onPause && props.onPause();
  };
  let handleError = (error) => {
    setHasError(true);
  };
  return (
    <div className="audio-button d-flex justify-content-center" style={props.style ?? {}}>
      <button onClick={play} className={props.playing ? 'playing pulse' : ''}>
        <Media object src={audioIcon} className="icon" />
      </button>
      <ReactPlayer
        playing={props.playing}
        url={props.url}
        config={{ file: { forceAudio: true, }}}
        controls={false}
        onPlay={onPlay}
        onEnded={onEnded}
        onPause={onPause}
        width='0px'
        height='0px'
        onError={handleError}
      />
    </div>
  );
}

AudioButton.propTypes = {
  onClick: PropTypes.func,
  onPlay: PropTypes.func,
  onEnded: PropTypes.func,
  onPause: PropTypes.func,
  playing: PropTypes.bool,
  url: PropTypes.string,
};

export default AudioButton;

