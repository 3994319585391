import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import {
  Row, Col, Media, Button, Modal, ModalBody, ModalHeader, ModalFooter 
} from 'reactstrap';
import mikrofonImage from '../../shared/images/mikrophone.svg';
import audioImage from '../../shared/images/audio.svg';
import './LessonModal.scss';
import ReactPlayer from 'react-player';
import optionsVideo from '../../shared/videos/help_option.mp4';
import recordingVideo from '../../shared/videos/help_recording.mp4';
import { DefaultBtn } from '../fragments/default_btn/DefaultBtn';
import closeIcon from '../../shared/images/new/close.svg';

class LessonModal extends React.Component {
    static propTypes = {
      round: PropTypes.number,
      isOpened: PropTypes.bool,
      onToggle:PropTypes.func,
    };

    renderContent = () => {
      const { round } = this.props;
    
      if(round === 1) {
        return (
          <React.Fragment>
            <ReactPlayer 
              url={optionsVideo}
              config={{ file:{ forceVideo:true, }}}          
              controls={true}
              width='100%'
              height='auto'
            />
            <Row className="footer">
              <Col>
                <p className="text-normal">
                  {this.props.t('lessonModalTitle')}
                </p>
              </Col>
            </Row>
          </React.Fragment>
         
        );
      }
      else {
        return (
          <React.Fragment>
            <ReactPlayer 
              url={recordingVideo}
              config={{ file:{ forceVideo:true, }}}          
              controls={true}
              width='100%'
              height='auto'
            />
            <Row className="footer">
              <Col>
                <p className="text-normal">
                  {this.props.t('lessonModalPlayer')}
                </p>
              </Col>
            </Row>
          </React.Fragment>
        );
      }
    }

    render() {
      const { 
        round, isOpened, onToggle 
      } = this.props;

      return (
        <Modal isOpen={isOpened} toggle={onToggle} className={'lesson'}>
          <Row className='modal-header-row'>
            <Col>
              <p className='text-normal'>{this.props.t('lessonSeries')}</p>
            </Col>
            <Col className='flex-grow-0'>
              <Media src={closeIcon} className="close-icon" onClick={onToggle} />
            </Col>
          </Row>
          <ModalBody>
            {this.renderContent()}
          </ModalBody>
          <ModalFooter>
            <DefaultBtn className='modal-btn' label={this.props.t('lessonModalFooter')} onClick={onToggle} />
          </ModalFooter>
        </Modal>
      );        
    }
}

export default withNamespaces()(LessonModal);
