import { useQuery } from '@tanstack/react-query';
import UserService from '../../service/user';
import { useSelector } from 'react-redux';

export const useUsersList = (pageIndex, limit, search) => {
  const token = useSelector((state) => state.token);
  const userService = new UserService(token);

  return useQuery(['users-list', pageIndex, search], () => {
    return userService.getUsersList(pageIndex * limit, limit, search);
  }, {
    initialData: {
      total: 0,
      users: []
    }, 
  });
};