import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import './ProgressStateIcon.scss';

export const ProgressStateIcon = (props) => {
  const {
    icon, percentage, size, strokeWidth = 6 
  } = props;

  return (
    <div className='progress-state-icon' style={{
      width: size,
      height: size 
    }}>
      <CircularProgressbarWithChildren
        value={percentage}
        strokeWidth={strokeWidth}
        styles={buildStyles({
          pathColor: '#a0c814',
          trailColor: 'rgba(156, 223, 22, 0.32)',
        })}
      >
        {icon}
      </CircularProgressbarWithChildren>
    </div>
  );
};

ProgressStateIcon.propTypes = {
  icon: PropTypes.element,
  percentage: PropTypes.number,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};