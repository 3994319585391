import {
  LOGIN,
  LOGOUT,
  LOGIN_ANONYMOUS,
  MODAL_SHOWN,
  SET_EDITOR,
  SAVE_USER_PROFILE,
  GET_UNIT_RECOMMENDATION,
} from '../types';
export function login(id) {
  return {
    type: LOGIN,
    payload: { _id: id },
  };
}
export function loginAnonymous() {
  return {
    type: LOGIN_ANONYMOUS,
    payload: true,
  };
}
export function logout() {
  return {
    type: LOGOUT,
    payload: {},
  };
}

export function setModalShown(id) {
  return {
    type: MODAL_SHOWN,
    payload: id,
  };
}

export function saveUserProfile(userProfile) {
  return {
    type: SAVE_USER_PROFILE,
    payload: userProfile,
  };
}
export function setEditor(isEditor) {
  return {
    type: SET_EDITOR,
    payload: isEditor,
  };
}

export function getUnitRecommendation() {
  return {
    type: GET_UNIT_RECOMMENDATION,
    payload: {},
  };
}
