import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import translate, { getSupportedLanguage } from '../../utils/translate';
import { CardListItem } from '../fragments/card_list_item/CardListItem';
import { API_URL } from '../../shared/constants/api';
import { LESSON_ICONS, UNIT_ICONS } from '../../shared/constants/icon';
import { STATE } from '../../shared/constants/state';
import './LessonList.scss';

function LessonList(props) {
  let currentState = null;
  const lng = getSupportedLanguage(props.lng);
  const isEditor = useSelector((state) => state.isEditor);

  return (
    <Row className="lesson-list">
      <Col>
        {props.lessons.map((lesson) => {
          let state;
          if (props.lessonsCompleted.indexOf(lesson._id) > -1) {
            switch (currentState) {
              case null:
              case STATE.DONE:
                currentState = STATE.DONE;
                state = STATE.DONE;
                break;
              default:
                break;
            }
          } else {
            switch (currentState) {
              case STATE.DONE:
              case null:
                currentState = STATE.CURRENT;
                state = STATE.CURRENT;
                break;
              case STATE.CURRENT:
                currentState = STATE.LOCKED;
                state = STATE.LOCKED;
                break;
              default:
                state = STATE.LOCKED;
                break;
            }
          }

          const icon = lesson.icon
            ? API_URL + lesson.icon
            : LESSON_ICONS[(props.unit.number - 1) % UNIT_ICONS.length][
                lesson.number - 1
              ];

          return (
            <CardListItem
              key={lesson._id}
              onCardClick={() =>
                state !== STATE.LOCKED || isEditor
                  ? props.onSelectLesson(lesson)
                  : null
              }
              title={`${props.t('lessonListItemHeader')} ${lesson.number}`}
              subtitle={translate(lesson, 'title', lng)}
              badgeText={`${lesson.tasks.length} ${props.t(
                'lessonListItemExercises'
              )}`}
              state={state}
              icon={icon}
              lang={lng}
            />
          );
        })}
      </Col>
    </Row>
  );
}

LessonList.propTypes = {
  lessons: PropTypes.array.isRequired,
  lessonsCompleted: PropTypes.array.isRequired,
  unit: PropTypes.object.isRequired,
  onSelectLesson: PropTypes.func,
  succededTasksPerLesson: PropTypes.object,
};

export default withNamespaces()(LessonList);
