import * as types from '../types';

const moduleReducers = { 
  lessons: setLessons,
  currentLesson: setCurrentLesson,
  // currentLessonResult: setCurrentLessonResult
};

function setLessons(state = [], action) {
  switch (action.type) {
  case types.SET_LESSONS:
    return action.payload;
  default:
    return state;
  }
}

function setCurrentLesson(state = null, action) {
  switch (action.type) {
  case types.SET_CURRENT_LESSON:
    return action.payload;
  default:
    return state;
  }
}
export default moduleReducers;