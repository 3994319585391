
import {
  START_LESSON, TASK_CHANGE, STORE_RESULT, SET_ANONYMOUS_LESSON_COMPLED 
} from '../types';
export function startLesson(lessonId) {
  return  {
    type:START_LESSON,
    payload:lessonId 
  };
}

export function taskChange(payload){
  return {
    type: TASK_CHANGE,
    payload: payload
  };
}

export function lessonCompleted(payload){
  return {
    type: STORE_RESULT,
    payload: payload
  };
}

export function anonymousLessonCompleted(lessonId, unitId = null){
  return {
    type: SET_ANONYMOUS_LESSON_COMPLED,
    payload: {
      lessonId,
      unitId 
    }
  };
}