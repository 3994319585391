import React from 'react';
import PropTypes from 'prop-types';
import starIcon from '../../../shared/images/new/star.svg';
import starFilledIcon from '../../../shared/images/new/star-filled.svg';
import { Row, Media } from 'reactstrap';
import { RESULT } from '../../../shared/constants/result';
import './StarsStats.scss';

export const StarsStats = (props) => {
  const { ratio } = props;

  return (
    <Row className="stars-stats">
      {RESULT.RATIO_CAPS.map((ratioCap, index) =>
        ratio >= ratioCap ? (
          <Media key={index} src={starFilledIcon} className="star-icon" />
        ) : (
          <Media key={index} src={starIcon} className="star-icon" />
        )
      )}
    </Row>
  );
};

StarsStats.propTypes = {
  ratio: PropTypes.number,
};
