export const LESSON = {
  AUDIO: {
    WRONG: 0,
    ALMOST: 1,
    RIGHT: 2,
    ALMOST_THRESHOLD: 0.5,
    RIGHT_THRESHOLD: 0.8,
  },
};

export const COLORED_WORD_SEPARATOR = '*';
