import React from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';
import questionIcon from '../../../shared/images/new/question.svg';
import './TipsBtn.scss';

export const TipsBtn = (props) => {
  const { onClick } = props;

  return (
    <div className='tips-container' onClick={onClick}>
      <Media src={questionIcon} className="icon" />
    </div>
  );
};

TipsBtn.propTypes = { onClick: PropTypes.func, };