import React, { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import { AudioContext } from '../../context/AudioContext';
import { RecordingAnimation } from './RecordingAnimation';
import { LoaderAnimation } from './LoaderAnimation';
import { StartRecordButton } from './StartRecordButton';
import { PlayRecordedAudioButton } from './PlayRecordedAudioButton';
import './AudioRecorder.scss';

export const AudioRecorder = withNamespaces()(({ t }) => {
  const { recording, analyzing, audioUrl, delayBeforeRecording } = useContext(
    AudioContext
  );

  return (
    <div className="audio-recorder">
      <Row className="btns-row">
        {delayBeforeRecording || recording ? (
          <Col>
            <RecordingAnimation />
          </Col>
        ) : (
          <>
            {analyzing ? (
              <Col>
                <LoaderAnimation />
              </Col>
            ) : (
              <>
                {audioUrl ? (
                  <>
                    <Col>
                      <PlayRecordedAudioButton />
                    </Col>
                    <Col>
                      <StartRecordButton />
                    </Col>
                    <Col></Col>
                  </>
                ) : (
                  <Col>
                    <StartRecordButton />
                  </Col>
                )}
              </>
            )}
          </>
        )}
      </Row>
    </div>
  );
});
