import React, { useEffect }  from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  FETCH_LESSONS,FETCH_UNITS,INIT_USER, IS_EDITOR 
} from '../redux/types';


export default function Data() {
  const dispatch = useDispatch();
  const location = useLocation();

  if(location.search){
    let isEditor = new URLSearchParams(location.search).get('editor');
    dispatch({
      type:IS_EDITOR,
      payload:isEditor==1?true:false 
    });
  } 
  useEffect(() => {
    dispatch({ type: FETCH_UNITS });
    dispatch({ type: FETCH_LESSONS });
    dispatch({ type: INIT_USER });
  }, [dispatch]);
  return (
    <React.Fragment/>        
  );
}
