import {
  NEW_ALERT_ERROR,NEW_ALERT_WARNING,NEW_ALERT_SUCCESS,NEW_ALERT_INFO, 
} from '../types';

export function errorAlert(options) {
  return  {
    type:NEW_ALERT_ERROR,
    payload:options
  };
}
export function successAlert(options) {
  return  {
    type:NEW_ALERT_SUCCESS,
    payload:options
  };
}
export function warningAlert(options) {  
  return  {
    type:NEW_ALERT_WARNING,
    payload:options
  };
}
export function infoAlert(options) {
  return  {
    type:NEW_ALERT_INFO,
    payload:options
  };
}
          