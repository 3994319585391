import React, { useEffect, useState, useContext } from 'react';
import { TaskFeedback } from '../task_feedback/TaskFeedback';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { ColoredWord } from '../colored_word/ColoredWord';
import { LESSON } from '../../../shared/constants';
import { AudioContext } from '../../../context/AudioContext';
import './TaskCaption.scss';

export const TASK_CAPTION_STATE = {
  ERROR: 'error',
  HINT: 'hint',
  SUCCESS: 'success',
  INFO: 'info',
};

export const TaskCaption = withNamespaces()((props) => {
  const {
    t,
    taskArticle,
    taskWord,
    taskAnnotatedWord,
    success,
    attempts,
    processing,
    correctOption,
    feedbackMessage,
    errorMessage = '',
    successLevel = 0,
  } = props;

  const { recording, analyzing } = useContext(AudioContext);

  const [feedbackState, setFeedbackState] = useState(TASK_CAPTION_STATE.INFO);
  const [feedbackLabel, setFeedbackLabel] = useState('');

  useEffect(() => {
    let newFeedbackState = TASK_CAPTION_STATE.INFO;
    let newFeedbackLabel = '';

    const wasLastAttempt = attempts >= 3;
    const wasSecondAttempt = attempts === 2;
    const attemptFailed = attempts > 0 && successLevel < LESSON.AUDIO.ALMOST;
    const attemptAlmostSucceded = successLevel === LESSON.AUDIO.ALMOST;

    if (recording) {
      newFeedbackLabel = t('audioGo');
    } else if (analyzing) {
      newFeedbackLabel = t('audioWait');
    } else if (processing) {
      newFeedbackLabel = '';
    } else if (errorMessage) {
      newFeedbackState = TASK_CAPTION_STATE.ERROR;
      newFeedbackLabel = errorMessage;
    } else if (success) {
      newFeedbackState = TASK_CAPTION_STATE.SUCCESS;
    } else if (wasLastAttempt && attemptFailed) {
      newFeedbackLabel = t('lessonExerciseTaskTitleTryLater');
    } else if (wasLastAttempt && attemptAlmostSucceded) {
      newFeedbackLabel = `${t('lessonExerciseTaskTitleThatWas')} ${t(
        'lessonExerciseTaskTitleTryLater'
      )}`;
    } else if (feedbackMessage && wasSecondAttempt) {
      newFeedbackState = TASK_CAPTION_STATE.HINT;
      newFeedbackLabel = feedbackMessage;
    } else if (correctOption && wasSecondAttempt) {
      newFeedbackState = TASK_CAPTION_STATE.HINT;
      newFeedbackLabel = `${t('lessonExerciseTaskHintTip')}: ${correctOption}`;
    } else if (attemptFailed) {
      newFeedbackLabel = t('lessonExerciseTaskTitleTryAgain');
    } else if (attemptAlmostSucceded) {
      newFeedbackLabel = `${t('lessonExerciseTaskTitleThatWas')} ${t(
        'lessonExerciseTaskTitleTryAgain'
      )}`;
    } else if (attempts > 0) {
      newFeedbackLabel = t('lessonExerciseTaskTitleTryAgain');
    }

    setFeedbackState(newFeedbackState);
    setFeedbackLabel(newFeedbackLabel);
  }, [
    errorMessage,
    correctOption,
    success,
    attempts,
    processing,
    successLevel,
    recording,
    analyzing,
  ]);

  return (
    <div className="task-caption">
      <div>
        <span className="article">{(taskArticle || '') + ' '}</span>
        <ColoredWord
          word={taskWord}
          annotatedWord={taskAnnotatedWord}
          success={success}
          attempts={attempts}
          forceDefaultStyle={processing}
        />
      </div>
      <TaskFeedback state={feedbackState} label={feedbackLabel} />
    </div>
  );
});

TaskCaption.propTypes = {
  t: PropTypes.func,
  taskArticle: PropTypes.string,
  taskWord: PropTypes.string,
  taskAnnotatedWord: PropTypes.string,
  success: PropTypes.bool,
  attempts: PropTypes.number,
  processing: PropTypes.bool,
  correctOption: PropTypes.string,
  errorMessage: PropTypes.string,
  successLevel: PropTypes.number,
};
