import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import PropTypes from "prop-types";
import { Col, Row, Media } from "reactstrap";
import Streak from "../components/stats/Streak";
import "./Progress.scss";
import { WebtrekkSmartPixelReact } from "@webtrekk-smart-pixel/react";
import { ProgressCard } from "../components/fragments/progress_card/ProgressCard";
import trophyIcon from "../shared/images/new/trophy.svg";
import trophyStarIcon from "../shared/images/new/trophy-star.svg";
import noteIcon from "../shared/images/new/note.svg";
import checkmarkIcon from "../shared/images/new/checkmark.svg";
import medalIcon from "../shared/images/new/medal.svg";
import { BGImage } from "../components/fragments/bg_image/BGImage";
import bgImage from "../shared/images/bg/progress.svg";

class Progress extends Component {
  static propTypes = {
    units: PropTypes.array,
    unitsCompleted: PropTypes.array,
    lessons: PropTypes.array,
    lessonsCompleted: PropTypes.array,
    streak: PropTypes.object,
    longestStreak: PropTypes.number,
    totalTasks: PropTypes.number,
    suceededTasks: PropTypes.number,
    history: PropTypes.object,
  };

  onUnitsClick() {
    this.props.history.push("/progress/units");
  }

  componentDidMount() {
    WebtrekkSmartPixelReact.page("aussprachetraining_goethe_de.progress", {});
    WebtrekkSmartPixelReact.track();
  }

  render() {
    let {
      units,
      unitsCompleted,
      lessons,
      lessonsCompleted,
      streak,
      longestStreak,
      totalTasks,
      suceededTasks,
    } = { ...this.props };

    let progressCorrectPercentage = (suceededTasks / totalTasks) * 100;
    if (isNaN(progressCorrectPercentage)) {
      progressCorrectPercentage = 0;
    }

    return (
      <Row className="progress-page">
        <Col
          lg={{
            offset: 2,
            size: 4,
          }}
        >
          <h1 className="page-title">{this.props.t("Progress")}</h1>
          <Streak streak={streak} />

          <Row className="cards-container">
            <Col>
              <ProgressCard
                title={this.props.t("progressUnits")}
                badgeText={`${unitsCompleted.length}/${units.length}`}
                icon={<Media src={trophyIcon} className="icon trophy-icon" />}
                onClick={() => this.props.history.push("/progress/units")}
                percentage={(unitsCompleted.length / units.length) * 100}
              />

              <ProgressCard
                title={this.props.t("progressLessons")}
                badgeText={`${lessonsCompleted.length}/${lessons.length}`}
                icon={<Media src={noteIcon} className="icon note-icon" />}
                onClick={() => this.props.history.push("/progress/lessons")}
                percentage={(lessonsCompleted.length / lessons.length) * 100}
              />

              <ProgressCard
                title={this.props.t("progressWeek")}
                icon={
                  <Media
                    src={trophyStarIcon}
                    className="icon trophy-star-icon"
                  />
                }
                onClick={() => this.props.history.push("/progress/week")}
              />

              <Row>
                <Col className="left-small-card-container">
                  <ProgressCard
                    title={this.props.t("progressCorrect")}
                    badgeText={`${suceededTasks}/${totalTasks}`}
                    icon={
                      <Media src={checkmarkIcon} className="checkmark-icon" />
                    }
                    percentage={progressCorrectPercentage}
                    isSmallCard={true}
                  />
                </Col>
                <Col className="right-small-card-container">
                  <ProgressCard
                    title={this.props.t("progressStreak")}
                    badgeText={`${
                      longestStreak === 1 
                      ? this.props.t("progressStreakOneDay") 
                      : this.props.t("progressStreakDays", { count:  longestStreak })
                    }`}
                    icon={<Media src={medalIcon} className="medal-icon" />}
                    isSmallCard={true}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <BGImage
          mobileStyle={{ top: this.props.anonymousUser ? 240 : 195 }}
          imageUrl={bgImage}
          className="screen-height"
          desktopStyle={{ paddingBottom: 40 }}
        />
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  streak: state.streak,
  longestStreak: state.longestStreak,
  lessons: state.lessons,
  lessonsCompleted: state.lessonsCompleted,
  units: state.units,
  unitsCompleted: state.unitsCompleted,
  totalTasks: state.totalTasks,
  suceededTasks: state.suceededTasks,
  anonymousUser: state.anonymousUser,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Progress));
