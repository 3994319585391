import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Media, Row } from 'reactstrap';
import clockIcon from '../../../shared/images/new/clock.svg';
import scheduleIcon from '../../../shared/images/new/schedule.svg';
import { StarsStats } from '../stars_stats/StarsStats';
import './ProgressEntityStats.scss';

export const ProgressEntityStats = (props) => {
  const { title, ratio = 0, date, time } = props;

  return (
    <Col className="progress-entity-stats">
      <Row>
        <p className="entity-title">{title}</p>
      </Row>
      <StarsStats ratio={ratio} />
      {(date || time) && (
        <Row className="date-time-container">
          {!!date && (
            <>
              <Media object src={scheduleIcon} className="date-icon" />
              <span className="text">{dayjs(date).format('DD.MM.YYYY')}</span>
            </>
          )}
          {!!time && (
            <>
              <Media object src={clockIcon} className="clock-icon" />
              <span className="text">{time} min</span>
            </>
          )}
        </Row>
      )}
    </Col>
  );
};

ProgressEntityStats.propTypes = {
  title: PropTypes.string,
  ratio: PropTypes.number,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  time: PropTypes.number,
};
