import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import './CircleProgressBar.scss';

const PATH_COLOR = '#a0c814';
const TRAIL_COLOR = '#dff5b4';

export const CircleProgressBar = (props) => {
  const { children, value } = props;

  return (
    <div className="circle-progress-bar-container">
      <CircularProgressbarWithChildren
        value={value}
        strokeWidth={6}
        styles={buildStyles({
          pathColor: PATH_COLOR,
          trailColor: TRAIL_COLOR,
        })}
      >
        {children}
      </CircularProgressbarWithChildren>
    </div>
  );
};

CircleProgressBar.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
};
