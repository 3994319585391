import ApiService from './api';

const baseUrl = `${window.location.protocol}//${window.location.host}`;
const authBase = `${baseUrl}/auth`;

export default class AuthService extends ApiService{

  constructor(token=null) {
    super(token);
  }
  async getToken(){
    let response =  await fetch(authBase + '/token',{ redirect: 'manual' });
    if(response.status === 200){
      const body = await response.json();
      return body;
    }else if(response.status === 0){
      throw Error('User needs to log in');
    }else{
      throw Error('Unknown response fetching token:' + response.status);
    }
 
  }  


  async logout(){
    window.location = authBase + '/logout';
  }

}

