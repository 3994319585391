import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import playIcon from '../../../../shared/images/new/play.svg';
import lockIcon from '../../../../shared/images/new/lock.svg';
import checkmarkThinIcon from '../../../../shared/images/new/checkmark-thin.svg';
import { Media } from 'reactstrap';
import { STATE } from '../../../../shared/constants';
import 'react-circular-progressbar/dist/styles.css';
import './StateIcon.scss';

export const StateIcon = ({ percentage, state }) => {

  const renderContent = () => {
    return (
      <div className="state-icon-container">
        <div className={`state-icon-inner-container ${state}`}>
          {[STATE.CURRENT, STATE.UNLOCKED].includes(state) && (
            <Media
              src={playIcon}
              className="card-btn-icon"
            />
          )}
          {state === STATE.LOCKED && (
            <Media
              src={lockIcon}
              className="card-btn-icon"
            />
          )}
          {state === STATE.DONE && (
            <Media
              src={checkmarkThinIcon}
              className="card-btn-icon"
            />
          )}
        </div>
      </div>
    );
  };

  if (percentage === undefined) {
    return renderContent();
  }

  return (
    <div className="state-icon-container">
      <CircularProgressbarWithChildren
        value={percentage}
        strokeWidth={6}
        styles={buildStyles({
          pathColor: '#a0c814',
          trailColor: state === STATE.LOCKED ? 'rgba(14, 32, 41, 0.12)' : 'rgba(156, 223, 22, 0.32)',
        })}
      >
        {renderContent()}
      </CircularProgressbarWithChildren>
    </div>
  );
};
