import React, { useState } from 'react';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Media,
} from 'reactstrap';
import warningIcon from '../../../shared/images/warning.svg';
import './Tabs.scss';

export const Tabs = ({
  form,
  locales,
  tabPaneContentComponent: Content,
  fieldNamePrefix,
  handleSubmit,
  item,
  itemIndex,
  isTabContentInvalidFunc,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const renderTabTitle = (locale) => {
    const showWarning = isTabContentInvalidFunc(
      locale,
      form.getState().values,
      itemIndex
    );
    return (
      <Row style={{ alignItems: 'center' }}>
        {showWarning && <Media src={warningIcon} className="tabs-icon" />}
        {locale}
      </Row>
    );
  };

  return (
    <>
      <Nav tabs>
        {locales.map((locale, index) => (
          <NavItem key={locale}>
            <NavLink
              className={`${activeTabIndex === index ? 'active' : ''}`}
              onClick={() => setActiveTabIndex(index)}
              style={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {renderTabTitle(locale)}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTabIndex}>
        {locales.map((locale, index) => (
          <TabPane key={index} tabId={index}>
            <Content
              locale={locale}
              fieldNamePrefix={fieldNamePrefix}
              item={item}
              handleSubmit={handleSubmit}
            />
          </TabPane>
        ))}
      </TabContent>
    </>
  );
};
