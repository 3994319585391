import React, { useState, useEffect } from 'react';
import {
  Form,
  FormGroup,
  Button,
  Media,
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from 'reactstrap';
import { Form as FinalForm } from 'react-final-form';
import { Tabs } from '../components/admin/tabs/Tabs';
import { useHistory } from 'react-router-dom';
import arrowLeft from '../shared/images/arrow-left.svg';
import { useUpdateLesson } from '../hooks/lesson/useUpdateLesson';
import { useLessonById } from '../hooks/lesson/useLessonById';
import { TabPaneLesson } from '../components/admin/tabs/TabPaneLesson';
import { TasksListModal } from '../components/admin/modal/TasksListModal';
import arrayMutators from 'final-form-arrays';
import { useDispatch, useSelector } from 'react-redux';
import { useUnitById } from '../hooks/unit/useUnitById';
import { setSpinnerVisible } from '../redux/actions/spinner';
import { substituteFileId } from '../shared/helpers/upload';
import { useUploadFile } from '../hooks/upload/useUploadFile';
import { FileUploader } from '../components/admin/FileUploader';
import './LessonEdit.scss';
import { ImagePreviewModal } from '../components/admin/modal/ImagePreviewModal';

export const LessonEdit = (props) => {
  const history = useHistory();
  const unitId = props.match.params?.unitId;
  const lessonId = props.match.params?.lessonId;
  const user = useSelector((state) => state.authenticatedUser);
  const dispatch = useDispatch();

  const { data: unit } = useUnitById(unitId);
  const { data: lesson } = useLessonById(lessonId);
  const { mutate: updateLesson } = useUpdateLesson(lessonId);
  const { mutate: uploadFile } = useUploadFile();

  const [rounds, setRounds] = useState([]);
  const [tasksToEdit, setTasksToEdit] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    if (!user.isAdmin) {
      history.replace('/');
    }
  }, [history, user]);

  useEffect(() => {
    if (lesson?.tasks) {
      const results = [];
      if (lesson.tasks.length > 0) {
        lesson.tasks.forEach((task) => {
          if (!results.includes(task.round)) {
            results.push(task.round);
          }
        });
      }
      setRounds(results.sort((a, b) => a - b));
    }
  }, [lesson]);

  const onSubmit = (values) => {
    values.tasks.forEach((task, index) => {
      values.tasks[index].options = task.options.filter((n) => n);
    });
    updateLesson(values);
  };

  const validate = (values) => {
    const errors = {
      i18n: {},
      tasks: [],
    };
    const initLocaleErrors = (locale) => {
      if (!errors.i18n[locale]) {
        errors.i18n[locale] = {};
      }
    };
    const initTasksErrors = (index) => {
      if (!errors.tasks[index]) {
        errors.tasks[index] = { options: [] };
      }
    };
    const initTasksOptionsErrors = (index) => {
      initTasksErrors(index);
      if (!errors.tasks[index].options) {
        errors.tasks[index].options = [];
      }
    };

    if (values.i18n) {
      Object.keys(values.i18n).forEach((locale) => {
        if (values.i18n[locale].roundHeaders) {
          if (!values.i18n[locale].title) {
            initLocaleErrors(locale);
            errors.i18n[locale].title = 'Required';
          }

          values.i18n[locale].roundHeaders.forEach((roundHeader, index) => {
            if (!roundHeader) {
              initLocaleErrors(locale);
              if (!errors.i18n[locale].roundHeaders) {
                errors.i18n[locale].roundHeaders = [];
              }
              errors.i18n[locale].roundHeaders[index] = 'Required';
            }
          });
        }
      });
    }

    if (values.tasks?.length > 0) {
      values.tasks.forEach((task, taskIndex) => {
        if (!task.word) {
          initTasksErrors(taskIndex);
          errors.tasks[taskIndex].word = 'Required';
        }

        if (task.round === 1) {
          if (task.options?.length > 0) {
            task.options.forEach((option, optionIndex) => {
              if (!option) {
                initTasksOptionsErrors(taskIndex);
                errors.tasks[taskIndex].options[optionIndex] = 'Required';
              }
            });
          }
        }
      });
    }

    return errors;
  };

  const isLessonLocalesInvalid = (locale, values) => {
    let result = false;
    let { i18n } = values;
    if (!i18n || !i18n[locale]) {
      result = true;
    } else {
      if (!i18n[locale].roundHeaders) result = true;
      if (!i18n[locale].title?.length) result = true;
      for (const roundHeader of i18n[locale].roundHeaders) {
        if (!roundHeader) result = true;
      }
    }
    return result;
  };

  const onGoBackClick = () => {
    history.goBack();
  };

  const onRoundClick = (round) => {
    const roundTasks = {};
    if (lesson?.tasks?.length > 0) {
      lesson.tasks.forEach((task, index) => {
        if (task.round === round) {
          roundTasks[index] = task;
        }
      });
    }
    setTasksToEdit(roundTasks);
  };

  const onFileUploadSuccess = (result) => {
    const updatedLesson = { ...lesson };
    let newFilePath = null;
    if (!updatedLesson.icon) {
      newFilePath = `/files/image/${result._id}`;
    } else {
      newFilePath = substituteFileId(updatedLesson.icon, result._id);
    }
    updatedLesson.icon = newFilePath;
    updateLesson(updatedLesson);
    dispatch(setSpinnerVisible(false));
  };

  const onChangeFileClick = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      dispatch(setSpinnerVisible(true));
      onUploadFile(files[0], (result) => onFileUploadSuccess(result));
    }
  };

  const onUploadFile = (file, cb) => {
    if (unit) {
      const metadata = {
        unitId: unit._id,
        lessonId: lesson._id,
        scope: 'lesson_icon',
      };
      uploadFile({
        file: file,
        metadata,
        cb,
      });
    }
  };

  return (
    <Row className="lesson-edit">
      <Col
        lg={{
          offset: 2,
          size: 8,
        }}
      >
        <div style={{ display: 'flex' }}>
          <Button className="back-btn" onClick={onGoBackClick}>
            <Media src={arrowLeft} className="back-icon" />
          </Button>
          <h3 style={{ marginBottom: 0 }}>
            Unit {unit?.number} / Lesson {lesson?.number} Edit
          </h3>
        </div>
        <br />
        <FinalForm
          onSubmit={onSubmit}
          validate={validate}
          initialValues={lesson}
          mutators={{ ...arrayMutators }}
          render={({ form, handleSubmit, submitting, pristine, invalid }) => (
            <Form onSubmit={handleSubmit} className="">
              <Card>
                <CardBody>
                  <CardTitle tag="h3" style={{ marginBottom: 5 }}>
                    Data
                  </CardTitle>
                  <FormGroup className="file-uploader-container">
                    <label className="uploader-label">Icon</label>
                    {lesson?.icon ? (
                      <Button
                        style={{ marginRight: 15 }}
                        onClick={() => setImagePreview(lesson?.icon)}
                      >
                        Preview
                      </Button>
                    ) : (
                      <span style={{ marginRight: 15 }}>No image</span>
                    )}
                    <FileUploader
                      label="Change"
                      onChange={(e) => onChangeFileClick(e, 'icon')}
                      accept="image/*"
                    />
                  </FormGroup>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle tag="h3" style={{ marginBottom: 5 }}>
                    Localizations
                  </CardTitle>
                  <FormGroup>
                    <Tabs
                      form={form}
                      locales={Object.keys(lesson?.i18n || {})}
                      tabPaneContentComponent={TabPaneLesson}
                      isTabContentInvalidFunc={isLessonLocalesInvalid}
                      fieldNamePrefix="i18n."
                      item={lesson}
                      handleSubmit={handleSubmit}
                    />
                  </FormGroup>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle tag="h3" style={{ marginBottom: 5 }}>
                    Tasks
                  </CardTitle>
                  <FormGroup>
                    <ListGroup>
                      {rounds.map((round) => (
                        <ListGroupItem
                          key={round}
                          tag="a"
                          style={{ cursor: 'pointer' }}
                          onClick={() => onRoundClick(round)}
                        >
                          Round {round}
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </FormGroup>
                </CardBody>
              </Card>

              <TasksListModal
                isOpen={!!tasksToEdit}
                onClosed={() => setTasksToEdit(null)}
                unitId={unitId}
                lesson={lesson}
                tasks={tasksToEdit}
                handleSubmit={handleSubmit}
                form={form}
              />
            </Form>
          )}
        />
        <ImagePreviewModal
          isOpen={!!imagePreview}
          image={imagePreview}
          onClosed={() => setImagePreview(null)}
        />
      </Col>
    </Row>
  );
};
