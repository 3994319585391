import React, { useContext, useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import {
  AudioContext,
  DELAY_BEFORE_RECORDING,
} from '../../context/AudioContext';
import { CircleProgressBar } from './CircleProgressBar';
import './RecordingAnimation.scss';

const MAX_VOICE_LOUD_LEVEL = 10;

export const RecordingAnimation = withNamespaces()((props) => {
  const { t } = props;

  const { voiceLoudLevel, delayBeforeRecording, recording } = useContext(
    AudioContext
  );

  const perc =
    Math.min(voiceLoudLevel, MAX_VOICE_LOUD_LEVEL) / MAX_VOICE_LOUD_LEVEL;
  const scale = 1 + perc;

  const [counter, setCounter] = useState(DELAY_BEFORE_RECORDING);

  useEffect(() => {
    let timeout = null;
    if (delayBeforeRecording && counter > 0) {
      timeout = setTimeout(() => {
        setCounter(counter - 1000);
      }, 1000);
    }
    return () => clearTimeout(timeout);
  }, [counter, delayBeforeRecording]);

  useEffect(() => {
    if (delayBeforeRecording) {
      setCounter(DELAY_BEFORE_RECORDING);
    }
  }, [delayBeforeRecording]);

  if (delayBeforeRecording) {
    return (
      <div className="delay-before-recording-container">
        <CircleProgressBar value={(counter / DELAY_BEFORE_RECORDING) * 100}>
          <div className="counter-container">
            <div className="counter-text">{`${counter / 1000}`}</div>
          </div>
        </CircleProgressBar>
      </div>
    );
  }

  if (recording) {
    return (
      <>
        <div className="recording-container">
          <div
            className="recording-animation"
            style={{ transform: `scale(${scale})` }}
          ></div>
        </div>
      </>
    );
  }

  return null;
});

RecordingAnimation.propTypes = {};
