import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const UnitRecommendationContext = createContext({
  unit: null,
  unitLessons: [],
  unitLessonsCompleted: [],
});

export const UnitRecommendationContextProvider = (props) => {
  const { children } = props;

  const [recommendedUnit, setRecommendedUnit] = useState(null);
  const [recommendedUnitLessons, setRecommendedUnitLessons] = useState([]);
  const [
    recommendedUnitLessonsCompleted,
    setRecommendedUnitLessonsCompleted,
  ] = useState([]);

  const units = useSelector((state) => state.units);
  const lessons = useSelector((state) => state.lessons);
  const lessonsCompleted = useSelector((state) => state.lessonsCompleted);
  const unitRecommendation = useSelector((state) => state.unitRecommendation);

  useEffect(() => {
    if (unitRecommendation) {
      const unitToRecommend = units.find(
        (unit) => unit._id === unitRecommendation
      );

      if (unitToRecommend) {
        setRecommendedUnit(unitToRecommend);
        const unitLessons = lessons.filter(
          (lesson) => lesson.unitId === unitToRecommend._id
        );
        setRecommendedUnitLessons(unitLessons);
        const unitLessonsCompleted = unitLessons.filter((lesson) =>
          lessonsCompleted.includes(lesson._id)
        );
        setRecommendedUnitLessonsCompleted(unitLessonsCompleted);
      }
    }
  }, [units, lessons, lessonsCompleted, unitRecommendation]);

  return (
    <UnitRecommendationContext.Provider
      value={{
        unit: recommendedUnit,
        unitLessons: recommendedUnitLessons,
        unitLessonsCompleted: recommendedUnitLessonsCompleted,
      }}
    >
      {children}
    </UnitRecommendationContext.Provider>
  );
};

UnitRecommendationContextProvider.propTypes = {
  children: PropTypes.node,
};
