import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Col, Row, Media, Button, Collapse } from "reactstrap";
import trophyBgIcon from "../shared/images/new/trophy-bg.svg";
import chevronDownYellowIcon from "../shared/images/new/chevron-down-yellow.svg";
import translate, { getSupportedLanguage } from "../utils/translate";
import { WebtrekkSmartPixelReact } from "@webtrekk-smart-pixel/react";
import Streak from "../components/stats/Streak";
import { ProgressPanel } from "../components/fragments/progress_panel/ProgressPanel";
import { ProgressEntity } from "../components/fragments/progress_entity/ProgressEntity";
import { DefaultBtn } from "../components/fragments/default_btn/DefaultBtn";
import { BGImage } from "../components/fragments/bg_image/BGImage";
import bgImage from "../shared/images/bg/progress.svg";
import "./ProgressLessons.scss";

class ProgressLessons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsableOpened: {},
      nextLesson: null,
      nextUnit: null,
    };
  }

  componentDidMount() {
    WebtrekkSmartPixelReact.page("aussprachetraining_goethe_de.lessons", {});
    WebtrekkSmartPixelReact.track();
    this.setNextLesson();
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.lessons !== this.props.lessons ||
      prevProps.userResults !== this.props.userResults
    ) {
      this.setNextLesson();
    }
  }

  onGoBack() {
    this.props.history.push("/progress/");
  }
  toggle(unitId) {
    let collapsableOpened = Object.assign({}, this.state.collapsableOpened);
    collapsableOpened[unitId] = !collapsableOpened[unitId];
    this.setState({ collapsableOpened });
  }
  isOpen(unitId) {
    return !!this.state.collapsableOpened[unitId];
  }

  setNextLesson() {
    let nextLesson = null;
    let nextUnit = null;
    this.props.lessons.forEach((lesson, index) => {
      let lessonResult = this.props.userResults.find(
        (result) => result.lessonId === lesson._id
      );
      if (lessonResult) {
        if (index + 1 === this.props.lessons.length) {
          nextLesson = null;
        } else {
          nextLesson = this.props.lessons[index + 1];
        }
      }
    });
    if (nextLesson) {
      nextUnit = this.props.units.find(
        (unit) => unit._id === nextLesson.unitId
      );
    }
    this.setState({
      nextLesson,
      nextUnit,
    });
  }

  renderLesson(lesson, index, nextLesson) {
    const lng = getSupportedLanguage(this.props.lng);
    // get the newest result
    let lessonResult = this.props.userResults
      .filter((result) => result.lessonId == lesson._id)
      .sort((a, b) => (a.finishedAt > b.finishedAt ? -1 : 1))
      .reduce((prev, current) => (prev ? prev : current), null);
    //   .filter(result => result.lessonId=lesson._id));
    let nextLessonResult = nextLesson
      ? this.props.userResults
          .filter((result) => result.lessonId == nextLesson._id)
          .sort((a, b) => (a.finishedAt > b.finishedAt ? -1 : 1))
          .reduce((prev, current) => (prev ? prev : current), null)
      : null;
    let first = index === 0;
    let last = nextLesson === null;
    let badgeClass = last ? " last" : first ? " first" : " middle";
    let lastCompleted = lessonResult && !nextLessonResult;
    badgeClass += lastCompleted ? " last-completed" : "";

    let lessonRatio = lessonResult ? lessonResult.ratio : 0;

    return (
      <ProgressEntity
        key={index}
        isCompleted={lessonResult}
        isLast={!nextLesson}
        title={translate(lesson, "title", lng)}
        ratio={lessonRatio || 0}
        date={lessonResult?.finishedAt}
        time={lessonResult?.minutesSpend}
      />
    );
  }

  renderLessons(unit) {
    let lessons = this.props.lessons.filter(
      (lesson) => lesson.unitId === unit._id
    );
    return (
      <div className="lesson-container">
        {lessons.map((lesson, index) => {
          let nextLesson = lessons[index + 1] || null;
          return this.renderLesson(lesson, index, nextLesson);
        })}
      </div>
    );
  }

  render() {
    let { units, unitsCompleted, unitsResults, streak, t } = { ...this.props };
    let nextUnitButton = null;
    if (!this.props.units.length && this.props.lessons.length) {
      return <React.Fragment />;
    }
    const lng = getSupportedLanguage(this.props.lng);
    return (
      <Row className="progress-lessons-page">
        <Col
          lg={{
            offset: 2,
            size: 4,
          }}
        >
          <h1 className="page-title">{this.props.t("Progress")}</h1>
          <Streak streak={streak} />

          <ProgressPanel
            title={this.props.t("progressLessons")}
            onBackClick={() => this.onGoBack()}
          >
            <Col>
              {units.map((unit, index) => {
                let unitCompleted = unitsCompleted.indexOf(unit._id) > -1;
                let unitResult = unitsResults[unit._id];
                let first = index === 0;
                let last = units.length - 1 === index;
                let nextUnit = units[index + 1] || null;
                let badgeClass = last ? "last" : first ? "first" : "middle";
                let lastCompleted =
                  unitsCompleted.indexOf(unit._id) > -1 &&
                  nextUnit &&
                  unitsCompleted.indexOf(nextUnit._id) == -1;
                if (lastCompleted) {
                  nextUnitButton = nextUnit;
                }
                badgeClass = lastCompleted
                  ? badgeClass + " last-completed"
                  : badgeClass;

                return (
                  <>
                    <Row key={index} className="unit-container" onClick={() => this.toggle(unit._id)}>
                      <Col className="unit-icon-container">
                        {unitCompleted ? (
                          <Media src={trophyBgIcon} className="trophy-icon" />
                        ) : (
                          <div className="circle"></div>
                        )}
                      </Col>
                      <Col>
                        <Row className="unit-title-container">
                          <p className="unit-title">
                            {translate(unit, "title", lng)}
                          </p>
                        </Row>
                      </Col>
                      <Col className="collapse-icon-container">
                        <Media
                          src={chevronDownYellowIcon}
                          className={[
                            "icon",
                            this.isOpen(unit._id) ? "arrow-up" : "arrow-down",
                          ].join(" ")}
                        />
                      </Col>
                    </Row>
                    <Row className="lessons-container">
                      <Collapse isOpen={this.isOpen(unit._id)}>
                        {this.renderLessons(unit)}
                      </Collapse>
                    </Row>
                  </>
                );
              })}
            </Col>
          </ProgressPanel>

          {this.state.nextLesson && (
            <DefaultBtn
              label={`${t("progressGoTo")} ${this.state.nextUnit.number} - ${t(
                "progressLessonNextButton"
              )} ${this.state.nextLesson.number}`}
              onClick={() => {
                this.props.history.push("/lesson/" + this.state.nextLesson._id);
              }}
            />
          )}
        </Col>
        <BGImage
          imageUrl={bgImage}
          className="screen-height"
          desktopStyle={{ paddingBottom: 40 }}
          mobileStyle={{ top: this.props.anonymousUser ? 240 : 195 }}
        />
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  units: state.units,
  lessons: state.lessons,
  lessonsCompleted: state.lessonsCompleted,
  userResults: state.userResults,
  unitsCompleted: state.unitsCompleted,
  unitsResults: state.unitsResults,
  streak: state.streak,
  anonymousUser: state.anonymousUser,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(ProgressLessons));
