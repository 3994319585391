import { PropTypes } from "prop-types";
import React from "react";
import {
  Col,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
import useScreenWidth from "../../../hooks/layout/useScreenWidth";
import { BREAKPOINT_MOBILE_LAYOUT } from "../../../shared/constants/theme";
import closeIcon from "../../../shared/images/new/close.svg";
import emblemIcon from "../../../shared/images/new/emblem.svg";
import { DefaultBtn } from "../default_btn/DefaultBtn";
import { withNamespaces } from 'react-i18next';
import "./SuccessStreakModal.scss";

export const SuccessStreakModal = withNamespaces()((props) => {
  const { opened, onClosed, t } = props;

  const screenWidth = useScreenWidth();

  return (
    <Modal
      isOpen={opened}
      onClosed={onClosed}
      toggle={onClosed}
      className="success-streak-modal"
    >
      <ModalHeader className="header-container">
        <Media src={closeIcon} className="close-icon" onClick={onClosed} />
      </ModalHeader>
      <ModalBody className="body-container">
        <p className="title">{t('streakTitle')}</p>
        <p className="subtitle">{t('streakSubtitlePre')}</p>
        <div className="emblem-container">
          <Media src={emblemIcon} />
          <div className="emblem-text-container">
            <p className="title">{t('streakDaysNumber', { count: 7 })}</p>
          </div>
        </div>
        {screenWidth > BREAKPOINT_MOBILE_LAYOUT ? (
          <Row className="footer-container">
            <Col xs="auto">
              <DefaultBtn label={t('streakBtn')} className="hidden" />
            </Col>
            <Col>
              <p className="subtitle">{t('streakSubtitlePost')}</p>
            </Col>
            <Col xs="auto">
              <DefaultBtn label={t('streakBtn')} onClick={onClosed} />
            </Col>
          </Row>
        ) : (
          <>
            <Row className="footer-container">
              <Col>
                <p className="subtitle">{t('streakSubtitlePost')}</p>
              </Col>
            </Row>
            <Row className="xs-btn-container">
              <DefaultBtn label={t('streakBtn')} onClick={onClosed} />
            </Row>
          </>
        )}
      </ModalBody>
    </Modal>
  );
});

SuccessStreakModal.propTypes = {
  opened: PropTypes.bool,
  onClosed: PropTypes.func,
};
