import React from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Modal, ModalBody, Row } from 'reactstrap';
import { saveUserProfile } from '../../redux/actions/user';
import { LOCALES } from '../../shared/constants/locales';
import BootstrapInput from '../form/BootstrapInput';
import BootstrapSelect from '../form/BootstrapSelect';
import { DefaultBtn } from '../fragments/default_btn/DefaultBtn';
import './UserProfile.scss';

const UserProfileForm = withNamespaces()((props) => {
  let validate = (values) => {
    const errors = {};
    if (!values.language) {
      errors.language = props.t('formFieldWarning');
    }
    if (!values.gender) {
      errors.gender = props.t('formFieldWarning');
    }
    if (
      values.age.length &&
      (parseInt(values.age) > 100 || parseInt(values.age) < 0)
    ) {
      errors.age = props.t('formFieldWarning');
    }

    return errors;
  };

  let onSubmit = (values) => {
    let now = new Date();
    let birthyear = now.getFullYear() - values.age;
    let newValues = Object.assign({}, values);
    delete newValues.age;
    newValues.birthyear = birthyear;
    props.onSubmit(newValues);
  };

  let now = new Date();
  let initialValues = Object.assign({}, props.userProfile);
  initialValues.age =
    initialValues.birthyear && initialValues.birthyear != now.getFullYear()
      ? now.getFullYear() - initialValues.birthyear
      : '';
  delete initialValues.birthyear;

  let languageOptions = (() => {
    let capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
    let result = { '': '' };
    LOCALES.SELECTION.sort((a, b) => {
      let navigatorLanguages = navigator.languages.map((language) =>
        language.substr(0, 2)
      );
      if (navigatorLanguages.indexOf(a) > -1) {
        if (navigatorLanguages.indexOf(b) > -1) {
          return navigatorLanguages.indexOf(a) > navigatorLanguages.indexOf(b)
            ? 1
            : -1;
        } else {
          return -1;
        }
      } else if (navigatorLanguages.indexOf(b) > -1) {
        return 1;
      } else {
        return props.t('userProfileLanguage' + capitalizeFirstLetter(a)) >
          props.t('userProfileLanguage' + capitalizeFirstLetter(b))
          ? 1
          : -1;
      }
    }).forEach(
      (locale) =>
        (result[locale] = props.t(
          'userProfileLanguage' + capitalizeFirstLetter(locale)
        ))
    );
    return result;
  })();
  return (
    <FinalForm
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Field
            name="language"
            type="select"
            label={props.t('userProfileLanguage')}
            component={BootstrapSelect}
            options={languageOptions}
          />
          <Field
            name="level"
            type="select"
            label={props.t('userProfileLevel')}
            component={BootstrapSelect}
            options={{
              '': '',
              A1: 'A1',
              A2: 'A2',
              B1: 'B1',
              B2: 'B2',
              C1: 'C1',
              C2: 'C2',
            }}
          />
          <Field
            name="gender"
            type="select"
            label={props.t('userProfileGender')}
            component={BootstrapSelect}
            options={{
              '': '',
              male: props.t('userProfileGenderMale'),
              female: props.t('userProfileGenderFemale'),
              divers: props.t('userProfileGenderDivers'),
            }}
          />
          <Field
            name="age"
            type="text"
            placeholder=""
            defaultValue=""
            label={props.t('userProfileBirthYear')}
            component={BootstrapInput}
          />
          <div
            className="disclainer"
            dangerouslySetInnerHTML={{
              __html: props.t('userProfileDisclaimer'),
            }}
          ></div>

          <Row className="btn-container">
            <DefaultBtn label={props.t('userProfileSave')} type="submit" />
          </Row>
        </Form>
      )}
    />
  );
});

function LinkText({ to, children }) {
  return <Link to={to || ''}>{children}test</Link>;
}

function UserProfile(props) {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile, []);
  const userProfileComplete = () => {
    if (!userProfile?.language?.length) {
      return false;
    }
    if (
      (userProfile?.birthyear && userProfile?.birthyear < 1920) ||
      !userProfile?.gender?.length
    ) {
      return false;
    }
    return true;
  };
  const onSubmit = (userProfile) => {
    dispatch(saveUserProfile(userProfile));
  };

  return !userProfileComplete() ? (
    <Modal isOpen={true} className="user-profile-modal">
      {/* <ModalHeader>Header</ModalHeader> */}
      <ModalBody className="user-profile">
        <h2 className="title">{props.t('userProfileTitle')}</h2>
        <p className="text">{props.t('userProfileText')}</p>
        <UserProfileForm onSubmit={onSubmit} userProfile={userProfile} />
      </ModalBody>
    </Modal>
  ) : (
    <React.Fragment />
  );
}

export default withNamespaces()(UserProfile);
