import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import {
  Modal, ModalBody, ModalHeader, ModalFooter, Button, Carousel, Row, Col, Container, Media,
  CarouselItem,
  CarouselIndicators,
} from 'reactstrap';
import ReactPlayer from 'react-player';
import './UnitHelp.scss';
import AudioButton from '../global/AudioButton';
import translate, { getSupportedLanguage } from '../../utils/translate';
import { LOCALES } from '../../shared/constants/locales';
import backImage from '../../shared/images/arrow-left.svg';
import arrowForwardIcon from '../../shared/images/new/arrow-forward.svg';
import { API_URL } from '../../shared/constants/api';
import closeIcon from '../../shared/images/close.svg';
import { HELP_CONTENT_CONTAINER_HEIGHT } from '../../shared/constants/theme';
import { DefaultBtn } from '../fragments/default_btn/DefaultBtn';

function UnitHelp(props) {
  let { unit, lng = LOCALES.DEFAULT } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [playingAudio, setPlayingAudio] = useState(unit.helpContent.map(() => false));
  const [playingVideo, setPlayingVideo] = useState(unit.helpContent.map(() => false));

  if (!unit) return <React.Fragment />;

  lng = getSupportedLanguage(lng);

  const slides = unit.helpContent.map((helpContent, index) => {

    const onExiting = () => {
      setAnimating(true);
      setPlayingAudio(unit.helpContent.map(() => false));
      setPlayingVideo(unit.helpContent.map(() => false));
    };
    const onExited = () => {
      setAnimating(false);
    };

    const onEntered = () => {
    };

    const handleAudioPlay = () => {
      let playingState = unit.helpContent.map(() => false);
      playingState[activeIndex] = true;
      setPlayingAudio(playingState);
    };
    const handleAudioEnded = () => {
      setPlayingAudio(unit.helpContent.map(() => false));
    };

    const handleVideoPlay = () => {
      let playingState = unit.helpContent.map(() => false);
      playingState[activeIndex] = true;
      setPlayingVideo(playingState);
    };

    return (
      <CarouselItem
        onExiting={onExiting.bind(this)}
        onExited={onExited.bind(this)}
        onEntered={onEntered.bind(this)}
        key={index}
      >
        <Row className='help-content-title-row'>
          <Col lg={{ size: 6 }} xs={{ size: 12 }}>
            <p className='help-content-title'>{translate(helpContent, 'title', lng)}</p>
          </Col>
          <Col lg={{ size: 6 }} xs={{ size: 12 }}>
            {helpContent.audio && <AudioButton
              playing={playingAudio[index]}
              url={API_URL + helpContent.audio}
              onPlay={handleAudioPlay}
              onEnded={handleAudioEnded}
              onPause={handleAudioEnded}
              repeat={true}
            />}
          </Col>
        </Row>
        <Row>
          <Col className='image-container' lg={{ size: 6 }} xs={{ size: 12 }}>
            {helpContent.image && <img alt={helpContent.text} style={{ height: HELP_CONTENT_CONTAINER_HEIGHT }} className="help-content-img" src={API_URL + helpContent.image}></img>}
          </Col>
          <Col className='video-container' lg={{ size: 6 }} xs={{ size: 12 }}>
            {helpContent.video && (
              <ReactPlayer
                playing={playingVideo[index]}
                url={API_URL + helpContent.video}
                config={{ file: { forceVideo: true, }}}
                controls={true}
                loop={true}
                onPlay={handleVideoPlay}
                width='100%'
                height={HELP_CONTENT_CONTAINER_HEIGHT}
              />
            )}
            <div className='help-content-text-container'>
              <p className='help-content-text'>{translate(helpContent, 'text', lng)}</p>
            </div>
          </Col>
        </Row>
      </CarouselItem>
    );
  });

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  return (
    <Modal isOpen={props.isOpened} toggle={props.onToggle} className='unit-help'>
      {/* <ModalHeader toggle={props.onToggle}>{translate(unit, 'title', lng)}</ModalHeader> */}
      <ModalBody>
        <Row>
          <Media src={closeIcon} className="icon close-icon" onClick={props.onToggle}/>
        </Row>
        <Row>
          <p className='unit-title'>{translate(unit, 'title', lng)}</p>
        </Row>
        <Row>
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            autoPlay={false}
            interval='1000000000'
          >
            {slides}
          </Carousel>
        </Row>
        <Row className='help-footer'>
          <Col className='btn-container'>
            <DefaultBtn label={props.t('unitHelpBackButton')} onClick={previous} />
          </Col>
          <Col className='carusel-indicators-container'>
            <CarouselIndicators items={slides} activeIndex={activeIndex} onClickHandler={goToIndex} />
          </Col>
          <Col className='btn-container'>
            <DefaultBtn label={props.t('unitHelpContinueButton')} onClick={next} />
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

UnitHelp.propTypes = {
  unit: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  onToggle: PropTypes.func,
};

export default withNamespaces()(UnitHelp);
