export const LOCALES = {
  AVAILABLE: ['de', 'en', 'vi'],
  DEFAULT: 'de',
  SELECTION: [
    'af',
    'ar',
    'az',
    'be',
    'bnt',
    'bn',
    'bo',
    'bs',
    'bg',
    'ca',
    'cs',
    'ce',
    'da',
    'de',
    'el',
    'en',
    'et',
    'eu',
    'fo',
    'fi',
    'fr',
    'he',
    'hi',
    'hit',
    'hr',
    'hu',
    'id',
    'ik',
    'it',
    'ja',
    'ks',
    'ky',
    'ko',
    'ku',
    'lv',
    'lt',
    'lb',
    'ms',
    'mt',
    'mn',
    'my',
    'ne',
    'nl',
    'no',
    'pl',
    'pt',
    'rm',
    'ru',
    'sk',
    'sl',
    'es',
    'sq',
    'sr',
    'sw',
    'sv',
    'ta',
    'tl',
    'th',
    'tk',
    'tr',
    'uk',
    'ur',
    'uz',
    'vi',
    'wa',
    'zh',
    'zu']
};