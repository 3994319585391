import { WebtrekkSmartPixelReact } from '@webtrekk-smart-pixel/react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Col, Media, Row } from 'reactstrap';
import translate, { getSupportedLanguage } from '../utils/translate';
import LessonList from '../components/lesson/LessonList';
import UnitHelp from '../components/unit/UnitHelp';
import { startLesson } from '../redux/actions/lesson';
import { setCurrentUnit } from '../redux/actions/unit';
import { setModalShown } from '../redux/actions/user';
import { LOCALES } from '../shared/constants/locales';
import { BREAKPOINT_MOBILE_LAYOUT } from '../shared/constants/theme';
import { BGImage } from '../components/fragments/bg_image/BGImage';
import { TipsBadge } from '../components/fragments/tips_badge/TipsBadge';
import arrowBackIcon from '../shared/images/new/arrow-back.svg';
import { UNIT_BG_IMAGES } from '../shared/constants/icon';
import { API_URL } from '../shared/constants/api';
import './Unit.scss';

class Unit extends Component {
  static propTypes = {
    currentUnit: PropTypes.object,
    currentUnitLessons: PropTypes.array,
    history: PropTypes.object,
    lessons: PropTypes.array,
    lessonsCompleted: PropTypes.any,
    match: PropTypes.object,
    modalsShown: PropTypes.array,
    setCurrentUnit: PropTypes.func,
    setModalShown: PropTypes.func,
    t: PropTypes.func,
    userResults: PropTypes.array,
    unitRecommendation: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      helpIsOpened: false,
      screenWidth: 0,
      currentLessonResult: null,
    };

    this.updateScreenWidth = this.updateScreenWidth.bind(this);
  }

  componentDidMount() {
    let unitId = this.props.match.params?.id;
    this.props.setCurrentUnit(unitId);
    this.initModal(unitId);

    this.updateScreenWidth();
    window.addEventListener('resize', this.updateScreenWidth);
  }

  componentDidUpdate(prevProps) {
    let { currentUnit, userResults } = this.props;
    let isUnitInited =
      currentUnit &&
      (prevProps.currentUnit === null ||
        currentUnit._id !== prevProps.currentUnit._id);
    if (isUnitInited) {
      WebtrekkSmartPixelReact.page(
        `aussprachetraining_goethe_de.unit_${currentUnit?.number}`,
        {}
      );
      WebtrekkSmartPixelReact.track();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateScreenWidth);
  }

  updateScreenWidth() {
    this.setState({ screenWidth: window.innerWidth });
  }

  initModal(unitId) {
    let modalId = 'unit_' + unitId;
    if (this.props.modalsShown.indexOf(modalId) === -1) {
      this.setState({ helpIsOpened: true });
      this.props.setModalShown(modalId);
    }
  }

  toggleHelp() {
    this.setState({ helpIsOpened: !this.state.helpIsOpened });
  }

  onSelectLesson(lesson) {
    this.props.history.push('/lesson/' + lesson._id);
  }

  onGoBack() {
    this.props.history.push('/');
  }

  render() {
    if (!this.props.currentUnit || !this.props.currentUnitLessons.length > 0)
      return <div>...loading</div>;
    let { currentUnit, lng = LOCALES.DEFAULT } = { ...this.props };
    lng = getSupportedLanguage(lng);
    const { image } = this.props.currentUnit;
    const bgImageUrl = image
      ? `${API_URL}${image}`
      : UNIT_BG_IMAGES[(currentUnit.number - 1) % UNIT_BG_IMAGES.length];

    return (
      <Row className="unit-page">
        <Col
          lg={{
            offset: 2,
            size: 4,
          }}
        >
          {this.state.screenWidth > BREAKPOINT_MOBILE_LAYOUT ? (
            <>
              <Row className="header-container">
                <Col>
                  <p className="unit-number-label">{`${this.props.t(
                    'unitListItem'
                  )} ${currentUnit.number}`}</p>
                  <h1 className="page-title">
                    {translate(currentUnit, 'title', lng)}
                  </h1>
                  <p className="text-normal">
                    {translate(currentUnit, 'goal', lng)}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row className="header-row">
                    <Media
                      src={arrowBackIcon}
                      className="icon btn-back"
                      onClick={() => this.onGoBack()}
                    />
                    <TipsBadge onClick={() => this.toggleHelp()} />
                  </Row>

                  <LessonList
                    succededTasksPerLesson={this.props.succededTasksPerLesson}
                    unit={this.props.currentUnit}
                    lessons={this.props.currentUnitLessons}
                    lessonsCompleted={this.props.lessonsCompleted}
                    onSelectLesson={this.onSelectLesson.bind(this)}
                  />

                  <Row>
                    <div>{this.props.unitRecommendation}</div>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="xs-header-row">
                <Media
                  src={arrowBackIcon}
                  className="icon btn-back"
                  onClick={() => this.onGoBack()}
                />
                <p className="unit-number-label">{`${this.props.t(
                  'unitListItem'
                )} ${currentUnit.number}`}</p>
                <TipsBadge onClick={() => this.toggleHelp()} />
              </Row>
              <Row>
                <h1 className="page-title">
                  {translate(currentUnit, 'title', lng)}
                </h1>
              </Row>
              <Row className="xs-lessons-list-container">
                <Col>
                  <LessonList
                    succededTasksPerLesson={this.props.succededTasksPerLesson}
                    unit={this.props.currentUnit}
                    lessons={this.props.currentUnitLessons}
                    lessonsCompleted={this.props.lessonsCompleted}
                    onSelectLesson={this.onSelectLesson.bind(this)}
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
        <BGImage
          imageUrl={bgImageUrl}
          className="screen-height"
          desktopStyle={{ paddingBottom: 40 }}
          mobileStyle={{ top: 120 }}
        />

        <UnitHelp
          unit={this.props.currentUnit}
          isOpened={this.state.helpIsOpened}
          onToggle={this.toggleHelp.bind(this)}
        />
      </Row>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCurrentUnit,
      startLesson,
      setModalShown,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentUnitLessons: state.currentUnitLessons,
    currentUnit: state.currentUnit,
    lessonsCompleted: state.lessonsCompleted,
    modalsShown: state.modalsShown,
    isEditor: state.isEditor,
    succededTasksPerLesson: state.succededTasksPerLesson,
    currentLesson: state.currentLesson,
    userResults: state.userResults,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withNamespaces()(Unit));
