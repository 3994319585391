import unitSagas from './unit';
import lessonSagas from './lesson';
import alertsSagas from './alert';
import userSagas from './user';
import feedbackSagas from './feedback';
import resultSagas from './result';
import { fork } from 'redux-saga/effects';

export default function* root() {
  yield fork(unitSagas);
  yield fork(lessonSagas);
  yield fork(alertsSagas);
  yield fork(userSagas);
  yield fork(feedbackSagas);
  yield fork(resultSagas);
}