import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useUploadFile } from '../../../hooks/upload/useUploadFile';
import { TaskListItem } from '../TaskListItem';
import { useUpdateLesson } from '../../../hooks/lesson/useUpdateLesson';
import { useDispatch } from 'react-redux';
import { setSpinnerVisible } from '../../../redux/actions/spinner';
import { substituteFileId } from '../../../shared/helpers/upload';

export const TasksListModal = ({
  isOpen,
  onClosed,
  unitId,
  lesson,
  tasks,
  handleSubmit,
  form,
}) => {
  const { mutate: uploadFile } = useUploadFile();
  const { mutate: updateLesson } = useUpdateLesson();
  const dispatch = useDispatch();

  const onFileUploadSuccess = (result, index, propertyName) => {
    const updatedLesson = { ...lesson };
    let newFilePath = null;
    if (!updatedLesson.tasks[index][propertyName]) {
      newFilePath = `/files/${propertyName}/${result._id}`;
    } else {
      newFilePath = substituteFileId(
        updatedLesson.tasks[index][propertyName],
        result._id
      );
    }
    updatedLesson.tasks[index][propertyName] = newFilePath;
    updateLesson(updatedLesson);
    dispatch(setSpinnerVisible(false));
  };

  const onChangeFileClick = (e, index, propertyName) => {
    const files = e.target.files;
    if (files.length > 0) {
      dispatch(setSpinnerVisible(true));
      onUploadFile(files[0], (result) => {
        onFileUploadSuccess(result, index, propertyName);
      });
    }
  };

  const onUploadFile = (file, cb) => {
    if (lesson && unitId) {
      const metadata = {
        lessonId: lesson._id,
        unitId,
        scope: 'task',
      };
      uploadFile({
        file: file,
        metadata,
        cb,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClosed={onClosed} toggle={onClosed}>
      <ModalBody>
        <div style={{ flex: 1 }}>
          {Object.keys(tasks || {})?.map((taskIndex) => (
            <TaskListItem
              key={taskIndex}
              taskIndex={taskIndex}
              task={tasks[taskIndex]}
              onChangeFileClick={onChangeFileClick}
              handleSubmit={handleSubmit}
              form={form}
            />
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};
