import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REMOVE_ALERT } from '../redux/types';
import Alert from '../components/global/Alert';
import { PropTypes } from 'prop-types';


export default function Alerts(props) {
  const dispatch = useDispatch();
  const alerts = useSelector(state => state.alerts,[]);
  return (
    <React.Fragment>      
      {alerts.filter(alert => alert.context === props.context).map(alert => {
        return <Alert key={alert.uuid} alert={alert} onDismiss={() => dispatch({
          type: REMOVE_ALERT,
          payload: alert 
        })} />;
      })}
    </React.Fragment>
  );
}
Alerts.propTypes = { context: PropTypes.string.isRequired };
