import { useMutation, useQueryClient } from '@tanstack/react-query';
import LessonService from '../../service/lesson';
import { useSelector } from 'react-redux';

export const useUpdateLessons = () => {
  const queryClient = useQueryClient();
  const token = useSelector((state) => state.token);
  const lessonService = new LessonService(token);
  const query = ['lessons-list'];

  return useMutation(
    (lessons) => {
      return lessonService.updateLessons(lessons);
    },
    {
      onMutate: async (lessons) => {
        await queryClient.cancelQueries(query);
        const oldLessons = queryClient.getQueryData(query) || [];
        queryClient.setQueryData(query, lessons);
        return { oldLessons };
      },
      onError: (_error, _vars, context) => {
        if (context !== undefined) {
          queryClient.setQueryData(query, context.oldLessons);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(query);
      },
    },
  );
};