import { useQuery } from '@tanstack/react-query';
import LessonService from '../../service/lesson';
import { useSelector } from 'react-redux';

export const useLessonsByUnitId = (unitId) => {
  const token = useSelector((state) => state.token);
  const lessonService = new LessonService(token);

  return useQuery(['lessons-by-unit-id'], () => {
    return lessonService.getLessonsByUnitId(unitId);
  }, { initialData: [], });
};