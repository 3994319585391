import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Media,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { FormGroup } from 'reactstrap';
import { Field } from 'react-final-form';
import BootstrapInput from '../form/BootstrapInput';
import { FileUploader } from './FileUploader';
import { ImagePreviewModal } from './modal/ImagePreviewModal';
import AudioButton from '../global/AudioButton';
import { VideoPreviewModal } from './modal/VideoPreviewModal';
import { API_URL } from '../../shared/constants/api';
import { FieldArray } from 'react-final-form-arrays';
import trashIcon from '../../shared/images/trash.svg';
import { Tabs } from './tabs/Tabs';
import { TabPaneTask } from './tabs/TabPaneTask';
import './TaskListItem.scss';

export const TaskListItem = ({
  task,
  taskIndex,
  handleSubmit,
  onChangeFileClick,
  form,
}) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [playingAudio, setPlayingAudio] = useState(false);

  const handleAudioPlay = () => {
    setPlayingAudio(true);
  };

  const handleAudioEnded = () => {
    setPlayingAudio(false);
  };

  const onRemoveOption = (fields, index) => {
    fields.remove(index);
    handleSubmit();
  };

  const isTaskLocalesInvalid = (locale, values, itemIndex) => {
    let result = false;
    const { tasks } = values;
    const i18n = tasks[itemIndex].i18n;
    if (!i18n || !i18n[locale]) {
      result = true;
    } else {
      if (!i18n[locale].feedback) result = true;
    }
    return result;
  };

  return (
    <Card>
      <CardBody>
        <FormGroup>
          <Field
            name={`tasks.${taskIndex}.article`}
            component={BootstrapInput}
            type="text"
            label="Article"
            onBlur={handleSubmit}
          />
          <Field
            name={`tasks.${taskIndex}.word`}
            component={BootstrapInput}
            type="text"
            label="Word"
            onBlur={handleSubmit}
          />
          <Field
            name={`tasks.${taskIndex}.annotatedWord`}
            component={BootstrapInput}
            type="text"
            label="Annotated Word"
            onBlur={handleSubmit}
          />

          {task.round === 1 ? (
            <>
              <Card>
                <CardBody>
                  <label>Options</label>
                  <FieldArray name={`tasks.${taskIndex}.options`}>
                    {({ fields }) => (
                      <div>
                        {fields.map((name, index) => (
                          <Field
                            key={index}
                            name={`${name}`}
                            component={BootstrapInput}
                            type="text"
                            onBlur={handleSubmit}
                            addon={
                              <InputGroupAddon
                                style={{ cursor: 'pointer' }}
                                addonType="append"
                                onClick={() => onRemoveOption(fields, index)}
                              >
                                <InputGroupText>
                                  <Media
                                    src={trashIcon}
                                    style={{
                                      width: 18,
                                      height: 18,
                                    }}
                                  />
                                </InputGroupText>
                              </InputGroupAddon>
                            }
                          />
                        ))}
                        <FormGroup style={{ marginBottom: 0 }}>
                          <Button
                            style={{
                              margin: 0,
                              paddingTop: 2,
                              paddingBottom: 2,
                            }}
                            color="link"
                            onClick={() => fields.push('')}
                          >
                            + Add Option
                          </Button>
                        </FormGroup>
                      </div>
                    )}
                  </FieldArray>
                </CardBody>
              </Card>
            </>
          ) : (
            <>
              <label>Expected Phrase</label>
              <Field
                name={`tasks.${taskIndex}.options.0`}
                component={BootstrapInput}
                type="text"
                onBlur={handleSubmit}
              />
            </>
          )}

          <FormGroup className="file-uploader-container">
            <label className="uploader-label">Image</label>
            {task?.image ? (
              <Button
                style={{ marginRight: 15 }}
                onClick={() => setImagePreview(task.image)}
              >
                Preview
              </Button>
            ) : (
              <span style={{ marginRight: 15 }}>No image</span>
            )}
            <FileUploader
              label="Change"
              onChange={(e) => onChangeFileClick(e, taskIndex, 'image')}
              accept="image/*"
            />
          </FormGroup>
          <FormGroup className="file-uploader-container">
            <label className="uploader-label">Audio</label>
            {task?.audio ? (
              <AudioButton
                playing={playingAudio}
                url={API_URL + task?.audio}
                onPlay={handleAudioPlay}
                onEnded={handleAudioEnded}
                onPause={handleAudioEnded}
                style={{
                  paddingBottom: 0,
                  marginRight: 15,
                }}
              />
            ) : (
              <span style={{ marginRight: 15 }}>No audio</span>
            )}
            <FileUploader
              label="Change"
              onChange={(e) => onChangeFileClick(e, taskIndex, 'audio')}
              accept="audio/*"
            />
          </FormGroup>
          <FormGroup className="file-uploader-container">
            <label className="uploader-label">Video</label>
            {task?.video ? (
              <Button
                style={{ marginRight: 15 }}
                onClick={() => setVideoPreview(task.video)}
              >
                Preview
              </Button>
            ) : (
              <span style={{ marginRight: 15 }}>No video</span>
            )}
            <FileUploader
              label="Change"
              onChange={(e) => onChangeFileClick(e, taskIndex, 'video')}
              accept="video/*"
            />
          </FormGroup>

          <Tabs
            form={form}
            locales={Object.keys(task?.i18n || {})}
            tabPaneContentComponent={TabPaneTask}
            isTabContentInvalidFunc={isTaskLocalesInvalid}
            fieldNamePrefix={`tasks.${taskIndex}.i18n.`}
            item={task}
            itemIndex={taskIndex}
            handleSubmit={handleSubmit}
          />

          <ImagePreviewModal
            isOpen={!!imagePreview}
            image={imagePreview}
            onClosed={() => setImagePreview(null)}
          />
          <VideoPreviewModal
            isOpen={!!videoPreview}
            video={videoPreview}
            onClosed={() => setVideoPreview(null)}
          />
        </FormGroup>
      </CardBody>
    </Card>
  );
};
