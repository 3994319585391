import ApiService from './api';

export default class LessonService extends ApiService{
  constructor(token) {
    super(token);
  }
  
  async getLessons(){
    return this.get('/lesson');
  }

  async getLessonById(lessonId){
    return this.get(`/lesson/${lessonId}`);
  }

  async updateLessons(lessons){
    return this.put('/lesson', lessons);
  }

  async getLessonsByUnitId(unitId) {
    return this.get(`/unit/${unitId}/lessons`);
  }

  async updateLesson(lesson) {
    return this.put(`/lesson/${lesson._id}`, lesson);
  }
}

