import * as types from '../types';

const moduleReducers = {
  authenticatedUser: setAuthenticatedUser,
  userProfile: setUserProfile,
  anonymousUser: setAnonymousUser,
  modalsShown: setModalsShown,
  isEditor: setEditor,
  unitRecommendation: setUnitRecommendation,
};

function setAuthenticatedUser(state = null, action) {
  switch (action.type) {
    case types.SET_AUTHENTICATED_USER:
      return action.payload;
    default:
      return state;
  }
}

function setUserProfile(state = null, action) {
  switch (action.type) {
    case types.SET_USER_PROFILE:
      return action.payload;
    default:
      return state;
  }
}

function setAnonymousUser(state = null, action) {
  switch (action.type) {
    case types.SET_ANONYMOUS_USER:
      return action.payload;
    default:
      return state;
  }
}

function setModalsShown(state = [], action) {
  switch (action.type) {
    case types.SET_MODALS_SHOWN:
      return action.payload;
    default:
      return state;
  }
}
function setEditor(state = false, action) {
  switch (action.type) {
    case types.SET_EDITOR:
      return action.payload;
    default:
      return state;
  }
}

function setUnitRecommendation(state = null, action) {
  switch (action.type) {
    case types.SET_UNIT_RECOMMENDATION:
      return action.payload;
    default:
      return state;
  }
}

export default moduleReducers;
