import React, { memo } from 'react';
import { useSelector } from 'react-redux';
const ProtectedComponent = ({
  component: Comp,
  ...rest
}) => {  
  const authenticatedUser = useSelector(state => state.authenticatedUser);  
  const anonymousUser = useSelector(state => state.anonymousUser);  

  return (authenticatedUser||anonymousUser )? <Comp {...rest} /> : <React.Fragment/>;
};
  


export default memo(ProtectedComponent);