import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import ReactPlayer from 'react-player';
import { API_URL } from '../../../shared/constants/api';
import './VideoPreviewModal.scss';

export const VideoPreviewModal = ({
  isOpen, video, onClosed 
}) => {
  const [playingVideo, setPlayingVideo] = useState(false);
  const handleVideoEnded = () => {
    setPlayingVideo(false);
  };
  const handleVideoPlay = () => {
    setPlayingVideo(true);
  };

  return (
    <Modal isOpen={isOpen} onClosed={onClosed} toggle={onClosed}>
      <ModalBody className="body">
        <div className="videowrap">
          <ReactPlayer
            playing={playingVideo}
            url={API_URL + video}
            config={{ file: { forceVideo: true }}}
            controls={true}
            onPlay={handleVideoPlay}
            onEnded={handleVideoEnded}
            onPause={handleVideoEnded}
            width="100%"
            height="200px"
            className="text-center video"
          />
        </div>
      </ModalBody>
    </Modal>
  );
};
