import {
  takeEvery, put, call, select 
} from 'redux-saga/effects';
import * as types from '../types';
import UnitService from '../../service/unit';

function* fetchUnits(action){
  try{
    /** @type {UnitService}  */        
    const unitService = yield call(()=>new UnitService());
    let units = yield call([unitService, unitService.getUnits]);
    yield put({
      type:types.SET_UNITS,
      payload:units.sort((a,b)=>a.number>b.number?1:-1) 
    });
  }catch(error){
    yield put({
      type:types.NEW_ALERT_ERROR,
      payload:{ message:'Error fetching units:' + error.message }
    });
    console.error('fetchUnits error',error);
  }
}

function* setCurrentUnit(action){    
  try{      
    let units = yield select((state) => state.units);      
    if(units.length === 0){
      yield call(fetchUnits);
      units  = yield select((state) => state.units);      
    }
    yield put({
      type:types.SET_CURRENT_UNIT_LESSONS,
      payload:[] 
    });
    
    let currentUnit = units.find(unit=> unit._id === action.payload);
    if(currentUnit === null) {
      yield put({
        type:types.NEW_ALERT_ERROR,
        payload:{ message:'Unit cannot be loaded' }
      });
    } else{     
      yield put({
        type:types.SET_CURRENT_UNIT,
        payload:currentUnit 
      });

      /** @type {UnitService}  */
      const unitService = yield call(()=>new UnitService());
      let lessons = yield call([unitService, unitService.getUnitLessons], action.payload);           
      yield put({
        type:types.SET_CURRENT_UNIT_LESSONS,
        payload:lessons.sort((a,b)=>a.number>b.number?1:-1) 
      });
    }
    
  }catch(error){
    yield put({
      type:types.NEW_ALERT_ERROR,
      payload:{ message:'Error setting unit:' + error.message }
    });
    console.error('fetchUnitLessons error',error);
  }
}

export function* unitSagas() {
  yield takeEvery(types.FETCH_UNITS, fetchUnits);
  yield takeEvery(types.SELECT_UNIT, setCurrentUnit);
}
  
export default unitSagas;