import { useMutation, useQueryClient } from '@tanstack/react-query';
import UnitService from '../../service/unit';
import { useSelector } from 'react-redux';

export const useUpdateUnits = () => {
  const queryClient = useQueryClient();
  const token = useSelector((state) => state.token);
  const unitService = new UnitService(token);
  const query = ['units-list'];

  return useMutation(
    (units) => {
      return unitService.updateUnits(units);
    },
    {
      onMutate: async (units) => {
        await queryClient.cancelQueries(query);
        const oldUnits = queryClient.getQueryData(query) || [];
        queryClient.setQueryData(query, units);
        return { oldUnits };
      },
      onError: (_error, _vars, context) => {
        if (context !== undefined) {
          queryClient.setQueryData(query, context.oldUnits);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(query);
      },
    },
  );
};