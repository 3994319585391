import React, { useEffect, useState } from 'react';
import {
  Table, Row, Media 
} from 'reactstrap';
import arrowLeft from '../../shared/images/arrow-left.svg';
import { withNamespaces } from 'react-i18next';
import { getSupportedLanguage } from '../../utils/translate';
import './SortableTable.scss';

export const SortableTable = withNamespaces()(({
  items, onItemClick, onItemsUpdate, lng 
}) => {
  const sortFunc = (a, b) => a.number - b.number;
  const [sortedItems, setSortedItems] = useState(items.sort(sortFunc));
  const supportedLng = getSupportedLanguage(lng);

  useEffect(() => {
    setSortedItems(items.sort(sortFunc));
  }, [items]);

  const arrayMove = (arr, fromIndex, toIndex) => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return [...arr];
  };

  const setNumbers = (items) => {
    let counter = 1;
    for (const item of items) {
      item.number = counter++;
    }
    return items;
  };

  const onArrowUpClick = (e, item) => {
    e.stopPropagation();

    const index = item.number - 1;
    if (index > 0) {
      let updatedItems = arrayMove(sortedItems, index, index - 1);
      updatedItems = setNumbers(updatedItems);
      onItemsUpdate(updatedItems);
    }
  };

  const onArrowDownClick = (e, item) => {
    e.stopPropagation();

    const index = item.number - 1;
    if (index < sortedItems.length - 1) {
      let updatedItems = arrayMove(sortedItems, index, index + 1);
      updatedItems = setNumbers(updatedItems);
      onItemsUpdate(updatedItems);
    }
  };

  return (
    <Table className="items-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Title</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {sortedItems.map((item, index) => (
          <tr key={item._id} onClick={() => onItemClick(item)}>
            <td>{item.number}</td>
            <td>{item.i18n?.[supportedLng]?.title}</td>
            <td>
              <Row>
                <span
                  onClick={(e) => onArrowUpClick(e, item)}
                  className="icon-container"
                >
                  <Media
                    src={arrowLeft}
                    style={{
                      rotate: '90deg',
                      opacity: index === 0 ? 0.5 : 1,
                    }}
                    className="icon"
                  />
                </span>
                <span
                  onClick={(e) => onArrowDownClick(e, item)}
                  className="icon-container"
                >
                  <Media
                    src={arrowLeft}
                    style={{
                      rotate: '270deg',
                      opacity: index === sortedItems.length - 1 ? 0.5 : 1,
                    }}
                    className="icon"
                  />
                </span>
              </Row>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
});
