import React from 'react';
import {
  FormFeedback,
  FormGroup,
  Input,
  Label,
  InputGroup,
} from 'reactstrap';

export default function BootstrapInput({
  input,
  defaultValue,
  label,
  placeholder,
  faIcon,
  disabled,
  type,
  style,
  onBlur = () => {},
  onChange,
  addon,
  meta: {
    asyncValidating, touched, error, invalid 
  },
}) {
  return (
    <FormGroup>
      {label && <Label for={input.name}>{label}</Label>}
      <InputGroup>
        <Input
          {...input}
          disabled={disabled}
          style={style || {}}
          placeholder={placeholder}
          invalid={touched && invalid}
          onBlur={onBlur}
          onChange={onChange ? onChange : input.onChange}
        />
        {addon}
      </InputGroup>
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  );
}
