import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import {
  Col, Row, Media 
} from 'reactstrap';
import trophyBgIcon from '../shared/images/new/trophy-bg.svg';
import scheduleBgIcon from '../shared/images/new/schedule-bg.svg';
import translate, { getSupportedLanguage } from '../utils/translate';
import * as dayjs from 'dayjs';
import * as  weekday from 'dayjs/plugin/weekday';
import { WebtrekkSmartPixelReact } from '@webtrekk-smart-pixel/react';
import Streak from '../components/stats/Streak';
import { ProgressPanel } from '../components/fragments/progress_panel/ProgressPanel';
import { ProgressEntityStats } from '../components/fragments/progress-entity-stats/ProgressEntityStats';
import { BGImage } from '../components/fragments/bg_image/BGImage';
import bgImage from "../shared/images/bg/progress.svg";
import './ProgressWeek.scss';

dayjs.extend(weekday);

class ProgressWeek extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsableOpened: {},
      nextLesson: null,
      nextUnit: null,
    };
  }  
  static propTypes = {
    units: PropTypes.array,
    lessons: PropTypes.array,
    lessonsCompleted: PropTypes.array,
    userResults: PropTypes.array,
    unitsCompleted: PropTypes.array,
    unitsResults: PropTypes.object,
  }
  onGoBack() {
    this.props.history.push('/progress/');
  }

  componentDidMount(){
    WebtrekkSmartPixelReact.page('aussprachetraining_goethe_de.progress.week', { });
    WebtrekkSmartPixelReact.track();
  }

  getWeeksLessons(worst = false) {
    const lng = getSupportedLanguage(this.props.lng);
    let result = [];
    let weekday = dayjs().day();
    // iso weeks not supported
    let startOfWeek = weekday === 0 ? dayjs().subtract(1, 'd').startOf('week').add(1, 'd') : dayjs().startOf('week').add(1, 'd');
    let seenLessons = [];
    result = this.props.userResults
      .map(userResult => {
        let lesson = this.props.lessons.find(lesson => lesson._id === userResult.lessonId);
        return Object.assign(userResult, { title: translate(lesson, 'title', lng) });
      })
      .filter(userResult => dayjs(userResult.finishedAt).isAfter(startOfWeek))
      .sort((a, b) => {
        return worst ?
          (a.ratio >= b.ratio ? 1 : -1) :
          (a.ratio >= b.ratio ? -1 : 1);
      })
      .filter(userResult => {
        let result = seenLessons.indexOf(userResult.lessonId) === -1 ? true : false;
        seenLessons.push(userResult.lessonId);
        return result;
      });

    return result;
  }

  render() {
    const { streak } = { ...this.props };

    if (!this.props.units.length && this.props.lessons.length) {
      return <React.Fragment />;
    }

    return (
      <Row className="progress-week-page">
        <Col lg={{
          offset: 2,
          size: 4 
        }}>
          <h1 className='page-title'>{this.props.t('Progress')}</h1>
          <Streak streak={streak} />

          <ProgressPanel onBackClick={() => this.onGoBack()} title={this.props.t('progressWeek')} >
            <Col>
              <Row className='block-container'>
                <Col className='left-container'>
                  <Media object src={trophyBgIcon} className="trophy-icon" />
                </Col>
                <Col>
                  <Row>
                    <p className='title'>{this.props.t('The best')}</p>
                  </Row>
                  {this.getWeeksLessons().map(lessonResult => (
                    <Row>
                      <ProgressEntityStats
                        key={lessonResult.title}
                        title={lessonResult.title}
                        ratio={lessonResult.ratio}
                        time={lessonResult.minutesSpend}
                      />
                    </Row>
                  ))}
                </Col>
              </Row>
              <Row className='block-container'>
                <Col className='left-container'>
                  <Media object src={scheduleBgIcon} className="schedule-icon" />
                </Col>
                <Col>
                  <Row>
                    <p className='title'>{this.props.t('progressWeekImprove')}</p>
                  </Row>
                  {this.getWeeksLessons(true).map(lessonResult => (
                    <Row>
                      <ProgressEntityStats
                        key={lessonResult.title}
                        title={lessonResult.title}
                        ratio={lessonResult.ratio}
                        time={lessonResult.minutesSpend}
                      />
                    </Row>
                  ))}
                </Col>
              </Row>
            </Col>
          </ProgressPanel>
        </Col>
        <BGImage
          imageUrl={bgImage}
          className="screen-height"
          desktopStyle={{ paddingBottom: 40 }}
          mobileStyle={{ top: this.props.anonymousUser ? 240 : 195 }}
        />
      </Row>
    );
  }
}



const mapStateToProps = (state) => ({
  units: state.units,
  lessons: state.lessons,
  lessonsCompleted: state.lessonsCompleted,
  userResults: state.userResults,
  unitsCompleted: state.unitsCompleted,
  unitsResults: state.unitsResults,
  streak: state.streak,
  anonymousUser: state.anonymousUser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ProgressWeek));

