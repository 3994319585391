import { LOCALES } from '../shared/constants';

export default (prop = {}, field = 'title', lng = LOCALES.DEFAULT) => {
  let localized = prop.i18n?.[lng];
  // workaround for wrong locale name
  if(!localized && lng == 'vi'){
    localized = prop.i18n?.['vn']||localized;
  }
  const fieldIsNumber = !!Number(field);

  if (fieldIsNumber) {
    return localized?.roundHeaders[field - 1] || prop.roundHeaders[field - 1];
  }

  return localized?.[field] || prop[field];
};

export const getSupportedLanguage = (lng) => {
  let result = lng.substring(0, 2);
  if (!LOCALES.AVAILABLE.includes(result)) {
    result = LOCALES.DEFAULT;
  }
  return result;
};
