
import { SELECT_UNIT, FETCH_UNITS } from '../types';
export function setCurrentUnit(unitId) {
  return  {
    type:SELECT_UNIT,
    payload:unitId 
  };
}

export function fetchUnits() {
  return  {
    type:FETCH_UNITS,
    payload:{} 
  };
}