import React from 'react';
import { Field } from 'react-final-form';
import BootstrapInput from '../../form/BootstrapInput';

export const TabPaneLesson = ({
  fieldNamePrefix, locale, item, handleSubmit 
}) => {
  return (
    <>
      <Field
        name={`${fieldNamePrefix}${locale}.title`}
        component={BootstrapInput}
        type="text"
        label={'Title'}
        onBlur={handleSubmit}
      />
      <label>Round Headers</label>
      {item?.i18n[locale].roundHeaders?.map((roundHeader, index) => (
        <Field
          key={index}
          name={`${fieldNamePrefix}.${locale}.roundHeaders.${index}`}
          type="input"
          component={BootstrapInput}
          onBlur={handleSubmit}
        />
      ))}
    </>
  );
};
