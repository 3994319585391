import React, { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import translate, { getSupportedLanguage } from '../../utils/translate';
import { API_URL } from '../../shared/constants/api';
import { UNIT_ICONS } from '../../shared/constants/icon';
import { CardListItem } from '../fragments/card_list_item/CardListItem';
import { UnitRecommendationContext } from '../../context/UnitRecommendationContext';
import { STATE } from '../../shared/constants/state';
import { useHistory } from 'react-router-dom';
import './UnitRecommendation.scss';
import { useSelector } from 'react-redux';

export const UnitRecommendation = withNamespaces()((props) => {
  const { t, lng } = props;

  const { unit, unitLessons, unitLessonsCompleted } = useContext(
    UnitRecommendationContext
  );
  const history = useHistory();
  const unitsResults = useSelector((state) => state.unitsResults);
  const unitResult = unitsResults[unit?._id];

  const supportedLanguage = getSupportedLanguage(lng);

  const onCardClick = () => {
    history.push('/unit/' + unit._id);
  };

  if (!unit) {
    return null;
  }

  return (
    <Row className="unit-recommendation">
      <Col>
        <Row>
          <div className="recommendation-card-text">
            <div className="recommendation-card-title">
              {t('recommendationTitle')}
            </div>
            {t('recommendationSubtitle')}
          </div>
        </Row>
        <Row>
          {unit && (
            <CardListItem
              onCardClick={onCardClick}
              badgeText={t('resultLessons', {
                current: unitLessonsCompleted.length,
                total: unitLessons.length || 0,
              })}
              subtitle={translate(unit, 'title', supportedLanguage)}
              state={STATE.UNLOCKED}
              percentage={
                (unitLessonsCompleted.length / (unitLessons.length || 0)) * 100
              }
              icon={
                unit.icon
                  ? API_URL + unit.icon
                  : UNIT_ICONS[(unit.number - 1) % UNIT_ICONS.length]
              }
              lang={supportedLanguage}
              ratio={unitResult?.ratio || 0}
            />
          )}
        </Row>
      </Col>
    </Row>
  );
});

UnitRecommendation.propTypes = {};
