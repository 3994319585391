import * as types from '../types';

const moduleReducers = {
  units: setUnits,
  currentUnitLessons: setCurrentUnitLessons,
  currentUnit: setCurrentUnit,
};

function setUnits(state = [], action) {
  switch (action.type) {
  case types.SET_UNITS:
    return action.payload;
  default:
    return state;
  }
}


function setCurrentUnitLessons(state = [], action) {
  switch (action.type) {
  case types.SET_CURRENT_UNIT_LESSONS:
    return action.payload;
  default:
    return state;
  }
}
function setCurrentUnit(state = null, action) {
  switch (action.type) {
  case types.SET_CURRENT_UNIT:
    return action.payload;
  default:
    return state;
  }
}
export default moduleReducers;