import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useUnitsList } from '../hooks/unit/useUnitsList';
import {
  Row, Media, Button, Col 
} from 'reactstrap';
import { useUpdateUnits } from '../hooks/unit/useUpdateUnits';
import arrowLeft from '../shared/images/arrow-left.svg';
import { SortableTable } from '../components/admin/SortableTable';
import './UnitsList.scss';

export const UnitsList = () => {
  const history = useHistory();
  const user = useSelector((state) => state.authenticatedUser);
  const { data: units } = useUnitsList();

  const { mutate: updateUnits } = useUpdateUnits();

  useEffect(() => {
    if (!user.isAdmin) {
      history.replace('/');
    }
  }, [history, user]);

  const onUnitClick = (unit) => {
    history.push(`/editor/units/${unit._id}`);
  };

  const onGoBackClick = () => {
    history.goBack();
  };

  return (
    <Row className="units-list">
      <Col lg={{
        offset: 2,
        size: 8 
      }}>
        <div style={{ display: 'flex' }}>
          <Button className="back-btn" onClick={onGoBackClick}>
            <Media src={arrowLeft} className="back-icon" />
          </Button>
          <h3 style={{ marginBottom: 0 }}>Units</h3>
        </div>
        <br/>
        <SortableTable
          items={units}
          onItemClick={onUnitClick}
          onItemsUpdate={updateUnits}
        />
      </Col>
    </Row>
  );
};
