import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import lessonDoneImage from '../../shared/images/lesson-bg-blue-big.svg';
import starYellowImage from '../../shared/images/star-yellow.svg';
import starGrayImage from '../../shared/images/star-grey.svg';
import calenderImage from '../../shared/images/calender-big.svg';
import clockImage from '../../shared/images/clock.svg';
import scheduleBgIcon from '../../shared/images/new/schedule-bg.svg';
import { Media, Button, Row, Col } from 'reactstrap';
import './LessonResult.scss';
import * as dayjs from 'dayjs';
import { RESULT, FEEDBACK_TYPES } from '../../shared/constants';
import UserFeedback from '../feedback/UserFeedback';
import { ResultHeader } from '../fragments/result_header/ResultHeader';
import { ProgressEntityStats } from '../fragments/progress-entity-stats/ProgressEntityStats';
import { DefaultBtn } from '../fragments/default_btn/DefaultBtn';
import { UnitRecommendationContextProvider } from '../../context/UnitRecommendationContext';
import { UnitRecommendation } from '../recommendation/UnitRecommendation';

function LessonResult(props) {
  let { currentLessonResult, currentLesson, currentUnit, t } = props;

  if (
    !currentLessonResult ||
    !currentLessonResult.taskResults ||
    !currentLesson
  ) {
    return <React.Fragment></React.Fragment>;
  }

  let correctAnswers = [];
  let totalAnswers = [];
  [1, 2, 3].forEach((round) => {
    let roundResult = props.currentLessonResult.taskResults[round];
    correctAnswers[round] = roundResult.reduce(
      (prev, curr) => (curr.success ? prev + 1 : prev),
      0
    );
    totalAnswers[round] = roundResult.length;
  });

  let showFeedback = 0;
  const uniqueLessonsCompleted = [...new Set(props.lessonsCompleted || [])];

  if (uniqueLessonsCompleted.length === 1) showFeedback = 1;
  if (uniqueLessonsCompleted.length === 4) showFeedback = 2;

  return (
    <Row>
      <Col
        lg={{
          offset: 2,
          size: 8,
        }}
      >
        <Row className="lesson-result">
          {showFeedback === 1 && <UserFeedback type={FEEDBACK_TYPES.V1} />}
          {showFeedback === 2 && <UserFeedback type={FEEDBACK_TYPES.V2} />}

          <Col>
            <Row>
              <Col className="schedule-bg-icon-container">
                <Media src={scheduleBgIcon} className="schedule-bg-icon" />
              </Col>
              <Col>
                <ProgressEntityStats
                  title={t('resultLessonNumber', {
                    number: currentLesson.number,
                  })}
                  ratio={currentLessonResult.ratio}
                  date={Date.now()}
                  time={45 /*currentLessonResult.minutesSpend*/}
                />
              </Col>
            </Row>

            <Row className="table-container">
              <table className="table">
                <tbody>
                  {[1, 2, 3].map((round) => {
                    return (
                      <tr key={round}>
                        <td>
                          {t('lessonSeries')} {round}
                        </td>
                        <td className="bold">
                          {correctAnswers[round]}/{totalAnswers[round]}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Row>

            <Row className="btn-container">
              <DefaultBtn
                label={props.t('lessonResultNextButton')}
                onClick={() => props.next()}
              />
            </Row>

            <Row className="recomendation-container">
              <UnitRecommendationContextProvider>
                <UnitRecommendation />
              </UnitRecommendationContextProvider>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

LessonResult.propTypes = {
  currentLessonResult: PropTypes.object,
  currentLesson: PropTypes.object,
  currentUnit: PropTypes.object,
  next: PropTypes.func,
  lessonsCompleted: PropTypes.array,
};

export default withNamespaces()(LessonResult);
