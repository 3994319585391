import { useMutation, useQueryClient } from '@tanstack/react-query';
import UnitService from '../../service/unit';
import { useSelector } from 'react-redux';

export const useUpdateUnit = (unitId) => {
  const queryClient = useQueryClient();
  const token = useSelector((state) => state.token);
  const unitService = new UnitService(token);
  const query = ['unit-by-id', unitId];

  return useMutation(
    (unit) => {
      return unitService.updateUnit(unit);
    },
    {
      onMutate: async (unit) => {
        await queryClient.cancelQueries(query);
        const oldUnit = queryClient.getQueryData(query);
        queryClient.setQueryData(query, unit);
        return { oldUnit };
      },
      onError: (_error, _vars, context) => {
        if (context !== undefined) {
          queryClient.setQueryData(query, context.oldUnit);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(query);
      },
    },
  );
};