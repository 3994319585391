import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalBody, ModalHeader 
} from 'reactstrap';
import i18n from '../../i18n';
import { removeGlobalDialog } from '../../redux/actions/globalDialog';
import { useDispatch } from 'react-redux';


function Contact(props) {
  let lng = i18n.language;
  const dispatch = useDispatch();
  let urls = {
    de:'https://www.goethe.de/de/spr/ueb/ast/knt.html',
    en:'https://www.goethe.de/en/spr/ueb/ast/knt.html',
    vi:'https://www.goethe.de/ins/vn/vi/spr/ueb/ast/knt.html'
  };
  let url = urls[lng]||urls.de;
  return (
    <Modal isOpen size="lg" style={{
      maxWidth: '1600px',
      width: '100vw-1rem' 
    }}>
      <ModalHeader toggle={()=>dispatch(removeGlobalDialog())} />
      <ModalBody>
        <iframe src={url} style={{
          width:'100%',
          height:'80vh', 
        }} frameborder="0"></iframe>
      </ModalBody>

    </Modal>
  );
}

Contact.propTypes = {};

export default Contact;
