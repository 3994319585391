import u1 from '../images/unit/U1.svg';
import u2 from '../images/unit/U2.svg';
import u3 from '../images/unit/U3.svg';
import u4 from '../images/unit/U4.svg';
import u5 from '../images/unit/U5.svg';
import u6 from '../images/unit/U6.svg';
import u7 from '../images/unit/U7.svg';
import u8 from '../images/unit/U8.svg';
import u9 from '../images/unit/U9.svg';
import u10 from '../images/unit/U10.svg';
import u11 from '../images/unit/U11.svg';
import u12 from '../images/unit/U12.svg';
import u1bg from '../images/unit-bg/U1.svg';
import u2bg from '../images/unit-bg/U2.svg';
import u3bg from '../images/unit-bg/U3.svg';
import u4bg from '../images/unit-bg/U4.svg';
import u5bg from '../images/unit-bg/U5.svg';
import u6bg from '../images/unit-bg/U6.svg';
import u7bg from '../images/unit-bg/U7.svg';
import u8bg from '../images/unit-bg/U8.svg';
import u9bg from '../images/unit-bg/U9.svg';
import u10bg from '../images/unit-bg/U10.svg';
import u11bg from '../images/unit-bg/U11.svg';
import u12bg from '../images/unit-bg/U12.svg';
import u1l1 from '../images/lesson/U1_L1.svg';
import u1l2 from '../images/lesson/U1_L2.svg';
import u1l3 from '../images/lesson/U1_L3.svg';
import u2l1 from '../images/lesson/U2_L1.svg';
import u2l2 from '../images/lesson/U2_L2.svg';
import u2l3 from '../images/lesson/U2_L3.svg';
import u3l1 from '../images/lesson/U3_L1.svg';
import u3l2 from '../images/lesson/U3_L2.svg';
import u3l3 from '../images/lesson/U3_L3.svg';
import u4l1 from '../images/lesson/U4_L1.svg';
import u4l2 from '../images/lesson/U4_L2.svg';
import u4l3 from '../images/lesson/U4_L3.svg';
import u5l1 from '../images/lesson/U5_L1.svg';
import u5l2 from '../images/lesson/U5_L2.svg';
import u5l3 from '../images/lesson/U5_L3.svg';
import u6l1 from '../images/lesson/U6_L1.svg';
import u6l2 from '../images/lesson/U6_L2.svg';
import u6l3 from '../images/lesson/U6_L3.svg';
import u7l1 from '../images/lesson/U7_L1.svg';
import u7l2 from '../images/lesson/U7_L2.svg';
import u7l3 from '../images/lesson/U7_L3.svg';
import u8l1 from '../images/lesson/U8_L1.svg';
import u8l2 from '../images/lesson/U8_L2.svg';
import u8l3 from '../images/lesson/U8_L3.svg';
import u9l1 from '../images/lesson/U9_L1.svg';
import u9l2 from '../images/lesson/U9_L2.svg';
import u9l3 from '../images/lesson/U9_L3.svg';
import u10l1 from '../images/lesson/U10_L1.svg';
import u10l2 from '../images/lesson/U10_L2.svg';
import u10l3 from '../images/lesson/U10_L3.svg';
import u11l1 from '../images/lesson/U11_L1.svg';
import u11l2 from '../images/lesson/U11_L2.svg';
import u11l3 from '../images/lesson/U11_L3.svg';
import u12l1 from '../images/lesson/U12_L1.svg';
import u12l2 from '../images/lesson/U12_L2.svg';
import u12l3 from '../images/lesson/U12_L3.svg';

export const UNIT_ICONS = [
  u1, u2, u3, u4, u5, u6, u7, u8, u9, u10, u11, u12
];

export const UNIT_BG_IMAGES = [
  u1bg, u2bg, u3bg, u4bg, u5bg, u6bg, u7bg, u8bg, u9bg, u10bg, u11bg, u12bg
]

export const LESSON_ICONS = [
  [u1l1, u1l2, u1l3],
  [u2l1, u2l2, u2l3],
  [u3l1, u3l2, u3l3],
  [u4l1, u4l2, u4l3],
  [u5l1, u5l2, u5l3],
  [u6l1, u6l2, u6l3],
  [u7l1, u7l2, u7l3],
  [u8l1, u8l2, u8l3],
  [u9l1, u9l2, u9l3],
  [u10l1, u10l2, u10l3],
  [u11l1, u11l2, u11l3],
  [u12l1, u12l2, u12l3]
];