import React from 'react';
import { useSelector } from 'react-redux';
import Contact from './globalDialogs/Contact';
export const DIALOGS = { CONTACT:'contact' };

const typeMap={ 'contact':Contact };

function GlobalDialog() {
  const globalDialog = useSelector((state) => state.globalDialog);
  let { type, props } = globalDialog;
  console.log('GlobalDialog', {
    type,
    props 
  });
  if (!type) {
    return <React.Fragment />;
  }
  const Modal = typeMap[type];
  return <Modal {...props} />;
}

GlobalDialog.propTypes = {};

export default GlobalDialog;
