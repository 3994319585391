import React from 'react';
import {
  FormFeedback, FormGroup, Input, Label
} from 'reactstrap';

export default function BootstrapSelect({
  input,
  defaultValue,
  label,
  placeholder,
  faIcon,
  disabled,
  options,
  style,
  meta: {
    asyncValidating,
    touched,
    error,
    invalid
  }
}) {
  return (
    <FormGroup>
      <Label for={input.name}>{label}</Label>
      <Input
        {...input}
        style={style||{}}
        disabled={disabled}
        placeholder={placeholder}
        invalid={touched && invalid}
      >
        {Object.keys(options).map(optionKey => <option key={optionKey} value={optionKey}>{options[optionKey]}</option>)}
      </Input>
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  );
};

