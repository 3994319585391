import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Media, Row 
} from 'reactstrap';
import { ProgressEntityStats } from '../progress-entity-stats/ProgressEntityStats';
import trophyBgIcon from '../../../shared/images/new/bookmark.svg';
import './ProgressEntity.scss';

export const ProgressEntity = (props) => {
  const {
    isCompleted, isLast, title, ratio = 0, date, time 
  } = props;

  return (
    <Row className='entity-container'>
      <Col className={`trophy-icon-container ${isLast && 'last'}`}>
        {isCompleted ? <Media src={trophyBgIcon} style={{ backgroundColor: 'white' }} className='trophy-icon' /> : <div className='circle' />}
      </Col>
      <ProgressEntityStats
        title={title}
        ratio={ratio}
        date={date}
        time={time}
      />
    </Row>
  );
};

ProgressEntity.propTypes = {
  isCompleted: PropTypes.bool,
  isLast: PropTypes.bool,
  title: PropTypes.string,
  ratio: PropTypes.number,
  date: PropTypes.string,
  time: PropTypes.number,
};