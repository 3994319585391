import { WebtrekkSmartPixelReact } from '@webtrekk-smart-pixel/react';

import React, { Component } from 'react';

import { connect } from 'react-redux';

import { bindActionCreators, compose } from 'redux';

import { withNamespaces } from 'react-i18next';

import { Row, Col, Media } from 'reactstrap';

import { withRouter } from 'react-router-dom';

import * as dayjs from 'dayjs';

import { PropTypes } from 'prop-types';

import translate, { getSupportedLanguage } from '../utils/translate';
import LessonExerciseTask from '../components/lesson/LessonExerciseTask';
import LessonModal from '../components/lesson/LessonModal';
import LessonResult from '../components/lesson/LessonResult';
import LessonRoundResult from '../components/lesson/LessonRoundResult';
import ProgressBar from '../components/lesson/ProgressBar';
import UnitHelp from '../components/unit/UnitHelp';
import {
  startLesson,
  taskChange,
  lessonCompleted,
  anonymousLessonCompleted,
} from '../redux/actions/lesson';
import { setModalShown } from '../redux/actions/user';
import { LESSON } from '../shared/constants/lesson';
import { PRONONCIATION_ERRORS_STORAGE_KEY } from '../shared/constants/storage';
import { BREAKPOINT_MOBILE_LAYOUT } from '../shared/constants/theme';
import closeIcon from '../shared/images/close.svg';
import { SuccessStreakModal } from '../components/fragments/success_streak_modal/SuccessStreakModal';
import { TipsBtn } from '../components/fragments/tips_btn/TipsBtn';
import arrowBackIcon from '../shared/images/new/arrow-back.svg';

import './Lesson.scss';

const SUCCESS_STREAK_MODAL_DAYS_IN_A_ROW = 7;

class Lesson extends Component {
  static propTypes = {
    authenticatedUser: PropTypes.object,
    anonymousLessonCompleted: PropTypes.func,
    anonymousUser: PropTypes.string,
    currentLesson: PropTypes.object,
    currentUnit: PropTypes.object,
    currentUnitLessons: PropTypes.array,
    history: PropTypes.object,
    isEditor: PropTypes.any,
    lessonCompleted: PropTypes.func,
    lng: PropTypes.any,
    match: PropTypes.object,
    modalsShown: PropTypes.array,
    setModalShown: PropTypes.func,
    startLesson: PropTypes.func,
    t: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = {
      round: 1,
      progress: 0,
      unitModalIsOpened: false,
      lessonModalIsOpened: false,
      allowSkipButton: true,
      skipsInRound: 0,
      showLessonResult: false,
      showLessonRoundResult: false,
      currentLessonResult: null,
      currentFailedAttempts: 0,
      currentStatus: false,
      taskStartedAt: new Date(),
      screenWidth: 0,
      successStreakModalOpened: false,
    };
    this.updateScreenWidth = this.updateScreenWidth.bind(this);
    // if (props.currentLesson) this.initCurrentLessonResult();
  }

  componentDidMount() {
    let lessonId = this.props.match.params?.id;
    this.props.startLesson(lessonId);
    this.autoOpenLessonModal(lessonId);
    this.initCurrentLessonResult();
    this.updateScreenWidth();
    window.addEventListener('resize', this.updateScreenWidth);
  }

  componentDidUpdate(prevProps) {
    let { currentUnit, currentLesson } = this.props;
    let isLessonInited =
      currentLesson &&
      (prevProps.currentLesson === null ||
        currentLesson._id !== prevProps.currentLesson._id);
    let isUnitInited =
      currentUnit &&
      (prevProps.currentUnit === null ||
        currentUnit._id !== prevProps.currentUnit._id);
    if (isLessonInited) {
      this.initCurrentLessonResult();
    }
    if (isLessonInited && isUnitInited) {
      WebtrekkSmartPixelReact.page(
        `aussprachetraining_goethe_de.unit_${currentUnit?.number}.lesson_${currentLesson?.number}`,
        {}
      );
      WebtrekkSmartPixelReact.track();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateScreenWidth);
  }

  updateScreenWidth() {
    this.setState({ screenWidth: window.innerWidth });
  }

  autoOpenLessonModal(lessonId, round = 1) {
    let modalId = 'lesson_' + lessonId + '_' + round;
    if (this.props.modalsShown.indexOf(modalId) === -1) {
      this.setState({ lessonModalIsOpened: true });
      this.props.setModalShown(modalId);
    }
  }

  initCurrentLessonResult() {
    let { currentLesson } = this.props;
    if (!currentLesson?.tasks?.length) {
      return;
    }
    let currentLessonResultJson = localStorage.getItem('currentLessonResult');
    let currentLessonResult = {};
    try {
      currentLessonResult = JSON.parse(currentLessonResultJson);
    } catch (e) {}
    if (
      !currentLessonResult?.lessonId ||
      currentLessonResult?.lessonId !== currentLesson._id
    ) {
      currentLessonResult = {
        userId: null,
        lessonId: null,
        taskResults: [],
        startedAt: new Date(),
        finishedAt: null,
        minutesSpend: 0,
        totalSuccess: 0,
        totalAttempts: 0,
        totalTasks: 0,
        ratio: 0,
        unitCompleted: false,
      };
      [1, 2, 3].forEach((i) =>
        this.initCurrentLessonResultForRound(i, currentLessonResult)
      );
      let { authenticatedUser, currentLesson } = this.props;
      currentLessonResult.userId = authenticatedUser?._id || null;
      currentLessonResult.lessonId = currentLesson._id || null;
    } else {
      delete currentLessonResult?.taskResults?.[0];
      this.restoreProgress(currentLessonResult);
    }
    this.setState({ currentLessonResult });
  }

  restoreProgress(currentLessonResult) {
    let latestRound = null;
    let latestProgress = null;
    [1, 2, 3].forEach((round) => {
      if (currentLessonResult.taskResults[round]) {
        currentLessonResult.taskResults?.[round]?.forEach &&
          currentLessonResult.taskResults[round].forEach(
            (taskResult, progress) => {
              if (
                taskResult.skipped ||
                taskResult.success ||
                taskResult.attempts == 3
              ) {
                latestRound = round;
                latestProgress = progress;
              }
            }
          );
      }
    });
    if (latestRound) {
      this.setState(
        {
          round: latestRound,
          progress: latestProgress,
        },
        () => this.nextTask(true)
      );
    }
  }
  initCurrentLessonResultForRound(round, currentLessonResult) {
    let { currentLesson } = this.props;
    if (!currentLesson.tasks?.length) {
      return;
    }
    currentLessonResult.taskResults[round] = [];
    currentLesson.tasks[round].map((task, index) => {
      currentLessonResult.taskResults[round][index] = {
        success: false,
        attempts: 0,
      };
    });
    this.setState({ currentLessonResult });
  }
  onSelectOption = (option) => {
    let success = option.indexOf('*') > -1;

    this.onAudioResult(success ? LESSON.AUDIO.RIGHT : LESSON.AUDIO.WRONG);
  };

  onAudioResult(result) {
    let { round, progress, currentLessonResult } = this.state;
    const taskResult = { ...currentLessonResult.taskResults[round][progress] };

    if (!taskResult.success || taskResult.attempts < 3) {
      taskResult.attempts++;
      if (result === LESSON.AUDIO.RIGHT) {
        taskResult.success = true;
      } else {
        taskResult.success = false;
      }
      taskResult.successLevel = result;
    }
    this.storeTaskResult(taskResult);
  }

  storeTaskResult(taskResult) {
    let { round, progress, taskStartedAt, currentLessonResult } = this.state;
    let newCurrentLessonResult = { ...currentLessonResult };
    if (taskResult.skipped || taskResult.success || taskResult.attempts === 3) {
      taskResult.startedAt = taskStartedAt;
      taskResult.finishedAt = new Date();
      taskResult.userId = this.props.authenticatedUser?._id || null;
    }
    taskResult.startedAt = taskStartedAt;
    taskResult.finishedAt = new Date();
    newCurrentLessonResult.taskResults[round][progress] = taskResult;
    this.setState({ currentLessonResult: newCurrentLessonResult });
    localStorage.setItem(
      'currentLessonResult',
      JSON.stringify(currentLessonResult)
    );
  }

  storeResult() {
    let currentLessonResult = { ...this.state.currentLessonResult };
    let currentUnitLessons = this.props.currentUnitLessons;

    currentLessonResult.totalSuccess = 0;
    currentLessonResult.totalAttempts = 0;
    currentLessonResult.totalTasks = 0;
    [1, 2, 3].forEach((round) => {
      let roundResult = currentLessonResult.taskResults[round];
      currentLessonResult.totalSuccess += roundResult.reduce(
        (prev, curr) => (curr.success ? prev + 1 : prev),
        0
      );
      currentLessonResult.totalAttempts += roundResult.reduce(
        (prev, curr) => prev + curr.attempts,
        0
      );
      currentLessonResult.totalTasks += roundResult.length;
    });
    currentLessonResult.ratio =
      currentLessonResult.totalSuccess / currentLessonResult.totalTasks;
    currentLessonResult.unitCompleted =
      currentUnitLessons[currentUnitLessons.length - 1]?._id ==
      this.props.currentLesson._id;

    currentLessonResult.lessonId = this.props.currentLesson._id;
    currentLessonResult.unitId = this.props.currentUnit?._id;
    currentLessonResult.userId = this.props.authenticatedUser?._id || null;
    currentLessonResult.finishedAt = new Date();
    currentLessonResult.minutesSpend = Math.ceil(
      (dayjs(currentLessonResult.finishedAt)
        .subtract(dayjs(currentLessonResult.startedAt))
        .unix() +
        1) /
        60
    );

    this.setState({ currentLessonResult: currentLessonResult });
    let newCurrentLessonResult = { ...currentLessonResult };

    let taskResults = [];
    [1, 2, 3].forEach((round) => {
      newCurrentLessonResult.taskResults[round].forEach((taskResult) => {
        delete taskResult.successLevel;
        taskResult.round = round;
        taskResults.push(taskResult);
      });
    });
    newCurrentLessonResult.taskResults = taskResults;

    newCurrentLessonResult.prononciationErrors =
      JSON.parse(localStorage.getItem(PRONONCIATION_ERRORS_STORAGE_KEY)) || {};

    if (this.props.anonymousUser) {
      this.props.anonymousLessonCompleted(
        newCurrentLessonResult.lessonId,
        newCurrentLessonResult.unitCompleted ? currentLessonResult.unitId : null
      );
    } else {
      this.props.lessonCompleted(newCurrentLessonResult);
    }
    localStorage.setItem('currentLessonResult', JSON.stringify({}));
    localStorage.setItem(PRONONCIATION_ERRORS_STORAGE_KEY, JSON.stringify({}));
  }

  nextTask = (fromRestoreProgress = false) => {
    let { round, progress } = this.state;
    let { currentLesson } = this.props;

    if (round < 3 && progress === currentLesson.tasks[round].length - 1) {
      // last task in round
      round = round + 1;
      progress = 0;
      if (round < 3) {
        this.autoOpenLessonModal(currentLesson._id, round);
      }

      this.setState({
        showLessonRoundResult: !fromRestoreProgress,
        allowSkipButton: true,
        skipsInRound: 0,
      });
    } else if (
      progress === currentLesson.tasks[round].length - 1 &&
      round === 3
    ) {
      // last task in lesson
      this.setState({
        showLessonResult: true,
        allowSkipButton: true,
        skipsInRound: 0,
      });
      this.storeResult();
    } else {
      progress++;
    }
    this.setState({
      round: round,
      progress: progress,
      taskStartedAt: new Date(),
    });
  };

  skipTask() {
    let { round, progress, currentLessonResult } = this.state;
    const taskResult = { ...currentLessonResult.taskResults[round][progress] };
    taskResult.skipped = true;
    this.storeTaskResult(taskResult);

    let allowSkipButton = true;
    if (this.state.skipsInRound >= 2) {
      allowSkipButton = false;
    }
    this.setState({
      skipsInRound: this.state.skipsInRound + 1,
      allowSkipButton,
    });
    this.nextTask();
  }

  nextRound = () => {
    this.setState({ showLessonRoundResult: false });
  };

  nextLesson = () => {
    // 7 days streak and first finished lesson for today
    if (
      this.props.currentStreak === SUCCESS_STREAK_MODAL_DAYS_IN_A_ROW &&
      this.getTodayResultsCount() === 1
    ) {
      this.setState({ successStreakModalOpened: true });
    } else {
      this.setState({ showLessonResult: false });
      this.props.history.push('/unit/' + this.props.currentUnit._id);
    }
  };

  onCloseSuccessStreakModal = () => {
    this.setState({ showLessonResult: false, successStreakModalOpened: false });
    this.props.history.push('/unit/' + this.props.currentUnit._id);
  };

  toggleUnitModal = () => {
    this.setState({ unitModalIsOpened: !this.state.unitModalIsOpened });
  };
  toggleLessonModal = () => {
    this.setState({ lessonModalIsOpened: !this.state.lessonModalIsOpened });
  };
  getCorrectOption = (currentTask) => {
    let correctOption =
      currentTask.options &&
      currentTask.options.find((option) => {
        if (option.indexOf('*') > -1) return option;
      });
    correctOption = correctOption && correctOption.replaceAll('*', '');
    return correctOption;
  };

  onGoBack() {
    this.props.history.push('/unit/' + this.props.currentUnit._id);
  }

  getTodayResultsCount = () => {
    let counter = 0;
    for (const result of this.props.userResults) {
      if (dayjs(result.startedAt).isSame(dayjs(), 'day')) {
        counter++;
      }
    }
    return counter;
  };

  render() {
    const {
      round,
      progress,
      unitModalIsOpened,
      lessonModalIsOpened,
      currentLessonResult,
      showLessonRoundResult,
      showLessonResult,
    } = this.state;
    const { currentUnit, currentLesson, lng, t } = this.props;

    const supportedLng = getSupportedLanguage(lng);

    if (!currentLesson || !currentUnit) {
      return <div>...loading</div>;
    } else if (
      !currentLesson.tasks?.length ||
      !currentLessonResult?.taskResults?.length
    ) {
      return <div>{this.props.t('lessonsNoTasks')}</div>;
    } else {
      const currentTask =
        currentLesson.tasks && currentLesson?.tasks?.[round]?.[progress];
      let success = currentLessonResult.taskResults[round][progress].success;
      let successLevel =
        currentLessonResult.taskResults[round][progress].successLevel ||
        LESSON.AUDIO.WRONG;
      let attempts = currentLessonResult.taskResults[round][progress].attempts;
      let showTask = !showLessonResult && !showLessonRoundResult;

      return (
        <>
          {showTask && (
            <>
              <UnitHelp
                unit={currentUnit}
                isOpened={unitModalIsOpened}
                onToggle={this.toggleUnitModal}
              />
              <LessonModal
                isOpened={lessonModalIsOpened}
                onToggle={this.toggleLessonModal}
                round={round}
              />
              <Row className="lesson-page">
                <Col
                  lg={{
                    offset: 2,
                    size: 8,
                  }}
                >
                  {this.state.screenWidth > BREAKPOINT_MOBILE_LAYOUT ? (
                    <>
                      <p className="unit-number-label page-title">{`${t(
                        'unitListItem'
                      )} ${currentUnit.number}`}</p>
                      <h1 className="page-title">
                        {translate(currentUnit, 'title', supportedLng)}
                      </h1>
                    </>
                  ) : (
                    <>
                      <Row className="xs-header-row">
                        <Col>
                          <Media
                            src={arrowBackIcon}
                            className="icon btn-back"
                            onClick={() => this.onGoBack()}
                          />
                        </Col>
                        <Col>
                          <p className="unit-number-label">{`${t(
                            'unitListItem'
                          )} ${currentUnit.number}`}</p>
                        </Col>
                        <Col />
                      </Row>
                      <Row>
                        <h1 className="page-title">
                          {translate(currentUnit, 'title', supportedLng)}
                        </h1>
                      </Row>
                    </>
                  )}

                  <Row className="lesson-card">
                    <Col>
                      <Row className="header-row">
                        <Col className="flex-grow-0">
                          <Media
                            src={closeIcon}
                            className="icon close-icon"
                            onClick={() => this.onGoBack()}
                          />
                        </Col>
                        <Col>
                          <p className="header-text">{`${t(
                            'lessonListItemHeader'
                          )} ${currentLesson.number} | ${translate(
                            currentLesson,
                            'title',
                            supportedLng
                          )}`}</p>
                        </Col>
                        <Col className="flex-grow-0">
                          <TipsBtn onClick={() => this.toggleUnitModal()} />
                        </Col>
                      </Row>

                      <Row className="task-container">
                        <Col
                          lg={{
                            size: 8,
                            offset: 2,
                          }}
                        >
                          <Row>
                            <p className="round-number-text">
                              {this.props.t("lessonSeries")} {round}
                            </p>
                          </Row>
                          <Row>
                            <ProgressBar
                              progress={progress}
                              taskLength={currentLesson.tasks[round].length}
                            ></ProgressBar>
                          </Row>

                          <LessonExerciseTask
                            task={currentTask}
                            success={success}
                            successLevel={successLevel}
                            allowSkipButton={this.state.allowSkipButton}
                            skip={this.skipTask.bind(this)}
                            attempts={attempts}
                            round={round}
                            correctOption={this.getCorrectOption(currentTask)}
                            next={() => this.nextTask()}
                            selectOption={this.onSelectOption.bind(this)}
                            onAudioResult={this.onAudioResult.bind(this)}
                            isEditor={this.props.isEditor}
                            roundHeaderText={translate(
                              currentLesson,
                              round,
                              supportedLng
                            )}
                            onTipsClick={() => this.toggleLessonModal()}
                            screenWidth={this.state.screenWidth}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}

          {showLessonRoundResult && (
            <LessonRoundResult
              currentLesson={currentLesson}
              currentLessonResult={currentLessonResult}
              currentUnit={currentUnit}
              round={round - 1}
              next={this.nextRound.bind(this)}
              screenWidth={this.state.screenWidth}
            />
          )}

          {showLessonResult && (
            <LessonResult
              currentLessonResult={currentLessonResult}
              currentLesson={currentLesson}
              currentUnit={currentUnit}
              next={this.nextLesson.bind(this)}
              lessonsCompleted={this.props.lessonsCompleted}
            />
          )}

          <SuccessStreakModal
            opened={this.state.successStreakModalOpened}
            onClosed={this.onCloseSuccessStreakModal}
          />
        </>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      startLesson,
      taskChange,
      lessonCompleted,
      anonymousLessonCompleted,
      setModalShown,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentLesson: state.currentLesson,
    currentUnit: state.currentUnit,
    currentUnitLessons: state.currentUnitLessons,
    authenticatedUser: state.authenticatedUser,
    anonymousUser: state.anonymousUser,
    modalsShown: state.modalsShown,
    isEditor: state.isEditor,
    lessonsCompleted: state.lessonsCompleted,
    currentStreak: state.currentStreak,
    userResults: state.userResults,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withNamespaces()(Lesson));
