import React from 'react';
import { Field } from 'react-final-form';
import BootstrapInput from '../../form/BootstrapInput';

export const TabPaneTask = ({ fieldNamePrefix, locale, handleSubmit }) => {
  return (
    <>
      <Field
        name={`${fieldNamePrefix}${locale}.feedback`}
        component={BootstrapInput}
        type="text"
        label="Feedback"
        onBlur={handleSubmit}
      />
    </>
  );
};
