import {
  applyMiddleware,
  createStore,
  compose
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers/index';

export default function configureStore() {
  const initialState = {};
  const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
  // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const sagaMiddleware = createSagaMiddleware();
  return {
    ...createStore(
      rootReducer,
      initialState,
      composeEnhancers(
        applyMiddleware(
          sagaMiddleware,
        )
      )
    ),
    runSaga: sagaMiddleware.run,
  };
}
  
  

