import * as types from '../types';

const moduleReducers = { 
  userResults: setUserResults,
  unitsCompleted: setUnitsCompleted,
  unitsResults: setUnitsResults,
  lessonsCompleted: setLessonsCompleted,
  streak: setStreak,
  currentStreak: setCurrentStreak,
  longestStreak: setLongestStreak,
  totalTasks: setTotalTasks,
  suceededTasks: setSuceededTasks,
  succededTasksPerLesson: setSuceededTasksPerLesson,
  // currentLessonResult: setCurrentLessonResult
};

function setUserResults(state = [], action) {
  switch (action.type) {
  case types.SET_USER_RESULTS:
    return action.payload;
  default:
    return state;
  }
}
function setUnitsCompleted(state = [], action) {
  switch (action.type) {
  case types.SET_UNITS_COMPLETED:
    return action.payload;
  default:
    return state;
  }
}
function setUnitsResults(state = {}, action) {
  switch (action.type) {
  case types.SET_UNITS_RESULT:
    return action.payload;
  default:
    return state;
  }
}
function setLessonsCompleted(state = [], action) {
  switch (action.type) {
  case types.SET_LESSONS_COMPLETED:
    return action.payload;
  default:
    return state;
  }
}

function setStreak(state = [], action) {
  switch (action.type) {
  case types.SET_STREAK:
    return action.payload;
  default:
    return state;
  }
}

function setCurrentStreak(state = 0, action) {
  switch (action.type) {
  case types.SET_CURRENT_STREAK:
    return action.payload;
  default:
    return state;
  }
}

function setLongestStreak(state = 0, action) {
  switch (action.type) {
  case types.SET_LONGEST_STREAK:
    return action.payload;
  default:
    return state;
  }
}
function setTotalTasks(state = 0, action) {
  switch (action.type) {
  case types.SET_TOTAL_TASKS:
    return action.payload;
  default:
    return state;
  }
}
function setSuceededTasks(state = 0, action) {
  switch (action.type) {
  case types.SET_SUCEEDED_TASKS:
    return action.payload;
  default:
    return state;
  }
}
function setSuceededTasksPerLesson(state = {}, action) {
  switch (action.type) {
  case types.SET_SUCCEDED_TASKS_PER_LESSON:
    return action.payload;
  default:
    return state;
  }
}

export default moduleReducers;