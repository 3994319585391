import { API_URL } from '../shared/constants/api';

export default class ApiService {

  constructor(token = null) {
    this.token = token;
  }

  async get(url, params) {
    try {
      let options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      if (this.token) {
        options.headers.Authorization = `Bearer ${this.token}`;
      }
      const fullUrl = new URL(API_URL + url);
      if (params) {
        fullUrl.search = new URLSearchParams(params).toString();
      }
      const result = await fetch(fullUrl, options);
      const body = await result.json();
      if(result.status!==200){
        throw new Error(body.detail);
      }
      return body;
    } catch (err) {
      throw err;
    }
  }

  async post(url, data) {
    try {
      let options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      };
      if (this.token) {
        options.headers.Authorization = `Bearer ${this.token}`;
      }
      const result = await fetch(API_URL + url, options);
      const body = await result.json();
      if(result.status!==201){
        throw new Error(this.parseErrors(body));
      }
      return body;
    } catch (err) {
      throw err;
    }
  }

  async postWithFormData(url, data) {
    try {
      let options = {
        method: 'POST',
        headers: {},
        body: data,
      };
      if (this.token) {
        options.headers.Authorization = `Bearer ${this.token}`;
      }
      const result = await fetch(API_URL + url, options);
      const body = await result.json();
      if(![200, 201].includes(result.status)) {
        throw new Error(this.parseErrors(body));
      }
      return body;
    } catch (err) {
      throw err;
    }
  }

  async put(url, data) {
    try {
      let options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      };
      if (this.token) {
        options.headers.Authorization = `Bearer ${this.token}`;
      }
      const result = await fetch(API_URL + url, options);
      const body = await result.json();
      if(result.status!==200){
        throw new Error(body.detail);
      }
    } catch (err) {
      throw err;
    }
  }
  parseErrors(body){
    if(body.detail){
      return body.detail;
    }else if(body.non_field_errors){
      return body.non_field_errors.join(', ');
    }else{
      return 'some undefined error';
    }
  }
}
