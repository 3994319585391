import * as dayjs from 'dayjs';
import * as weekday from 'dayjs/plugin/weekday';

import { call, put, select, takeEvery } from 'redux-saga/effects';

import * as types from '../types';
import ResultService from '../../service/result';
import {
  PRONONCIATION_ERRORS_STORAGE_KEY,
  ANON_USER_PRONONCIATION_ERRORS_STORAGE_KEY,
} from '../../shared/constants/storage';
dayjs.extend(weekday);

function* storeResult({ payload }) {
  try {
    let { token } = yield select((state) => state);
    /** @type {ResultService}  */
    const resultService = yield call(() => new ResultService(token));
    yield call([resultService, resultService.storeLessonResult], payload);
    yield put({
      type: types.GET_UNIT_RECOMMENDATION,
      payload: {},
    });
    yield getUserResults();
  } catch (error) {
    yield put({
      type: types.NEW_ALERT_ERROR,
      payload: { message: 'Error storing result:' + error.message },
    });
    console.error('put lesson result error', error);
  }
}

function* getUserResults() {
  let { authenticatedUser, token } = yield select((state) => state);
  if (authenticatedUser && authenticatedUser._id) {
    try {
      /** @type {ResultService}  */
      const resultService = yield call(() => new ResultService(token));
      const userResults = yield call([
        resultService,
        resultService.getUserResults,
      ]);
      yield put({
        type: types.SET_USER_RESULTS,
        payload: userResults.sort((a, b) =>
          a.finishedAt > b.finishedAt ? 1 : -1
        ),
      });
      yield setUnitsCompleted(userResults);
      yield setLessonsCompleted(userResults);
      yield setStreak(userResults);
      yield setTaskStat(userResults);
    } catch (error) {
      yield put({
        type: types.NEW_ALERT_ERROR,
        payload: { message: 'Error fetching user results:' + error.message },
      });
      console.error('Error fetching user results', error);
    }
  }
}
function* setUnitsCompleted(userResults) {
  let unitsCompleted = [];
  let unitResults = {};
  let lessonsSeen = [];
  userResults.forEach((userResult) => {
    if (userResult.unitCompleted) {
      unitsCompleted.push(userResult.unitId);
    }
    if (lessonsSeen.indexOf(userResult.lessonId) === -1) {
      // debugger;
      let unitResult = unitResults[userResult.unitId] || {
        totalSuccess: 0,
        totalAttempts: 0,
        totalTasks: 0,
        minutesSpend: 0,
        unitCompleted: false,
        finishedAt: new Date('1970-01-01'),
      };
      unitResult.unitId = userResult.unitId;
      unitResult.totalSuccess =
        unitResult.totalSuccess + userResult.totalSuccess;
      unitResult.totalAttempts =
        unitResult.totalAttempts + userResult.totalAttempts;
      unitResult.totalTasks = unitResult.totalTasks + userResult.totalTasks;
      unitResult.ratio = unitResult.totalSuccess / unitResult.totalTasks;
      unitResult.minutesSpend =
        unitResult.minutesSpend + userResult.minutesSpend;
      unitResult.finishedAt =
        unitResult.finishedAt > userResult.finishedAt
          ? unitResult.finishedAt
          : userResult.finishedAt;
      unitResult.unitCompleted =
        unitResult.unitCompleted || userResult.unitCompleted;

      unitResults[userResult.unitId] = unitResult;
      lessonsSeen.push(userResult.lessonId);
    }
  });
  yield put({
    type: types.SET_UNITS_COMPLETED,
    payload: unitsCompleted,
  });
  yield put({
    type: types.SET_UNITS_RESULT,
    payload: unitResults,
  });
}

function* setLessonsCompleted(userResults) {
  let lessonsCompleted = [];
  userResults.forEach((userResult) => {
    if (lessonsCompleted.indexOf(userResult.lessonId) === -1) {
      lessonsCompleted.push(userResult.lessonId);
    }
  });
  yield put({
    type: types.SET_LESSONS_COMPLETED,
    payload: lessonsCompleted,
  });
}
function* setStreak(userResults) {
  let streak = [false, false, false, false, false, false, false];
  let weekday = dayjs().day();
  // iso weeks not supported
  let startOfWeek =
    weekday === 0
      ? dayjs().subtract(1, 'd').startOf('week').add(1, 'd')
      : dayjs().startOf('week').add(1, 'd');
  let sortedResult = userResults.sort((a, b) =>
    dayjs(a.startedAt).isBefore(b.startedAt) ? 1 : -1
  );
  let tsPracticed = [];
  let minTs = dayjs().startOf('d').unix();
  let maxTs = 0;
  sortedResult.forEach((result) => {
    let ts = dayjs(result.startedAt).startOf('d').unix();
    if (tsPracticed.indexOf(ts) === -1) tsPracticed.push(ts);
    maxTs = Math.max(maxTs, ts);
    minTs = Math.max(minTs, ts);
    if (dayjs(startOfWeek).isBefore(result.startedAt)) {
      let day = dayjs(result.startedAt).day();
      day = day === 0 ? 6 : day - 1; // iso-fy
      streak[day] = true;
    } else {
      return;
    }
  });
  let tsPracticedSorted = tsPracticed.sort((a, b) => (a > b ? 1 : -1));
  let longestStreak = 0;
  let currentStreak = 0;
  let gap = 60 * 60 * 24;
  tsPracticedSorted.forEach((ts, index) => {
    currentStreak++;

    if (tsPracticedSorted.indexOf(ts + gap) === -1) {
      longestStreak = Math.max(longestStreak, currentStreak);

      // If it's today - do not reset current streak
      if (!dayjs.unix(ts).isSame(dayjs(), 'day')) {
        currentStreak = 0;
      }
    }
  });

  yield put({
    type: types.SET_STREAK,
    payload: streak,
  });
  yield put({
    type: types.SET_CURRENT_STREAK,
    payload: currentStreak,
  });
  yield put({
    type: types.SET_LONGEST_STREAK,
    payload: longestStreak,
  });
}

function* setTaskStat(userResults) {
  let sortedResult = userResults.sort((a, b) =>
    dayjs(a.startedAt).isBefore(b.startedAt) ? 1 : -1
  );
  let seen = [];
  let succeeded = 0;
  let total = 0;
  const succededPerLesson = {};
  sortedResult.forEach((result) => {
    if (seen.indexOf(result.lessonId) === -1) {
      total = total + result.taskResults.length;
      const lessonSucceded = result.taskResults.reduce(
        (previous, taskResult) =>
          taskResult.success ? previous + 1 : previous,
        0
      );
      succeeded = succeeded + lessonSucceded;
      succededPerLesson[result.lessonId] = lessonSucceded;
      seen.push(result.lessonId);
    }
  });
  yield put({
    type: types.SET_SUCEEDED_TASKS,
    payload: succeeded,
  });
  yield put({
    type: types.SET_TOTAL_TASKS,
    payload: total,
  });
  yield put({
    type: types.SET_SUCCEDED_TASKS_PER_LESSON,
    payload: succededPerLesson,
  });
}

function* setAnonymousLessonCompleted({ payload }) {
  let lessonsCompleteStorage = yield localStorage.getItem('lessonsCompleted');
  let unitsCompletedStorage = yield localStorage.getItem('unitsCompleted');
  let prononciationErrrorsStorage = yield localStorage.getItem(
    PRONONCIATION_ERRORS_STORAGE_KEY
  );
  const anonUserPrononciationErrrorsStorage = yield localStorage.getItem(
    ANON_USER_PRONONCIATION_ERRORS_STORAGE_KEY
  );

  let lessonsCompleted = [];
  let unitsCompleted = [];
  let prononciationErrrors = {};
  let anonUserPrononciationErrrors = [];
  try {
    lessonsCompleted = JSON.parse(lessonsCompleteStorage) || [];
    unitsCompleted = JSON.parse(unitsCompletedStorage) || [];
    prononciationErrrors = JSON.parse(prononciationErrrorsStorage) || {};
    anonUserPrononciationErrrors =
      JSON.parse(anonUserPrononciationErrrorsStorage) || [];
  } catch (e) {
    console.error(e);
  }
  if (payload) {
    if (lessonsCompleted.indexOf(payload?.lessonId) === -1) {
      lessonsCompleted.push(payload?.lessonId);
    }
    if (payload?.unitId && unitsCompleted.indexOf(payload?.unitId) === -1) {
      unitsCompleted.push(payload?.unitId);
    }
    if (payload?.lessonId && Object.keys(prononciationErrrors).length > 0) {
      anonUserPrononciationErrrors.push({
        unitId: payload?.unitId || null,
        lessonId: payload?.lessonId,
        prononciationErrrors,
      });
    }
    localStorage.setItem('lessonsCompleted', JSON.stringify(lessonsCompleted));
    localStorage.setItem('unitsCompleted', JSON.stringify(unitsCompleted));
    localStorage.setItem(PRONONCIATION_ERRORS_STORAGE_KEY, JSON.stringify({}));
    localStorage.setItem(
      ANON_USER_PRONONCIATION_ERRORS_STORAGE_KEY,
      JSON.stringify(anonUserPrononciationErrrors)
    );
  }
  yield put({
    type: types.SET_LESSONS_COMPLETED,
    payload: lessonsCompleted,
  });
  yield put({
    type: types.SET_UNITS_COMPLETED,
    payload: unitsCompleted,
  });
}
export function* resultSagas() {
  yield takeEvery(
    types.SET_ANONYMOUS_LESSON_COMPLED,
    setAnonymousLessonCompleted
  );
  yield takeEvery(types.STORE_RESULT, storeResult);
  yield takeEvery(types.FETCH_USER_RESULTS, getUserResults);
}

export default resultSagas;
