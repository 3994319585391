import React from "react";
import { Col } from "reactstrap";
import PropTypes from "prop-types";
import useScreenWidth from "../../../hooks/layout/useScreenWidth";
import { BREAKPOINT_MOBILE_LAYOUT } from "../../../shared/constants/theme";
import bgImgMob from "../../../shared/images/default-bg-mob.svg";
import bgImg from "../../../shared/images/default-bg.svg";
import "./BGImage.scss";

export const BGImage = (props) => {
  const { mobileStyle, desktopStyle, imageUrl, className } = props;
  const screenWidth = useScreenWidth();

  if (screenWidth <= BREAKPOINT_MOBILE_LAYOUT) {
    return (
      <div
        className="bg-img-mobile"
        style={{
          backgroundImage: `url(${imageUrl ?? bgImgMob})`,
          ...mobileStyle,
        }}
      ></div>
    );
  }

  return (
    <Col
      lg={{ offset: 1, size: 4 }}
      className={`bg-img ${className ?? ''}`}
      style={{
        backgroundImage: `url(${imageUrl ?? bgImg})`,
        ...desktopStyle,
      }}
    ></Col>
  );
};

BGImage.propTypes = {
  mobileStyle: PropTypes.object,
  desktopStyle: PropTypes.object,
  imageUrl: PropTypes.string,
  className: PropTypes.string,
};
