import React, { useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Form, Modal, ModalBody, ModalHeader, Row 
} from 'reactstrap';
import { saveFeedback } from '../../redux/actions/feedback';
import { FEEDBACK_TYPES } from '../../shared/constants';
import BootstrapInput from '../form/BootstrapInput';
import './UserFeedback.scss';
import { PropTypes } from 'prop-types';
import { DefaultBtn } from '../fragments/default_btn/DefaultBtn';

const UserFeedbackForm = withNamespaces()((props) => {

  let validate = (values) => {
    const errors = {};
    if (!values.rate) {
      errors.language = props.t('formFieldWarning');
    }

    return errors;
  };
  return <FinalForm
    onSubmit={props.onSubmit}
    validate={validate}
    initialValues={{
      rate: 0,
      feedback: '' 
    }}
    render={({
      handleSubmit, submitting, pristine, invalid 
    }) => (

      <Form onSubmit={handleSubmit} className="user-feedback-form">
        <h2>{props.t('userFeedbackV1Title')}</h2>
        {props.type == FEEDBACK_TYPES.V2 && <p className="question blue">{props.t('userFeedbackV2Text')}<br /><br /></p>}
        <p className="question">{props.t('userFeedbackV1Text')}</p>

        <div className="rate">
          <div className="line"></div>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(value => {
            return <span className="rate-value" key={'feedback_rate' + value}>
              {/* <div>{value}</div> */}
              <Field
                name="rate"
                type="radio"
                component="input"
                value={value + ''}
              />
            </span>;
          })}
        </div>
        <div className="rate-label">
          <div className="label text-left">{props.t('userFeedbackV1ScaleLeft')}</div>
          <div className="label text-right">{props.t('userFeedbackV1ScaleRight')}</div>
        </div>
        <div className="feedback">
          <br />
          <Field
            name="feedback"
            type="textarea"
            label={props.t('userFeedbackV1FeedbackLabel')}
            component={BootstrapInput}
          />
        </div>
        <Row className='btn-container'>
          <DefaultBtn label={props.t('userFeedbackSend')} type="submit" disabled={submitting || pristine || invalid} />
        </Row>
      </Form>
    )}
  />;
});
const UserFeedbackThanks = withNamespaces()((props) => {
  const feedbackUrl = {
    v1:{
      de:'https://asp2.inquery.net/s.app?A=85ks2uS7',
      vi:'https://asp2.inquery.net/s.app?A=DqykXAO8',
      en:'https://asp2.inquery.net/s.app?A=lfUuwQmz'
    },
    v2:{
      de:'https://asp2.inquery.net/s.app?A=IpaByoAT',
      vi:'https://asp2.inquery.net/s.app?A=1NYIqukL',
      en:'https://asp2.inquery.net/s.app?A=lozb5lpm'
    },
  };

  const openInQuery = ()=>{
    window.open(feedbackUrl?.[props.type]?.[props.lng?.substring(0,2)], '_blank');
    props.onButtonPress();
  };
  const closeModal = ()=>{
    props.onButtonPress();
  };
  return <div className="user-feedback-thanks">
    <h2>{props.t('userFeedbackTankyouTitle')}</h2>
    {/* <p>
      {props.t('userFeedbackTankyouText1')} 
      <span className="bold"> {props.t('userFeedbackTankyouText2')}</span> 
    </p> */}
    <p>{props.t('userFeedbackMoreQuestions')}</p>
    {/* <Button block color="primary" onClick={openInQuery}> */}
    <Row className='btn-container'>
      <DefaultBtn label={props.t('userFeedbackThanksButton')} onClick={openInQuery} />
    </Row>

  </div>;
});

function UserFeedback(props) {
  const dispatch = useDispatch();
  const didFeedback = useSelector(state => state.didFeedback, false);
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const onSubmitForm = (userFeedback) => {
    dispatch(saveFeedback({
      data: userFeedback,
      type: props.type 
    }));
    setSubmitted(true);
  };
  const onDiscard = () => {
    if (!submitted) {
      dispatch(saveFeedback({
        data: { skipped: true },
        type: props.type 
      }));
    }
    setShowModal(false);
  };

  return !didFeedback?.[props.type] || submitted ?
    <Modal isOpen={showModal} >
      <ModalHeader toggle={onDiscard} />
      <ModalBody>
        {!submitted && <UserFeedbackForm type={props.type} onSubmit={onSubmitForm} />}
        {submitted && <UserFeedbackThanks onButtonPress={()=>setShowModal(false)} type={props.type} />}
      </ModalBody>
    </Modal> :
    <React.Fragment />;
}

UserFeedback.propTypes = { type: PropTypes.string.isRequired, };


export default withNamespaces()(UserFeedback);
