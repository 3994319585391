import { WebtrekkSmartPixelReact } from "@webtrekk-smart-pixel/react";
import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { bindActionCreators, compose } from "redux";
import { Redirect, withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { loginAnonymous } from "../redux/actions/user";
import loginIcon from "../shared/images/new/login.svg";
import { DefaultBtn } from "../components/fragments/default_btn/DefaultBtn";
import closeIcon from "../shared/images/new/close.svg";
import bgImage from "../shared/images/bg/home.svg";
import { BGImage } from "../components/fragments/bg_image/BGImage";
import "./Login.scss";

const baseUrl = `${window.location.protocol}//${window.location.host}`;
const authBase = `${baseUrl}/auth`;

class Login extends Component {
  static propTypes = { login: PropTypes.func };

  constructor(props) {
    super(props);
    this.state = { showAnonymousModal: false };
  }

  login() {
    window.location = authBase + "/casauth?login=1";
  }
  register() {
    window.location = authBase + "/casauth?register=1";
  }
  loginAnonymous() {
    this.props.loginAnonymous();
    this.props.history.push("/");
  }

  toggleAnonymousModal() {
    this.setState({ showAnonymousModal: !this.state.showAnonymousModal });
  }

  componentDidMount() {
    WebtrekkSmartPixelReact.page("aussprachetraining_goethe_de.login", {});
    WebtrekkSmartPixelReact.track();
  }

  render() {
    if (this.props.authenticatedUser) return <Redirect to="/" />;

    return (
      <Row className="login-page">
        <Modal
          className="anon-login-modal"
          isOpen={this.state.showAnonymousModal}
          toggle={this.toggleAnonymousModal.bind(this)}
        >
          <Row className="modal-header-row">
            <Col>
              <h2 className="modal-title">{this.props.t("loginModalBody")}</h2>
            </Col>
            <Col className="flex-grow-0">
              <Media
                src={closeIcon}
                className="close-icon"
                onClick={this.toggleAnonymousModal.bind(this)}
              />
            </Col>
          </Row>

          <ModalBody>
            <Row>
              <p className="text-normal">
                {this.props.t("loginModalBodyParagraph")}
              </p>
            </Row>
            <Row>
              <DefaultBtn
                className="anon-login-modal-btn"
                label={this.props.t("loginModalBodyContinueButton")}
                onClick={this.loginAnonymous.bind(this)}
              />
            </Row>
            <Row>
              <DefaultBtn
                className="anon-login-modal-btn white"
                label={this.props.t("loginModalBodyBackButton")}
                onClick={this.toggleAnonymousModal.bind(this)}
              />
            </Row>
          </ModalBody>
        </Modal>
        <Col>
          <Row>
            <Col
                lg={{
                  offset: 2,
                  size: 8,
                }}
            >
              <Row>
                <h1 className="page-title">
                  {this.props.t("loginFooterTitle")}
                </h1>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
                lg={{
                  offset: 2,
                  size: 5,
                }}
            >
              <Row>
                <p className="text-normal">
                  {this.props.t("loginMotivation")}
                </p>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              lg={{
                offset: 2,
                size: 4,
              }}
            >
              <Row className="login-card">
                <Col>
                  <DefaultBtn
                    className="login-btn"
                    label={this.props.t("footerModalBodyButtonRegister")}
                    onClick={this.register.bind(this)}
                  />
                  <DefaultBtn
                    className="login-btn"
                    label={"Login"}
                    onClick={this.login.bind(this)}
                  />
                  <DefaultBtn
                    className="login-btn grayed"
                    label={this.props.t("loginFooterButtonContinue")}
                    onClick={this.toggleAnonymousModal.bind(this)}
                  />

                  <Row className="icons-container">
                    <Media src={loginIcon} className="" />
                  </Row>
                </Col>
              </Row>
            </Col>
            <BGImage imageUrl={bgImage} />
          </Row>
        </Col>
      </Row>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loginAnonymous }, dispatch);
}

function mapStateToProps(state) {
  return {
    authenticatedUser: state.authenticatedUser,
    anonymousUser: state.anonymousUser,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withNamespaces()(Login));
