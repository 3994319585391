import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import translate, { getSupportedLanguage } from '../../utils/translate';
import { CardListItem } from '../fragments/card_list_item/CardListItem';
import { API_URL } from '../../shared/constants/api';
import { UNIT_ICONS } from '../../shared/constants/icon';
import { STATE } from '../../shared/constants/state';
import { useSelector } from 'react-redux';

function UnitList(props) {
  const unitsResults = useSelector((state) => state.unitsResults);
  const lng = getSupportedLanguage(props.lng);
  return (
    <Row>
      <Col>
        {props.units &&
          props.units.map((unit) => {
            const unitLessons = props.lessons.filter(
              (lesson) => lesson.unitId === unit._id
            );
            const unitLessonsCompleted = unitLessons.filter((lesson) =>
              props.lessonsCompleted.includes(lesson._id)
            );

            let unitHasFinishedLessons = false;
            for (const unitLesson of unitLessons) {
              if (props.lessonsCompleted.indexOf(unitLesson._id) > -1) {
                unitHasFinishedLessons = true;
                break;
              }
            }

            let state;
            if (props.unitsCompleted.indexOf(unit._id) > -1) {
              state = STATE.DONE;
            } else if (unitHasFinishedLessons) {
              state = STATE.CURRENT;
            } else {
              state = STATE.UNLOCKED;
            }

            const unitResult = unitsResults[unit._id];

            return (
              <CardListItem
                key={unit._id}
                onCardClick={() => props.onSelectUnit(unit)}
                badgeText={props.t('resultLessons', {
                  current: unitLessonsCompleted.length,
                  total: unitLessons.length || 0,
                })}
                subtitle={translate(unit, 'title', lng)}
                state={state}
                percentage={
                  (unitLessonsCompleted.length / (unitLessons.length || 0)) *
                  100
                }
                icon={
                  unit.icon
                    ? API_URL + unit.icon
                    : UNIT_ICONS[(unit.number - 1) % UNIT_ICONS.length]
                }
                lang={lng}
                ratio={unitResult?.ratio || 0}
              />
            );
          })}
      </Col>
    </Row>
  );
}

UnitList.propTypes = {
  units: PropTypes.array,
  lessons: PropTypes.array,
  unitsCompleted: PropTypes.array,
  onSelectUnit: PropTypes.func,
  lessonsCompleted: PropTypes.array,
};

export default withNamespaces()(UnitList);
